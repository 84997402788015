import React from "react";
import { TextField, makeStyles, InputAdornment, IconButton, VisibilityIcon, VisibilityOffIcon , MenuIcon} from "../../utilities/PackagesList";
const useStyles = makeStyles(theme => ({
    forNumber: {
        "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
            margin: 0
          }
    },
    forNumber1: {
        "& input[type=number]": {
            "-moz-appearance":"textfield",
          }
    }
  }))
const PasswordField = (props) => { 
    const classes = useStyles();
    return (
        <TextField
            required={props.required}
            className={[classes.forNumber,classes.forNumber1]} 
            size="small"
            multiline={false}
            type={props.type}
            label={props.label}
            value={props.value}
            onChange={props.changeText}
            onKeyPress={props.KeyPress}            
            variant="outlined"
            fullWidth={true}
            color="primary"
            error={props.error}
            InputLabelProps={{
            shrink: true,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={props.showText}
                    edge="end"
                  >
                    {props.HideText ? <VisibilityIcon fontSize={"small"}/> : <VisibilityOffIcon fontSize={"small"}/>}
                  </IconButton>
                </InputAdornment>
              )
            }}
            defaultValue={props.FirstValue}
            placeholder={props.initialName}
            helperText={props.error ? props.errorMsg : ""}
            />
    )
}
export default PasswordField;