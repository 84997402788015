import React, { useState, Fragment , useRef } from 'react';
import {
    Grid, Dialog, GridList, DialogContent,
    DialogActions, DialogTitle, makeStyles,
    Typography, Divider, IconButton, DeleteIcon,
    EditIcon, _, swal, toast, useSelector, useDispatch,
    Card, CardMedia, CardContent, CardActions, Box, Button,
    List, ListItem, ListItemIcon, ListItemText, Paper, Link,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow
} from '../../utilities/PackagesList';
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import {
    PageTitle, CustomToolbar, AlertDialog,
    CustomModel, InputField, Dropdown,
    Buttons, LoadingOverlay, PDF
} from "../../utilities/ComponentsList";
import { GetFilledServiceForms } from '../../api/Services';
import { refreshReduxStates } from './../../api/Refresh';
import { isEmptyObj, DMY, GetYorN, dateTimeFormating, PriceFormat, capsFirstLetter } from "../../utilities/AppFunctions";

const useStyles = makeStyles(theme => ({
    dialogTitle: {

    },
    dialogWindow: {
        width: '80%'
    },
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
    },
    gridList: {
        width: 500,
        height: 300,
    },
    cardStyle: {
        height: 90,
        padding: 10,
        margin: 10,
        boxShadow: "none",
        borderRadius: 10,
        backgroundColor: '#000'

    },
    tableRightBorder: {
        borderWidth: 0,
        borderRightWidth: 1,
        borderColor: 'black',
        borderStyle: 'solid',
    },
    buttonCancel: {
        backgroundColor: theme.palette.btns.default,
        opacity: 0.9,
        borderRadius: 30,
        '&:hover': {
            backgroundColor: theme.palette.btns.gray
        }
    },
    boldText: {
        fontWeight: "600"
    },
}))
const ViewForm = (props) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    let ParentState = props.parentState;
    
   
   
    return (
        <Dialog
            open={props.Status}
            fullWidth={true}
            fullScreen={false}
            maxWidth="md"
            disableBackdropClick={false}
            disableEscapeKeyDown={false}
        >
            <DialogTitle className={classes.dialogTitle} id="dialog-title">{props.Title}({ParentState.SelectedForm.type})</DialogTitle>
            <DialogContent dividers >
                <Grid container spacing={4} md={12} >
                <Grid item md={12} style={{ display: "flex", justifyContent: "flex-end" }}>
                <PDFDownloadLink
                document={<PDF input={ParentState.SelectedForm}  />}
                fileName={ParentState.SelectedForm.type+".pdf"}
                style={{
                  textDecoration: "none",
                  padding: "10px",
                  backgroundColor: "#ed3026",
                  opacity:0.9,
                  borderRadius:5,
                  fontSize:"0.7rem",
                  color:"#fff",
                  '&:hover': {
                      backgroundColor: "#f9c300"
                  }
                }}
              >
                {({ blob, url, loading, error }) =>
                  loading ? "Loading document..." : "Download Pdf"
                }
              </PDFDownloadLink>
              </Grid>
                <PDFViewer style={{width:'100%',height:400}}>
                <PDF input={ParentState.SelectedForm} Type={ParentState.SelectedForm.type} />
              </PDFViewer>
                </Grid>
            </DialogContent>
            <DialogActions>
                {/* <Buttons
                close={() => props.ModelCancel()}
                closeText={"Cancel"}
                save={() => downloadPDF()}
                saveText={"Download PDF"}
                /> */}
                 <Grid item md={12} style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                        onClick={() => props.ModelCancel()}
                        variant="contained"
                        color="secondary"
                        className={classes.buttonCancel}
                    >
                        Close
                        </Button>

                </Grid> 
            </DialogActions>
        </Dialog >
    );
}

export default ViewForm;