import React, { useState, useEffect, Component } from "react";
import {
  Grid, TextField, Button, Switch,
  AgGridColumn, AgGridReact, DeleteIcon,
  EditIcon, MUIDataTable, Autocomplete, connect,
  _, swal, toast, Typography, Checkbox, Tab,
  TabPanel, AppBar, TabContext, TabList, withStyles,
  TableRow, TableHead, TableCell, TableFooter
} from "../../utilities/PackagesList";
import {
  PageTitle, CustomToolbar, AlertDialog,
  CustomModel, InputField, Dropdown,
  Buttons, LoadingOverlay, ButtonIcon
} from "../../utilities/ComponentsList";
import {
  LastDayMonth, todayDate,
  convertDateDMY, DateMinusYMD, AMPM,
  DecimalFormat, nullToStr
} from "../../utilities/AppFunctions";
import { getAdhoc, getPTripReport } from '../../api/Adhoc';
import { getTeams } from '../../api/Team';
import { refreshReduxStates } from '../../api/Refresh';
import styles from "../../utilities/StylesClass";
import BaseDetails from './../../utilities/BaseDetails.json';

class DeliveryPTrip extends Component {

  constructor(props) {
    super(props);
    this.state = {
      DeliveryCompleted: [],
      TeamsReport: [],
      DelStartDate: DateMinusYMD(5),
      DelEndDate: todayDate(),
      TeamSelectedID: "",
      TeamSelected: "",
      isLoading: true,
      getCalls: [0, 1],
      TeamsLists: []

    }
    props.getTeams(2);
  }

  UNSAFE_componentWillReceiveProps = (props) => {
    if (this.state.getCalls[0]) {
      if (props.PTripSuccess) {
        let succed = this.state.getCalls;
        succed[0] = 0;
        let loaderStatus = succed.includes(1);
        let FilteredItems = props.PTripData;
        FilteredItems = _.orderBy(FilteredItems, ['teamId', 'deliveryDate'], ['asc', 'asc']);
        
        let k = 0;
        let totalCompleted = 0; let totalAssigned = 0; let teamID = 0; let teamName = "";
        let newArra = [];
        FilteredItems.map(function (data) {
          k++;
          let completed = 0; let assigned = 0;
          data.details.forEach((item) => {
            completed += item.pTrip != 'NaN' && item.status == 'completed' ? parseFloat(item.pTrip) : 0;
            assigned += item.pTrip != 'NaN' && item.status == 'assigned' ? parseFloat(item.pTrip) : 0;
          })
          let newObject = {};
          console.log("assigned",assigned)
          console.log("totalAssigned",totalAssigned)
          console.log("completed",completed)
          console.log("totalCompleted",totalCompleted)
          if (FilteredItems.length == k  && teamID != data.teamId) {
            console.log("@1");
            
            let item1 = {
              TeamName: null,
              TeamID: teamID,
              Date: "Sub-Total(" + teamName + ")",
              Status: "Total",
              Completed: totalCompleted,
              Assigned: totalAssigned
            }
            let item2 = {
              TeamName: data.teamName,
              TeamID: data.teamId,
              Status: "Item",
              Date: data.deliveryDate,
              Completed: completed,
              Assigned: assigned
            }
            let item3 = {
              TeamName: null,
              TeamID: data.teamId,
              Date: "Sub-Total(" + data.teamName + ")",
              Status: "Total",
              Completed: completed,
              Assigned: assigned
            }
            if(teamName){
              newArra.push(item1);
            }
            newArra.push(item2);
            newArra.push(item3);
          } else if (FilteredItems.length == k) {
            totalCompleted += parseFloat(completed);
            totalAssigned += parseFloat(assigned);
            let item1 = {
              TeamName: null,
              TeamID: teamID,
              Date: "Sub-Total(" + teamName + ")",
              Status: "Total",
              Completed: totalCompleted,
              Assigned: totalAssigned
            }
            let item2 = {
              TeamName: data.teamName,
              TeamID: data.teamId,
              Status: "Item",
              Date: data.deliveryDate,
              Completed: completed,
              Assigned: assigned
            }
            newArra.push(item2);
            newArra.push(item1);
            totalCompleted = 0;
            totalAssigned = 0;
          }else if (teamID > 0 && teamID != data.teamId) {
            console.log("@2");
            let item1 = {
              TeamName: null,
              TeamID: teamID,
              Date: "Sub-Total(" + teamName + ")",
              Status: "Total",
              Completed: totalCompleted,
              Assigned: totalAssigned
            }
            let item2 = {
              TeamName: data.teamName,
              TeamID: data.teamId,
              Status: "Item",
              Date: data.deliveryDate,
              Completed: completed,
              Assigned: assigned
            }
            newArra.push(item1);
            newArra.push(item2);
            totalCompleted = 0;
            totalAssigned = 0;
          } else {
            console.log("@3");
            let item1 = {
              TeamName: data.teamName,
              TeamID: data.teamId,
              Status: "Item",
              Date: data.deliveryDate,
              Completed: completed,
              Assigned: assigned
            }
            newArra.push(item1);
          }
          totalCompleted += parseFloat(completed);
          totalAssigned += parseFloat(assigned);
          teamID = data.teamId;
          teamName = data.teamName;
          return newObject;
        })
        
        this.setState({
          DeliveryCompleted: newArra,
          TeamsReport: [],
          isLoading: loaderStatus,
          getCalls: succed
        })
      } else if (props.PTripFailure) {
        let succed = this.state.getCalls;
        succed[0] = 0;
        let loaderStatus = succed.includes(1);
        this.setState({
          DeliveryCompleted: [],
          TeamsReport: [],
          getCalls: succed,
          isLoading: loaderStatus
        })
      }
    }
    if (this.state.getCalls[1]) {
      if (props.TeamListSuccess) {
        let succed = this.state.getCalls;
        succed[1] = 0;
        succed[0] = 1;
        let loaderStatus = succed.includes(1);
        let newTeam = {
          "id": 0,
          "teamName": "All",
          "vehicleNumber": "",
          "vehicelId": "",
          "assignedStaff": [],
          "teamTypeId": 0,
          "teamTypeName": "all"
        };
        let Teams = props.TeamLists;
        let TeamExist = Teams.length > 0 ? true : false;
        Teams.unshift(newTeam);
        let DeliveryStart = this.state.DelStartDate;
        let DeliveryEnd = this.state.DelEndDate;
        let TeamID = TeamExist ? Teams[0].id : "";
        let filterObj = {
          DelStartDate: DeliveryStart,
          DelEndDate: DeliveryEnd,
          DeliveryStatus: "1,2,4",
          TeamID: TeamID
        }

        this.setState({
          TeamsLists: props.TeamLists,
          isLoading: loaderStatus,
          TeamSelectedID: TeamExist ? Teams[0].id : "",
          TeamSelected: TeamExist ? Teams[0] : "",
          getCalls: succed
        }, () => {
          this.props.getPTripReport(filterObj);
        })
      } else if (props.TeamListFailure) {
        let succed = this.state.getCalls;
        succed[1] = 0;
        let loaderStatus = succed.includes(1);
        this.setState({
          TeamsLists: [],
          getCalls: succed,
          isLoading: loaderStatus
        })
      }
    }
  }

  searchClick = () => {
    if (!this.state.DelStartDate && !this.state.DelEndDate && !this.state.TeamSelectedID) {
      toast.warning('Please choose any one filter option!', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: false,
      });
      return;
    } /* else if (!this.state.TeamSelectedID) {
      toast.warning('Please choose the team!', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: false,
      });
      return;
    } */
    let DeliveryStart = this.state.DelStartDate;
    let DeliveryEnd = this.state.DelEndDate;
    let TeamID = this.state.TeamSelectedID ? this.state.TeamSelectedID : "";
    let filterObj = {
      DelStartDate: DeliveryStart,
      DelEndDate: DeliveryEnd,
      DeliveryStatus: "1,2,4",
      TeamID: TeamID
    }

    this.setState({
      getCalls: [1, 0],
      isLoading: true
    }, () => {
      this.props.getPTripReport(filterObj);
    })

  }

  clearSearchClick = () => {
    this.setState({
      DeliveryCompleted: [],
      DelStartDate: DateMinusYMD(5),
      DelEndDate: todayDate(),
      TeamSelectedID: "",
      TeamSelected: "",
      getCalls: [0, 0]
    });

  }
  CompletedSum = () => {
    let inpData = this.state.DeliveryCompleted;
    return DecimalFormat(_.sumBy(inpData, function (o) { return o.Status == 'Item' ? o.Completed : 0; }));
    let sum = 0;
    inpData.map((ele) => ({
      itemPrice: ele.details.forEach((item) => {
        sum += item.pTrip != 'NaN' && item.status == 'Completed' ? parseFloat(item.pTrip) : 0;
      }),
    }));
    return DecimalFormat(sum);//_.sumBy(inpData.details, function(o) { return o.pTrip!='NaN' && o.status=='Completed' ? o.pTrip : 0; });
  }
  AssignedSum = () => {
    /* let inpData = this.state.DeliveryCompleted;
    return _.sumBy(inpData.details, function(o) { return o.pTrip!='NaN' && o.status!='Completed' ? o.pTrip : 0; }); */
    let inpData = this.state.DeliveryCompleted;
    return DecimalFormat(_.sumBy(inpData, function (o) { return o.Status == 'Item' ? o.Assigned : 0; }));
    let sum = 0;
    inpData.map((ele) => ({
      itemPrice: ele.details.forEach((item) => {
        sum += item.pTrip != 'NaN' && item.status != 'Completed' ? parseFloat(item.pTrip) : 0;
      }),
    }));
    return DecimalFormat(sum);
  }
  render() {
    const { classes } = this.props;

    const columns = [
      {
        name: "Date",
        label: "Delivery Date",
        options: {
          sort: true,
          filter: false,
          display: true,
          print: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            let TeamValues = tableMeta.rowData[3];
            let firstValues = tableMeta.rowData[4] == 'Item' ? true : false;
            return (
              <Grid item md={12}>
                {firstValues ?
                  <Typography>{convertDateDMY(value)}</Typography>
                  :
                  <Typography className={classes.largeBoldText}>{value}</Typography>
                }
              </Grid>
            )
          }
        }
      },
      {
        name: "Assigned",
        label: "Assigned Job",
        options: {
          sort: false,
          filter: false,
          display: true,
          print: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            //let AssignedData = _.sumBy(tableMeta[0], function (o) { return o.pTrip != 'NaN' && o.status != 'Completed' ? o.pTrip : 0; });
            //let inptData = value.status != 'Completed' ? value.pTrip : 0;
            let firstValues = tableMeta.rowData[4] == 'Item' ? true : false;

            return (
              <Grid item md={12}>
                {firstValues ?
                  <Typography>{DecimalFormat(value)}</Typography>
                  :
                  <Typography className={classes.largeBoldText}>{DecimalFormat(value)}</Typography>
                }
              </Grid>
            )
          }
        }
      },
      {
        name: "Completed",
        label: "Completed Job",
        options: {
          sort: false,
          filter: false,
          display: true,
          print: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            //let CompletedData = _.sumBy(tableMeta[0], function (o) { return o.pTrip != 'NaN' && o.status == 'Completed' ? o.pTrip : 0; });
            let firstValues = tableMeta.rowData[4] == 'Item' ? true : false;
            return (
              <Grid item md={12}>
                {firstValues ?
                  <Typography>{DecimalFormat(value)}</Typography>
                  :
                  <Typography className={classes.largeBoldText}>{DecimalFormat(value)}</Typography>
                }
              </Grid>
            )
          }
        }
      },
      {
        name: "TeamName",
        label: "Team Name",
        options: {
          sort: true,
          filter: false,
          display: true,
          print: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            let firstValues = tableMeta.rowData[4] == 'Item' ? true : false;
            return (
              <Grid item md={12}>
                <Typography>{firstValues ? value : ""}</Typography>
              </Grid>
            )
          }
        }
      },
      {
        name: "Status",
        label: "Status",
        options: {
          sort: false,
          filter: false,
          display: false,
          print: false
        }
      }
    ];

    return (
      <>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Grid container spacing={1} md={12} direction={"row"}>
              <Grid item md={2} >
                <InputField
                  label={"Delivery Start Date"}
                  type={"date"}
                  required={false}
                  value={this.state.DelStartDate}
                  changeText={(data) => {
                    this.setState({
                      DelStartDate: data ? data.target.value : null,
                      DelEndDate: data ? LastDayMonth(data.target.value) : null
                    })
                  }}
                  initialName={"Delivery Start Date"}
                  errorMsg={"Please enter start date."}
                />
              </Grid>
              <Grid item md={2} >
                <InputField
                  label={"Delivery End Date"}
                  type={"date"}
                  required={false}
                  value={this.state.DelEndDate}
                  changeText={(data) => { this.setState({ DelEndDate: data ? data.target.value : null }) }}
                  // error={Createerrors.Date}
                  initialName={"Delivery End Date"}
                  errorMsg={"Please enter end date."}
                />
              </Grid>
              <Grid item md={2} >
                <Dropdown
                  label={"Select Team"}
                  data={this.state.TeamsLists}
                  getOptionLabel={option => (option.teamName ? option.teamName : "")}
                  getOptionSelected={(option, value) => option.id === value.id}
                  value={this.state.TeamSelected}
                  ItemChange={(event, newValue) => {
                    this.setState({
                      TeamSelectedID: newValue ? newValue.id : null,
                      TeamSelected: newValue ? newValue : null
                    })
                  }}
                  //error={Createerrors.TeamSelectedID}
                  initialName={"Team"}
                  errorMsg={"Please choose team."}
                />
              </Grid>
              <Grid item md={1} >
                <Button
                  onClick={() => this.clearSearchClick()}
                  className={classes.buttonCancel}
                  variant="contained" color="primary">
                  Clear
                  </Button>
              </Grid>
              <Grid item md={1} >
                <Button
                  onClick={() => this.searchClick()}
                  className={classes.buttonSuccess}
                  variant="contained" color="primary">
                  Search
                  </Button>
              </Grid>
            </Grid>

            <Grid item xs={12} style={{ marginTop: 10 }}>
              <MUIDataTable
                title=""
                data={this.state.DeliveryCompleted}
                columns={columns}
                options={{
                  downloadOptions: {
                    filename: "Delivery_P_Trip_Report.csv",
                    filterOptions: {
                      useDisplayedColumnsOnly: true,
                      useDisplayedRowsOnly: true
                    }
                  },
                  /* onDownload: (buildHead, buildBody, columns, data) => {
                    return "\uFEFF" + buildHead(columns) + buildBody(data);
                  }, */
                  /* customTableBodyFooterRender: (opts) => {
                    return (
                      <TableFooter>
                        <TableRow>
                          <TableCell>
                            <Typography className={classes.boldText}>Total</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography className={classes.boldText}>{this.AssignedSum()}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography className={classes.boldText}>{this.CompletedSum()}</Typography>
                          </TableCell>
                        </TableRow>
                      </TableFooter>
                    );
                  }, */
                  sort: true,
                  search: true,
                  selectableRows: false,
                  selectableRowsOnClick: false,
                  responsive: true,
                  print: true,
                  download: true,
                  viewColumns: false,
                  filter: false
                }}
              />
            </Grid>

          </Grid>
          <LoadingOverlay Status={this.state.isLoading} />
        </Grid>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    TeamListSuccess: state.getTeamsReducer.Success,
    TeamListFailure: state.getTeamsReducer.Failure,
    TeamLists: state.getTeamsReducer.TeamList,

    PTripSuccess: state.getPTripReportReducer.Success,
    PTripFailure: state.getPTripReportReducer.Failure,
    PTripData: state.getPTripReportReducer.PtripList,

    UserDetails: state.LoginReducer.UserDetails,
    LoaderState: state.LoaderReducer.State
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTeams: (ID) => dispatch(getTeams(ID)),
    getPTripReport: (filterObj) => dispatch(getPTripReport(filterObj)),
    refreshReduxStates: () => dispatch(refreshReduxStates()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DeliveryPTrip));
