import React, {useState } from "react";
import { Grid, TextField, Button , Switch, FormControlLabel} from "@material-ui/core";
//import { makeStyles } from "@material-ui/styles";

// components
import PageTitle from "../../components/PageTitle";

const designationList = [
  {
    ID: 1,
    label: "Employee",
  },
  {
    ID: 2,
    label: "Admin",
  },
];
export default function AddStaffs(props) {
 // const classes = useStyles();
  const [values, setValues] = React.useState({
    FName: '',
    LName: '',
    Email: '',
    Phone: '',
    DOB: '',
    Designation: '',
    JoiningDate: '',
    Status: true
  });
  const [errors, setErrors] = useState({});

  const handleChange = name => event => {
    let targetValue = event.target.value;
    
    if(name==='Status'){
      targetValue = !values.Status;
    }
    setValues({ ...values, [name]: targetValue });    
    setErrors({ ...errors, [name]: targetValue ? false : true })
  };
  const submitForm = () => {
    let srrorObj = {};
    Object.keys(values).map(function(k){ 
      srrorObj[k] = values[k] ? false : true;
    })
    setErrors(srrorObj) 
  };
  return (
    <>
      <PageTitle title="Add Staffs" />
      <Grid container spacing={4}>
          <Grid item md={12}>
            <Grid
              container
              direction="row"
            >
              <Grid item md={4}>
                <TextField
                  required
                  
                  label="First Name"
                  value={values.FName}
                  onChange={handleChange('FName')}
                  variant="outlined"
                  fullWidth={true}
                  color="primary"
                  error={errors.FName}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  helperText={errors.FName ? "Please enter first name." : ""}
                />
              </Grid>
              <Grid item md={1} />
              <Grid item md={4}>
                <TextField
                  required
                  
                  label="Last Name"
                  value={values.LName}
                  onChange={handleChange('LName')}
                  variant="outlined"
                  fullWidth={true}
                  color="primary"
                  error={errors.LName}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  helperText={errors.LName ? "Please enter last name." : ""}
                />
              </Grid>
              <Grid item md={2} />
            </Grid>
            
          </Grid>
          <Grid item md={12}>
            <Grid
              container
              direction="row"
            >
              <Grid item md={4}>
                <TextField
                  required
                  
                  label="Email"
                  value={values.Email}
                  onChange={handleChange('Email')}
                  variant="outlined"
                  fullWidth={true}
                  color="primary"
                  error={errors.Email}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  helperText={errors.Email ? "Please enter first name." : ""}
                />
              </Grid>
              <Grid item md={1} />
              <Grid item md={4}>
                <TextField
                  required
                  
                  label="Phone"
                  value={values.Phone}
                  onChange={handleChange('Phone')}
                  variant="outlined"
                  fullWidth={true}
                  color="primary"
                  error={errors.Phone}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  helperText={errors.Phone ? "Please enter phone." : ""}
                />
              </Grid>
              <Grid item md={2} />
            </Grid>
            
          </Grid>
          <Grid item md={12}>
            <Grid
              container
              direction="row"
            >
              <Grid item md={4}>
                <TextField
                  required
                  
                  label="DOB"
                  type="date"
                  value={values.DOB}
                  onChange={handleChange('DOB')}
                  variant="outlined"
                  fullWidth={true}
                  color="primary"
                  error={errors.DOB}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  helperText={errors.DOB ? "Please enter first name." : ""}
                />
              </Grid>
              <Grid item md={1} />
              <Grid item md={4}>
              <TextField
                  id="outlined-select-currency-native"
                  select
                  label="Designation"
                  value={values.Designation}
                  onChange={handleChange('Designation')}
                  SelectProps={{
                    native: true,
                  }}
                  fullWidth={true}
                  helperText={errors.Designation ? "Please enter first name." : ""}
                  variant="outlined"
                >
                  {designationList.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </TextField>
              </Grid>
              <Grid item md={2} />
            </Grid>
            
          </Grid>
          <Grid item md={12}>
            <Grid
              container
              direction="row"
            >
              <Grid item md={4}>
                <TextField
                  required
                  label="Joining Date"
                  type="date"
                  value={values.JoiningDate}
                  onChange={handleChange('JoiningDate')}
                  variant="outlined"
                  fullWidth={true}
                  color="primary"
                  error={errors.JoiningDate}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  helperText={errors.JoiningDate ? "Please enter first name." : ""}
                />
              </Grid>
              <Grid item md={1} />
              <Grid item md={4}>
              <FormControlLabel
                value={values.Status}
                control={
                  <Switch 
                  size="medium"
                  
                  checked={values.Status}
                  onChange={handleChange('Status')} 
                  inputProps={{ 'aria-label': 'primary' }}
                  color="primary" />
                }
                label="Activate"
                fullWidth={true}
                labelPlacement="top"
              /> 
              </Grid>
              <Grid item md={2} />
            </Grid>
            
          </Grid>
          <Grid item md={12}>
          <Button 
            onClick={() => { submitForm() }}
            variant="contained" color="primary">
            Submit
          </Button>
          </Grid>
      </Grid>
    </>
  );
}
