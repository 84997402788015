import React, { useState, useEffect, Component } from "react";
import {
  Grid, TextField, Button, Switch, Tooltip,
  FormControlLabel, IconButton, DeleteIcon,
  EditIcon, MUIDataTable, Autocomplete, connect,
  _, swal, toast, Typography, Checkbox, withStyles
} from "../../utilities/PackagesList";
import {
  PageTitle, CustomToolbar, AlertDialog,
  CustomModel, InputField, Dropdown,
  Buttons, LoadingOverlay, ButtonIcon
} from "../../utilities/ComponentsList";
import {DatePlusYMD, PartialSearch, LastDayMonth, convertDateDMY, todayDate, capsFirstLetter, GetFullMobileNo, UnChangeObj, DMYD, AMPM } from "../../utilities/AppFunctions";
import { GetService, DeleteService, GetJobTypes, AssignService, UpdateService } from '../../api/Services';
import { getTeams } from '../../api/Team';
import { refreshReduxStates } from '../../api/Refresh';
import { SendSMS } from '../../api/Notification';
import { LoaderStatus } from '../../api/Loading';
import CreateService from "./CreateService";
import EditService from "./EditService";
import AssignTeam from "./AssignTeam";
import AssignTimeSlot from "./AssignTimeSlot";
import ViewList from "./ViewList";
import ViewTimeSlots from "./ViewTimeSlots";
import styles from "../../utilities/StylesClass";

class Service extends Component {

  constructor(props) {
    super(props);
    this.state = {
      EditModel: false,
      DeleteModel: false,
      ViewModel: false,
      CreateModel: false,
      AssignSlotState : false,
      DeleteID: null,
      EditID: null,
      ViewID: null,
      SelectedIndex: null,
      isLoading: false,
      SelectedServices: [],
      SelectedServiceID:null,
      TeamsLists: [],
      AllTeamList:[],
      JobTypeList: [],
      getCalls: [1, 1, 1],
      postCalls: [0],
      //ServiceLists:[],
      FilteredServiceLists: [],
      DateStartSelected: todayDate(),
      DateEndSelected: DatePlusYMD(15),
      PostalCode: "",
      FilterContactNo: "",
      FilterJobTypeID: "",
      FilterJobType: "",
      FilterCustNumber : "",
      SelectingList : [],
      ViewSLot:false
    }
    let filterObj = {
      StartDate: todayDate(15),
      EndDate: DatePlusYMD(15),
      StatusID: 1,
      JobType: ""
    }
    props.getTeams(1);
    props.GetJobTypes();
    props.GetService(filterObj);

  }
  UNSAFE_componentWillReceiveProps = (props) => {

    if (this.state.getCalls[0]) {
      if (props.ServicesSuccess) {
        let succed = this.state.getCalls;
        succed[0] = 0;
        let loaderStatus = succed.includes(1);
        let postalSelected = this.state.PostalCode;
        let ContactNo = this.state.FilterContactNo;
        let CustNo = this.state.FilterCustNumber;
        let services = props.ServicesList;
        services = _.orderBy(services, ['createdAt'], ['asc']);
        let FilteredService = _.filter(services, function (item) {
          return (postalSelected ? PartialSearch(item.postalCode, postalSelected) : true)
            && (CustNo ? PartialSearch(item.customerNumber, CustNo) : true)
            && (ContactNo ? PartialSearch(item.phoneNumber, ContactNo) : true);
        });
        this.setState({
          //ServiceLists: services,
          FilteredServiceLists: FilteredService,
          isLoading: loaderStatus,
          getCalls: succed
        })
      } else if (props.ServicesFailure) {
        let succed = this.state.getCalls;
        succed[0] = 0;
        let loaderStatus = succed.includes(1);
        this.setState({
          //ServiceLists: [],
          FilteredServiceLists: [],
          getCalls: succed,
          isLoading: loaderStatus
        })
      }
    }
    if (this.state.getCalls[1]) {
      if (props.TeamListSuccess) {
        let succed = this.state.getCalls;
        succed[1] = 0;
        let loaderStatus = succed.includes(1);
        let TeamListData = props.TeamLists;
        let newTeam = {
          "id": 0,
          "teamName": "All",
          "vehicleNumber": "",
          "vehicelId": "",
          "assignedStaff": [],
          "teamTypeId": 0,
          "teamTypeName": "all"
      };
      TeamListData.unshift(newTeam);
        this.setState({
          TeamsLists: props.TeamLists,
          AllTeamList : TeamListData,
          isLoading: loaderStatus,
          getCalls: succed
        })
      } else if (props.TeamListFailure) {
        let succed = this.state.getCalls;
        succed[1] = 0;
        let loaderStatus = succed.includes(1);
        this.setState({
          TeamsLists: [],
          getCalls: succed,
          isLoading: loaderStatus
        })
      }
    }
    if (this.state.getCalls[2]) {
      if (props.JobTypesSuccess) {
        let succed = this.state.getCalls;
        succed[2] = 0;
        let loaderStatus = succed.includes(1);
        let Jobs = props.JobTypesList;
        Jobs = _.orderBy(Jobs, ['jobCode'], ['asc']);
        this.setState({
          JobTypeList: Jobs,
          isLoading: loaderStatus,
          getCalls: succed
        })
      } else if (props.JobTypesFailure) {
        let succed = this.state.getCalls;
        succed[2] = 0;
        let loaderStatus = succed.includes(1);
        this.setState({
          JobTypeList: [],
          isLoading: loaderStatus,
          getCalls: succed
        })
      }
    }
    if (this.state.postCalls[0]) {
      if (props.DeleteServiceSuccess) {
        let updatedList = this.state.FilteredServiceLists;
        updatedList.splice(this.state.SelectedIndex, 1);
        this.setState({
          postCalls: [0],
          isLoading: false,
          DeleteModel: false,
          DeleteID: null,
          SelectedIndex: null,
          FilteredServiceLists: updatedList
        }, () => {
          this.props.refreshReduxStates();
          toast.success('Deleted Successfully!', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: false,
          });
        })
      } else if (props.DeleteServicecFailure) {
        this.setState({
          postCalls: [0],
          isLoading: false,
          DeleteModel: false,
          DeleteID: null,
          SelectedIndex: null,
        }, () => {
          toast.error(props.DeleteServiceResponse.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: false,
          });
        })
      }
    }
  }



  //DELETE
  deletePress = (id, index) => {
    this.setState({
      DeleteModel: true,
      DeleteID: id,
      SelectedIndex: index
    })
  }
  DeleteClose = () => {
    this.setState({
      DeleteModel: false,
      DeleteID: null,
      SelectedIndex: null,
    })
  }

  DeleteConfirm = () => {
    this.setState({
      isLoading: true,
      postCalls: [1],
      getCalls: [0, 0, 0]
    }, () => {
      this.props.DeleteService(this.state.DeleteID);
    })
  }
  //DELETE END
  //ADDING
  CloseAdd = () => {
    this.setState({
      CreateModel: false
    })
  }
  AddingService = () => {
    this.setState({
      CreateModel: true
    })
  }
  AddedService = () => {
    let filterObj = {
      StartDate: todayDate(),
      EndDate: DatePlusYMD(15),
      StatusID: 1,
      JobType: ""
    }
    this.setState({
      CreateModel: false,
      isLoading: false,
      getCalls: [1, 0, 0],
    }, () => {
      this.props.GetService(filterObj);
    })
  }
  //ADDING END
  //View
  viewPress = (id, index) => {
    this.setState({
      ViewModel: true,
      ViewID: id,
    })
  }
  //EDITING
  editPress = (id, index) => {
    let ListData = UnChangeObj(this.state.FilteredServiceLists);
    this.setState({
      EditModel: true,
      SelectingList : ListData,
      EditID: id,
      SelectedIndex: index
    })
  }
  CloseEdit = () => {
    this.setState({
      EditModel: false,
      EditID: null,
      SelectingList : [],
      SelectedIndex: null,
    })
  }
  EditedService = (EditedObject) => {
    let ListData = this.state.FilteredServiceLists;
    let EditedID = this.state.EditID;
    let newList = ListData.filter(function (value, index) {
      let Exist = value.id === EditedID;
      if (Exist) {
        value.jobTypeId = EditedObject.jobTypeId;
        value.jobType = EditedObject.JobType;
        value.serviceDate = EditedObject.serviceDate;
        value.fee = EditedObject.fee;
        value.complementary = EditedObject.complementary;
        value.serviceRemarks = EditedObject.serviceRemarks;
      }
      return value;
    });
    this.setState({
      EditModel: false,
      EditID: null,
      SelectingList : [],
      SelectedIndex: null,
      FilteredServiceLists: newList
    }, () => {
      this.props.refreshReduxStates();
    })
  }
  //EDIT END
  //ASSIGN TEAM
  AssignedTeam = (ServiceItems) => {
    let FilterListData = this.state.FilteredServiceLists;
    //let FullExistList = this.state.ServiceLists;
    let selectedService = ServiceItems;//this.state.SelectedServices;
    /* let FullList = FullExistList.filter(function (value, index) {
      let orderExist = selectedService.includes(value.id);
      return !orderExist;
    }) */
    let FilterList = FilterListData.filter(function (value, index) {
      let orderExist = selectedService.includes(value.id);
      return !orderExist;
    })

    this.setState({
      AssignTeamState: false,
      SelectedServices: [],
      //ServiceLists: FullList,
      FilteredServiceLists: FilterList,
      getCalls: [0, 0, 0]
    }, () => {
      this.props.refreshReduxStates();
    })
  }
  CloseAssign = () => {
    this.setState({
      AssignTeamState: false,
      SelectedServices: []
    })
  }
  //ASSIGN TEAM END
  //Assign Slot
  schedulePress = (id, index) => {
    this.setState({
      AssignSlotState: true,
      SelectedServiceID: id
    })
  }
  AssignedSlot = (ServiceItems, selectedSlots, SlotDate) => {
    let FilterListData = this.state.FilteredServiceLists;
    let selectedService = ServiceItems;
    let MobileNo = "";
    let FilterList = FilterListData.filter(function (value, index) {
      let orderExist = value.id == selectedService;
      if(orderExist){
        MobileNo = GetFullMobileNo(value.phoneNumber);
      }
      return !orderExist;
    });
    let Slots = "";
    selectedSlots.map((row, index) => {
      const times = row.split("-");
      if(index>0){
        Slots += '/'+AMPM(times[0]);
      }else{
        Slots += AMPM(times[0]);
      }
    })
   let sortedTimes = selectedSlots.sort(function (a, b) {
      const atime = a.split("-");
      const btime = b.split("-");
      return new Date('1970/01/01 ' + atime[0]) - new Date('1970/01/01 ' + btime[0]);
    });
   if(sortedTimes.length>0){
    const tms = sortedTimes[0].split("-");
      Slots = AMPM(tms[0]);
   }
   
    let smsObj=[{
        "type": "sms",
        "body": "Your appointment for instrument tuning/servicing is confirmed on "+DMYD(SlotDate)+" "+Slots+". Any changes, pls call 62439555 in advance to reschedule. Thank you.",
        "toMobileNo": MobileNo
      }];
    console.log(JSON.stringify(smsObj))
     this.setState({
      AssignSlotState: false,
      SelectedServiceID : null,
      FilteredServiceLists: FilterList,
      getCalls: [0, 0, 0]
    }, () => {
      this.props.SendSMS(smsObj);
      //this.props.refreshReduxStates();
    }) 
  }
  CloseAssignSlot = () => {
    this.setState({
      AssignSlotState: false,
      SelectedServiceID : null,
    })
  }
  //End
  checkedItemsChange = (e, value, tableMeta, updateValue) => {
    let selectedItems = this.state.SelectedServices;
    let Arrayindex = selectedItems.indexOf(value);
    if (selectedItems.includes(value)) {
      if (Arrayindex > -1) {
        selectedItems.splice(Arrayindex, 1);
      }
    } else {
      selectedItems.push(value);
    }
    this.setState({
      SelectedServices: selectedItems
    });
  }
  AssignServiceTeam = () => {
    if (this.state.SelectedServices.length > 0) {
      this.setState({
        AssignTeamState: true
      })
    } else {
      toast.warning('Please choose service!', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: false,
      });
    }
  }
  searchClick = () => {
    if (!this.state.DateStartSelected && !this.state.DateEndSelected && !this.state.PostalCode && !this.state.FilterContactNo  && !this.state.FilterCustNumber) {
      toast.warning('Please choose any one filter option!', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: false,
      });
      return;
    } else if (!this.state.DateStartSelected || !this.state.DateEndSelected) {
      toast.warning("Please choose start and end date", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: false,
      });
      return;
    }
    let dateStartPicked = this.state.DateStartSelected ? this.state.DateStartSelected : null;
    let dateEndPicked = this.state.DateEndSelected ? this.state.DateEndSelected : null;
    let FilterJobType = this.state.FilterJobTypeID ? this.state.FilterJobTypeID : "";
    let filterObj = {
      StartDate: dateStartPicked,
      EndDate: dateEndPicked,
      StatusID: 1,
      JobType: FilterJobType
    }

    this.setState({
      isLoading: true,
      getCalls: [1, 0, 0]
    }, () => {
      this.props.GetService(filterObj);
    })

  }
  clearSearchClick = () => {
    let filterObj = {
      StartDate: todayDate(),
      EndDate: DatePlusYMD(15),
      StatusID: 1,
      JobType: ""
    }
    this.setState({
      DateStartSelected: todayDate(),
      DateEndSelected: DatePlusYMD(15),
      PostalCode: "",
      FilterContactNo: null,
      FilterJobTypeID: null,
      FilterJobType: null,
      FilterCustNumber : "",
      isLoading: true,
      getCalls: [1, 0, 0],
    }, () => {
      this.props.GetService(filterObj);
    })

  }
  CloseList = () => {
    this.setState({
      ViewModel: false,
      ViewID: null,
    })
  }
  OpenTimeSlots = () => {
    this.setState({
      ViewSLot: true
    })
  }
  CloseViewSLot = () => {
    this.setState({
      ViewSLot: false
    })
  }
  MoveToTSlotReport = () => {
    this.props.history.push('/app/timeslotreport')
  }
  render() {
    const { classes } = this.props;
    const columns = [
      {
        name: "id",
        label: "Select",
        options: {
          sort: false,
          filter: false,
          display: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Grid item md={12}>
                <Checkbox
                  checked={this.state.SelectedServices ? (this.state.SelectedServices).includes(value) : false}
                  onChange={(e) => this.checkedItemsChange(e, value, tableMeta, updateValue)}
                  name="checkedB"
                  color="primary"
                  size="small"
                />
              </Grid>
            )
          }
        }
      },
      {
        name: "customerNumber",
        label: "Cust Number",
        options: {
          filter: false,
          sort: true,
          display: true
        }
      },
      {
        name: "customerName",
        label: "Cust Name",
        options: {
          filter: false,
          sort: true,
          display: true
        }
      },
      {
        name: "postalCode",
        label: "Postal",
        options: {
          filter: false,
          sort: true,
          display: true
        }
      },
      {
        name: "phoneNumber",
        label: "Phone",
        options: {
          filter: false,
          sort: true,
          display: true
        }
      },
      {
        name: "email",
        label: "Email",
        options: {
          filter: false,
          sort: false,
          display: false
        }
      },
      {
        name: "items",
        label: "Product Items",
        options: {
          filter: false,
          sort: false,
          display: false
        }
      },
      {
        name: "brand",
        label: "Brand",
        options: {
          filter: false,
          sort: true,
          display: true
        }
      },
      {
        name: "model",
        label: "Model",
        options: {
          filter: false,
          sort: true,
          display: true
        }
      },
      {
        name: "serialNumber",
        label: "Serial No",
        options: {
          filter: false,
          sort: true,
          display: true
        }
      },
      {
        name: "items",
        label: "Product Items",
        options: {
          filter: false,
          sort: false,
          display: false
        }
      },
      {
        name: "serviceDate",
        label: "Service Date",
        options: {
          filter: false,
          sort: true,
          display: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Grid item lg={12}>
                <Typography>{convertDateDMY(value)}</Typography>
              </Grid>
            )
          }
        }
      },
      {
        name: "fee",
        label: "Fee",
        options: {
          filter: false,
          sort: true,
          display: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Grid item lg={12}>
                <Typography>${parseFloat(value).toFixed(2)}</Typography>
              </Grid>
            )
          }
        }
      },
      {
        name: "jobCode",
        label: "Job Code",
        options: {
          filter: false,
          sort: true,
          display: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Grid item lg={12}>
                <Typography>{capsFirstLetter(value)}</Typography>
              </Grid>
            )
          }
        }
      },
      {
        name: "serviceRemarks",
        label: "Remarks",
        options: {
          filter: false,
          sort: true,
          display: true,
          display: true,
        }
      },
      {
        name: "",
        label: "Actions",
        options: {
          filter: false,
          sort: false,
          setCellProps: () => ({ style: { minWidth: "120px" } }),
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <ButtonIcon
                EditTitle={"Edit Service"}
                DeleteTitle={"Delete Service"}
                ScheduleTitle={"Assign Team"}
                Schedule={() => this.schedulePress(tableMeta.rowData[0], tableMeta.rowIndex)}
                Edit={() => this.editPress(tableMeta.rowData[0], tableMeta.rowIndex)}
                Delete={() => this.deletePress(tableMeta.rowData[0], tableMeta.rowIndex)}
              />
            )
          }
        }
      }
    ];


    return (
      <>
        <Grid container spacing={4}>
          <Grid container item spacing={1} md={12} direction={"row"} style={{ marginTop: 0 }}>
            <Grid item md={2}>
              <InputField
                label={"Start Date"}
                type={"date"}
                required={false}
                value={this.state.DateStartSelected}
                changeText={(data) => {
                  this.setState({
                    DateStartSelected: data ? data.target.value : null,
                    DateEndSelected: data ? LastDayMonth(data.target.value) : null
                  })
                }}
                // error={Createerrors.Date}
                initialName={"Start Date"}
                errorMsg={"Please enter date."}
              />
            </Grid>
            <Grid item md={2}>
              <InputField
                label={"End Date"}
                type={"date"}
                required={false}
                value={this.state.DateEndSelected}
                changeText={(data) => { this.setState({ DateEndSelected: data ? data.target.value : null }) }}
                // error={Createerrors.Date}
                initialName={"End Date"}
                errorMsg={"Please enter date."}
              />
            </Grid>
            <Grid item md={2}>
              <Dropdown
                label={"Job Type"}
                data={this.state.JobTypeList}
                getOptionLabel={option => (option.jobCode ? option.jobCode : "")}
                getOptionSelected={(option, value) => option.jobTypeId === value.jobTypeId}
                value={this.state.FilterJobType}
                ItemChange={(event, newValue) => {
                  this.setState({
                    FilterJobTypeID: newValue ? newValue.jobTypeId : null,
                    FilterJobType: newValue ? newValue : null
                  })
                }}
                //error={this.state.errors.FilterJobTypeID}
                initialName={"Job Type"}
                errorMsg={"Please choose job type."}
              />
            </Grid>
            <Grid item md={2} >
              <InputField
                label={"Postal Code"}
                type={"number"}
                required={false}
                value={this.state.PostalCode}
                changeText={(data) => this.setState({ PostalCode: data ? data.target.value : null })}
                // error={Createerrors.Date}
                initialName={"Postal Code"}
                errorMsg={"Please enter postal code."}
              />
            </Grid>
            <Grid item md={2} >
              <InputField
                label={"Contact Number"}
                type={"number"}
                required={false}
                value={this.state.FilterContactNo}
                changeText={(data) => this.setState({ FilterContactNo: data ? data.target.value : null })}
                // error={Createerrors.Date}
                initialName={"Contact Number"}
                errorMsg={"Please enter contact no."}
              />
            </Grid>
            <Grid item md={2} >
              <InputField
                label={"Customer Number"}
                type={"text"}
                required={false}
                value={this.state.FilterCustNumber}
                changeText={(data) => this.setState({ FilterCustNumber: data ? data.target.value : null })}
                // error={Createerrors.Date}
                initialName={"Customer Number"}
                errorMsg={"Please enter customer number."}
              />
            </Grid>
          </Grid>
          <Grid container item spacing={1} md={12} direction={"row"} >
          <Grid item md={1} >
              <Button
                onClick={() => this.clearSearchClick()}
                className={classes.buttonCancel}
                variant="contained" color="primary">
                Clear
                  </Button>
            </Grid>
            <Grid item md={1} >
              <Button
                onClick={() => this.searchClick()}
                className={classes.buttonSuccess}
                variant="contained" color="primary">
                Search
                  </Button>
            </Grid>
          </Grid>
          {/* <Grid container item md={12} direction={"row"}>
            <Grid item md={8} />
            <Grid item md={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                onClick={() => this.MoveToTSlotReport()}
                className={classes.buttonSuccess}
                variant="contained" color="primary">
                Time Slots
                </Button>
            </Grid>
            <Grid item md={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                onClick={() => this.AssignServiceTeam()}
                className={classes.buttonSuccess}
                variant="contained" color="primary">
                Assign Team
                </Button>
            </Grid>
          </Grid> */}

          <Grid item xs={12}>
            <MUIDataTable
              title="Service List"
              data={this.state.FilteredServiceLists}
              columns={columns}
              options={{
                sort: true,
                selectableRows: false,
                print: false,
                download: false,
                viewColumns: false,
                responsive: true,
                filter: false,
                 customToolbar: () => {
                  return (
                    <CustomToolbar title={"Time Slots"} icontype={"time"} iconOnPress={() => this.OpenTimeSlots()} />
                  );
                } 
              }}
            />
          </Grid>
          <AlertDialog
            Status={this.state.DeleteModel}
            DialogConfirm={() => { this.DeleteConfirm() }}
            DialogCancel={() => { this.DeleteClose() }}
            Title={"Delete Service"}
            Content={"Are you sure, you want to delete the service ?"}
          />
          {this.state.CreateModel ? <CreateService
            Title={"Add Service"}
            parentClass={this}
            parentState={this.state}
            Status={this.state.CreateModel}
            ModelConfirm={this.AddedService}
            ModelCancel={this.CloseAdd}
          /> : null}
          {this.state.EditModel ? <EditService
            Title={"Edit Service"}
            parentClass={this}
            parentState={this.state}
            Status={this.state.EditModel}
            ModelConfirm={this.EditedService}
            ModelCancel={this.CloseEdit}
          /> : null}
          {this.state.AssignTeamState ? <AssignTeam
            Title={"Assign Team"}
            parentClass={this}
            parentState={this.state}
            Status={this.state.AssignTeamState}
            ModelConfirm={this.AssignedTeam}
            ModelCancel={this.CloseAssign}
          /> : null}
          {this.state.AssignSlotState ? <AssignTimeSlot
            Title={"Assign Team"}
            parentClass={this}
            parentState={this.state}
            Status={this.state.AssignSlotState}
            ModelConfirm={this.AssignedSlot}
            ModelCancel={this.CloseAssignSlot}
          /> : null}
          {this.state.ViewModel ? <ViewList
            Title={"Service Details"}
            parentClass={this}
            parentState={this.state}
            Status={this.state.ViewModel}
            ModelCancel={this.CloseList}
          /> : null}
          {this.state.ViewSLot ? <ViewTimeSlots
            Title={"Time Slots"}
            parentClass={this}
            parentState={this.state}
            Status={this.state.ViewSLot}
            ModelCancel={this.CloseViewSLot}
          /> : null}
          <LoadingOverlay Status={this.state.isLoading} />
        </Grid>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    ServicesSuccess: state.GetServiceReducer.Success,
    ServicesFailure: state.GetServiceReducer.Failure,
    ServicesList: state.GetServiceReducer.Services,

    DeleteServiceSuccess: state.DeleteServiceReducer.Success,
    DeleteServicecFailure: state.DeleteServiceReducer.Failure,
    DeleteServiceResponse: state.DeleteServiceReducer.DeleteResponse,

    AssignServiceSuccess: state.AssignServiceReducer.Success,
    AssignServiceFailure: state.AssignServiceReducer.Failure,
    AssignServiceDetail: state.AssignServiceReducer.AssignServiceRsponse,

    TeamListSuccess: state.getTeamsReducer.Success,
    TeamListFailure: state.getTeamsReducer.Failure,
    TeamLists: state.getTeamsReducer.TeamList,

    JobTypesSuccess: state.GetJobTypesReducer.Success,
    JobTypesFailure: state.GetJobTypesReducer.Failure,
    JobTypesList: state.GetJobTypesReducer.JobTypes,

    UserDetails: state.LoginReducer.UserDetails,
    LoaderState: state.LoaderReducer.State
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetJobTypes: () => dispatch(GetJobTypes()),
    getTeams: (ID) => dispatch(getTeams(ID)),
    GetService: (filterObj) => dispatch(GetService(filterObj)),
    UpdateService: (reqObject, ID) => dispatch(UpdateService(reqObject, ID)),
    AssignService: (requObj) => dispatch(AssignService(requObj)),
    SendSMS: (requObj) => dispatch(SendSMS(requObj)),
    DeleteService: (ID) => dispatch(DeleteService(ID)),
    refreshReduxStates: () => dispatch(refreshReduxStates()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Service));
