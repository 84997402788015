import React, { useState, useEffect, Component } from 'react';
import {
  Grid, Dialog, DialogActions, DialogContent,
  DialogContentText, DialogTitle, makeStyles,
  Switch, FormControlLabel, IconButton, DeleteIcon,
  EditIcon, _, swal, toast, useSelector, useDispatch
} from '../../utilities/PackagesList';
import {
  PageTitle, CustomToolbar, AlertDialog,
  CustomModel, InputField, Dropdown,
  Buttons, LoadingOverlay
} from "../../utilities/ComponentsList";
import { isEmptyObj } from "../../utilities/AppFunctions";
import { AddTeam } from '../../api/Team';
const useStyles = makeStyles(theme => ({
  dialogTitle: {

  }
}))
const CreateTeams = (props) => {
  const dispatch = useDispatch();
  const createState = useSelector((state) => state.AddTeamReducer);
  const classes = useStyles();
  const [Addvalues, setAddValues] = React.useState({
    Vehicle: '',
    TeamsName: '',
    TeamType: '',
    TeamTypeID: '',
    Staff: [],
    VehicleSelected: [],
    StaffSelected: ''
  });
  const [Createerrors, setCreateErrors] = useState({});

  let TeamTypeList = props.parentState.TeamTypeLists;
  let VehicleList = props.parentState.UnAssignedSV[0].vehicles ? props.parentState.UnAssignedSV[0].vehicles : [];
  let StaffList = props.parentState.UnAssignedSV[0].staffs ? props.parentState.UnAssignedSV[0].staffs : [];
  /*  console.log(JSON.stringify(VehicleList))
   console.log(JSON.stringify(StaffList)) */
  if (createState.Success && !isEmptyObj(createState.AddingResponse)) {
    toast.success('Added Successfully!', {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: false,
    });
    props.parentClass.AddedTeam()
  } else if (createState.Failure && !isEmptyObj(createState.AddingResponse)) {
    toast.error(createState.AddingResponse.message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: false,
    });
  }
  const handleChange = name => event => {

    let targetValue = event.target.value;
    setAddValues({ ...Addvalues, [name]: targetValue });
    setCreateErrors({ ...Createerrors, [name]: targetValue ? false : true })
  };

  const submitForm = () => {
    let srrorObj = {};
    let errorStatus = false;
    Object.keys(Addvalues).map(function (k) {

      srrorObj[k] = Addvalues[k] ? false : true;
      errorStatus = errorStatus ? true : !Addvalues[k];

      if (k === 'StaffSelected') {
        srrorObj[k] = (Addvalues[k].length > 0) ? false : true;
        errorStatus = errorStatus ? true : !(Addvalues[k].length > 0);
      }

    })
    setCreateErrors(srrorObj);
    if (!errorStatus) {
      let addObject = {
        vehicleId: Addvalues.VehicleSelected,
        staffId: Addvalues.StaffSelected,
        teamName: Addvalues.TeamsName,
        teamTypeId: Addvalues.TeamTypeID,
      }
      //console.log(JSON.stringify(addObject))
      dispatch(AddTeam(addObject));
    }
  };

  return (
    <Dialog
      open={props.Status}
      //onClose={()=>this.closeDialog()}
      fullWidth
      maxWidth="sm"
      disableBackdropClick={false}
      disableEscapeKeyDown={false}
    >
      <DialogTitle className={classes.dialogTitle} id="dialog-title">{props.Title}</DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={4}>
          <Grid item md={12}>
            <Grid
              container
              spacing={6}
              direction="column"
            >
              <Grid item md={12}>
                <InputField
                  label="Team Name"
                  type={"text"}
                  required={true}
                  value={Addvalues.TeamsName}
                  changeText={handleChange('TeamsName')}
                  error={Createerrors.TeamsName}
                  initialName={"Name"}
                  errorMsg={"Please enter team name."}
                />
              </Grid>
              <Grid item md={12} >
                <Dropdown
                  label={"Team Type"}
                  data={TeamTypeList}
                  getOptionLabel={option => (option.teamTypeName ? option.teamTypeName : "")}
                  getOptionSelected={(option, value) => option.teamTypeId === value.teamTypeId}
                  value={Addvalues.TeamType}
                  ItemChange={(event, newValue) => {
                    setAddValues({
                      ...Addvalues,
                      TeamTypeID: newValue ? newValue.teamTypeId : null,
                      TeamType: newValue ? newValue : null
                    });
                    setCreateErrors({
                      ...Createerrors,
                      TeamTypeID: newValue ? false : true,
                      TeamType: newValue ? false : true
                    })
                  }}
                  error={Createerrors.TeamTypeID}
                  initialName={"Team Type"}
                  errorMsg={"Please choose team type."}
                />
              </Grid>
              <Grid item md={12} >
                <Dropdown
                  label={"Vehicle Number"}
                  data={VehicleList}
                  getOptionLabel={option => (option.vehicleNumber ? option.vehicleNumber : "")}
                  getOptionSelected={(option, value) => option.vehicleId === value.vehicleId}
                  value={Addvalues.Vehicle}
                  ItemChange={(event, newValue) => {
                    setAddValues({
                      ...Addvalues,
                      VehicleSelected: newValue ? newValue.vehicleId : null,
                      Vehicle: newValue ? newValue : null
                    });
                    setCreateErrors({
                      ...Createerrors,
                      VehicleSelected: newValue ? false : true,
                      Vehicle: newValue ? false : true
                    })
                  }}
                  error={Createerrors.Vehicle}
                  initialName={"Number"}
                  errorMsg={"Please choose vehicle number."}
                />
              </Grid>
              <Grid item md={12}>
                <Dropdown
                  label={"Staff"}
                  data={StaffList}
                  multiple={true}
                  filterSelectedOptions={true}
                  getOptionLabel={option => (option.staffName ? option.staffName : "")}
                  getOptionSelected={(option, value) => option.staffId === value.staffId}
                  value={Addvalues.Staff}
                  ItemChange={(event, newValue) => {
                    if (newValue !== null) {
                      const items = [];
                      const IDs = [];
                      newValue.map((v) => {
                        items.push(v);
                        IDs.push(v.staffId)
                      });
                      setAddValues({
                        ...Addvalues,
                        StaffSelected: IDs ? IDs : null,
                        Staff: items ? items : null
                      });
                      setCreateErrors({
                        ...Createerrors,
                        StaffSelected: IDs.length > 0 ? false : true,
                        Staff: IDs.length > 0 ? false : true
                      })
                    }
                  }}
                  error={Createerrors.StaffSelected}
                  initialName={"Staff"}
                  errorMsg={"Please choose staff."}
                />
              </Grid>
            </Grid>

          </Grid>
          <Buttons
            close={() => props.parentClass.CloseCreate()}
            closeText={"Cancel"}
            save={() => submitForm()}
            saveText={"Submit"}
          />
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default CreateTeams;