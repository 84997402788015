import React, { useState, useEffect, Component } from 'react';
import {
    Grid, Dialog, DialogActions, DialogContent,
    DialogContentText, DialogTitle, makeStyles,
    Switch, FormControlLabel, IconButton, DeleteIcon,
    EditIcon, _, swal, toast, useSelector, useDispatch
} from '../../utilities/PackagesList';
import {
    PageTitle, CustomToolbar, AlertDialog,
    CustomModel, InputField, Dropdown,
    Buttons, LoadingOverlay
} from "../../utilities/ComponentsList";
import { isEmptyObj } from "../../utilities/AppFunctions";
import { UpdateAdhoc } from '../../api/Adhoc';
const useStyles = makeStyles(theme => ({
    dialogTitle: {

    }
}))
const EditAdhoc = (props) => {
    const dispatch = useDispatch();
    const EditState = useSelector((state) => state.UpdateAdhocReducer);
    const classes = useStyles();
    const [Editvalues, setEditValues] = useState({
        Address: '',
        PostalCode: '',
        CustomerNumber : '',
        CustomerName:'',
        Items:'',
        Remarks:''
      });
      
    const [Editerrors, setEditErrors] = useState({});

    let ParentState = props.parentState;
    let userSelection = ParentState.SelectedIndex;
    let OrderListsData = ParentState.OrderLists ? ParentState.OrderLists[userSelection] : [];
   
    useEffect(() => {
        if(ParentState.EditID){
          setEditValues({
            Address: ParentState.EditID ? OrderListsData.address : "",
            PostalCode: ParentState.EditID ? OrderListsData.postalCode : "",
            CustomerNumber: ParentState.EditID ? OrderListsData.contactNumber : "",
            CustomerName: ParentState.EditID ? OrderListsData.customerName : "",
            Items : ParentState.EditID ? OrderListsData.items : "",
            Remarks:''
          })
        } 
        
      }, [ParentState.EditID])
      if (EditState.Success && !isEmptyObj(EditState.UpdateResponse)) {
        toast.success('Updated Successfully!', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: false,
        });
        let EditedObject = OrderListsData;
         EditedObject.customerName= Editvalues.CustomerName;
         EditedObject.customerNumber= Editvalues.CustomerNumber;
         EditedObject.address=Editvalues.Address;
         EditedObject.postalCode = Editvalues.PostalCode;
         EditedObject.items = Editvalues.Items;
         EditedObject.remarks = Editvalues.Remarks
        props.parentClass.EditedAdhoc(EditedObject)
    } else if (EditState.Failure && !isEmptyObj(EditState.UpdateResponse)) {
        toast.error(EditState.UpdateResponse.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: false,
        });
    }
    const handleChange = name => event => {
        let targetValue = event.target.value;
        setEditValues({ ...Editvalues, [name]: targetValue });
        setEditErrors({ ...Editerrors, [name]: targetValue ? false : true })
      };
      const saveEdit = () => {
        let srrorObj = {};
        let errorStatus = false;
        Object.keys(Editvalues).map(function (k) {
          srrorObj[k] = Editvalues[k] ? false : true;
          errorStatus = errorStatus ? true : !Editvalues[k];
        })
        setEditErrors(srrorObj);
        if(Editvalues.CustomerName && Editvalues.CustomerNumber && 
            Editvalues.Address && Editvalues.PostalCode && Editvalues.Items){
          let updateObject = {
            customerName: Editvalues.CustomerName,
            customerNumber : Editvalues.CustomerNumber,
            address : Editvalues.Address,
            postalCode : Editvalues.PostalCode,
            items : Editvalues.Items,
            remarks : Editvalues.Remarks
          }
         
          dispatch(UpdateAdhoc(updateObject,ParentState.EditID));
        } 
      };

      return (
        <Dialog
          open={props.Status}
          fullWidth
          maxWidth="sm"
          disableBackdropClick={false}
          disableEscapeKeyDown={false}
        >
          <DialogTitle className={classes.dialogTitle} id="dialog-title">{props.Title}</DialogTitle>
          <DialogContent dividers>
          <Grid container spacing={4}>
          <Grid item md={12}>
            <Grid
              container
              spacing={6}
              direction="row">
              <Grid
                  item
                  md={6}
                  spacing={6}
                >
                 <InputField
                    label={"Customer Name"}
                    type={"text"}
                    required={true}
                    value={Editvalues.CustomerName}
                    changeText={handleChange('CustomerName')}
                    error={Editerrors.CustomerName}
                    initialName={"Customer Name"}
                    errorMsg={"Please enter customer name."}
                  />
              </Grid>
              <Grid
                  item
                  md={6}
                  spacing={6}
                >
                 <InputField
                    label={"Contact Number"}
                    type={"number"}
                    required={true}
                    value={Editvalues.CustomerNumber}
                    changeText={handleChange('CustomerNumber')}
                    error={Editerrors.CustomerNumber}
                    initialName={"Contact Number"}
                    errorMsg={"Please enter contact number."}
                  />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={6}
              direction="row">
              <Grid
                  item
                  md={6}
                  spacing={6}
                >
                 <InputField
                    label={"Address"}
                    type={"text"}
                    required={true}
                    value={Editvalues.Address}
                    changeText={handleChange('Address')}
                    error={Editerrors.Address}
                    initialName={"Address"}
                    errorMsg={"Please enter address."}
                  />
              </Grid>
              <Grid
                  item
                  md={6}
                  spacing={6}
                >
                 <InputField
                    label={"Postal Code"}
                    type={"number"}
                    required={true}
                    value={Editvalues.PostalCode}
                    changeText={handleChange('PostalCode')}
                    error={Editerrors.PostalCode}
                    initialName={"Postal Code"}
                    errorMsg={"Please enter postal code."}
                  />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={6}
              >
                <Grid
                  item
                  md={12}
                  spacing={6}
                >
                <InputField
                    label={"Items"}
                    type={"text"}
                    multiline={true}
                    required={true}
                    rows={5}
                    value={Editvalues.Items}
                    changeText={handleChange('Items')}
                    error={Editerrors.Items}
                    initialName={"Items"}
                    errorMsg={"Please enter Items."}
                  />
                  </Grid>
              </Grid>
              <Grid
              container
              spacing={6}
              >
                <Grid
                  item
                  md={12}
                  spacing={6}
                >
                <InputField
                    label={"Remarks"}
                    type={"text"}
                    multiline={true}
                    required={true}
                    rows={3}
                    value={Editvalues.Remarks}
                    changeText={handleChange('Remarks')}
                    error={Editerrors.Remarks}
                    initialName={"Remarks"}
                    errorMsg={"Please enter remarks."}
                  />
                  </Grid>
              </Grid>
          </Grid>
          <Buttons
              close={() => props.parentClass.CloseEdit()}
              closeText={"Cancel"}
              save={() => saveEdit()}
              saveText={"Update"}
            />
        </Grid>
     </DialogContent>
        </Dialog>
      );
}

export default EditAdhoc;