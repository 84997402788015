import React, { useState, useEffect, Component } from 'react';
import {
    Grid, Dialog, GridList, DialogContent,
    GridListTile, DialogTitle, makeStyles,
    Typography, Divider, IconButton, DeleteIcon,
    EditIcon, _, swal, toast, useSelector, useDispatch,
    Card, CardMedia, CardContent, CardActions, Box, Button,
    List, ListItem, ListItemIcon, ListItemText, Paper,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow
} from '../../utilities/PackagesList';
import {
    PageTitle, CustomToolbar, AlertDialog,
    CustomModel, InputField, Dropdown,
    Buttons, LoadingOverlay
} from "../../utilities/ComponentsList";
import { getTeamOrder, AdhocScheduleDelivery } from '../../api/Adhoc';
import { isEmptyObj, DMY, GetYorN, convertDateDMY, capsFirstLetter } from "../../utilities/AppFunctions";
const useStyles = makeStyles(theme => ({
    dialogTitle: {

    },
    dialogWindow: {
        width: '80%'
    },
    root: {
        display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    },
    gridList: {
        width: 500,
        height: 300,
      },
    cardStyle: {
        height: 90,
        padding: 10,
        margin: 10,
        boxShadow: "none",
        borderRadius: 10,
        backgroundColor: '#000'

    },
    tableRightBorder: {
        borderWidth: 0,
        borderRightWidth: 1,
        borderColor: 'black',
        borderStyle: 'solid',
    },
    buttonCancel: {
        backgroundColor: theme.palette.btns.default,
        opacity: 0.9,
        borderRadius: 30,
        '&:hover': {
            backgroundColor: theme.palette.btns.gray
        }
    }
}))
const ViewList = (props) => {
    const classes = useStyles();
    /* const [items, setitems] = useState({
        JobType: "",
        ServiceDate: "",
        Fees: "",
        Complementary: "",
        ServiceCreated: "",
    }); */

    let ParentState = props.parentState;
    let userSelection = ParentState.ViewID;
    let Services = ParentState.FilteredServiceLists ? ParentState.FilteredServiceLists : [];
    let ServiceById = _.filter(Services, { 'id': userSelection });
    ServiceById = _.orderBy(ServiceById, ['serviceDate'],['asc']);
    let ServiceData = [];
    if(ServiceById.length>0){
        ServiceData = ServiceById[0].serviceDetails;
    }
    /* useEffect(() => {
        if (ParentState.ViewID) {
            setitems({
                JobType: ParentState.EditID ? ServiceData.jobType : "",
                ServiceDate: ParentState.EditID ? ServiceData.serviceDate : "",
                Fees: ParentState.EditID ? ServiceData.fee : "",
                Complementary: ParentState.EditID ? ServiceData.complementary : "",
                ServiceCreated: ParentState.EditID ? ServiceData.createdAt : "",
            })
        }

    }, [ParentState.EditID]) */
    return (
        <Dialog
            open={props.Status}
            fullWidth={true}
            fullScreen={false}
            maxWidth="xs"
            disableBackdropClick={false}
            disableEscapeKeyDown={false}
        >
            <DialogTitle className={classes.dialogTitle} id="dialog-title">{props.Title}</DialogTitle>
            <DialogContent dividers >
                <Grid container spacing={4} >
                <div className={classes.root}>
                <GridList container cols={1} className={classes.gridList} >
                                    {ServiceData.map((items,index) => (
                                        <GridListTile key={items.id} >
                                        <Grid item spacing={4} md={12} style={{paddingTop:5,paddingBottom:10}}>
                                        {/* <Typography variant="h6">Service {index+1}</Typography> */}
                                        <Grid item md={12}>
                                            <Grid container spacing={4} direction="row">
                                                <Grid item md={6} spacing={4}
                                                >
                                                    <Typography variant="body2">Job Type</Typography>
                                                </Grid>
                                                <Grid item md={6} spacing={4}
                                                >
                                                    <Typography variant="body2">{capsFirstLetter(items.jobType)}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item md={12}>
                                            <Grid container spacing={4} direction="row">
                                                <Grid item md={6} spacing={4}
                                                >
                                                    <Typography variant="body2">Service Date</Typography>
                                                </Grid>
                                                <Grid item md={6} spacing={4}
                                                >
                                                    <Typography variant="body2">{convertDateDMY(items.serviceDate)}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item md={12}>
                                            <Grid container spacing={4} direction="row">
                                                <Grid item md={6} spacing={4}
                                                >
                                                    <Typography variant="body2">Service Fee</Typography>
                                                </Grid>
                                                <Grid item md={6} spacing={4}
                                                >
                                                    <Typography variant="body2">${parseFloat(items.fee).toFixed(2)}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item md={12}>
                                            <Grid container spacing={4} direction="row">
                                                <Grid item md={6} spacing={4}
                                                >
                                                    <Typography variant="body2">Complementary</Typography>
                                                </Grid>
                                                <Grid item md={6} spacing={4}
                                                >
                                                    <Typography variant="body2">{GetYorN(items.complementary)}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item md={12}>
                                            <Grid container spacing={4} direction="row">
                                                <Grid item md={6} spacing={4}
                                                >
                                                    <Typography variant="body2">Created</Typography>
                                                </Grid>
                                                <Grid item md={6} spacing={4}
                                                >
                                                    <Typography variant="body2">{convertDateDMY(items.createdAt)}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        
                                    </Grid>
                                    <Divider/>   
                                    </GridListTile>
                                    ))}
                                    {ServiceData.length==0 ? 
                                    <GridListTile key={1} >
                                        <Typography variant="body2" align="center" style={{paddingTop:50}}>No Service found</Typography>
                                    </GridListTile>
                                    :null
                                    }
                                </GridList>
                   {/*  {ServiceData.map((items) => {
                        return (
                            <Grid item spacing={4} md={12}>
                                <Grid item md={12}>
                                    <Grid container spacing={4} direction="row">
                                        <Grid item md={6} spacing={4}
                                        >
                                            <Typography variant="body2">Job Type</Typography>
                                        </Grid>
                                        <Grid item md={6} spacing={4}
                                        >
                                            <Typography variant="body2">{capsFirstLetter(items.jobType)}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={12}>
                                    <Grid container spacing={4} direction="row">
                                        <Grid item md={6} spacing={4}
                                        >
                                            <Typography variant="body2">Service Date</Typography>
                                        </Grid>
                                        <Grid item md={6} spacing={4}
                                        >
                                            <Typography variant="body2">{convertDateDMY(items.serviceDate)}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={12}>
                                    <Grid container spacing={4} direction="row">
                                        <Grid item md={6} spacing={4}
                                        >
                                            <Typography variant="body2">Service Fee</Typography>
                                        </Grid>
                                        <Grid item md={6} spacing={4}
                                        >
                                            <Typography variant="body2">${parseFloat(items.fee).toFixed(2)}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={12}>
                                    <Grid container spacing={4} direction="row">
                                        <Grid item md={6} spacing={4}
                                        >
                                            <Typography variant="body2">Complementary</Typography>
                                        </Grid>
                                        <Grid item md={6} spacing={4}
                                        >
                                            <Typography variant="body2">{GetYorN(items.complementary)}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={12}>
                                    <Grid container spacing={4} direction="row">
                                        <Grid item md={6} spacing={4}
                                        >
                                            <Typography variant="body2">Created</Typography>
                                        </Grid>
                                        <Grid item md={6} spacing={4}
                                        >
                                            <Typography variant="body2">{convertDateDMY(items.createdAt)}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )
                    })
                    } */}
                    </div>
                    <Grid container md={12} direction="row" item style={{ display: "flex", justifyContent: "flex-end" }}>

                        <Button
                            onClick={() => props.parentClass.CloseList()}
                            variant="contained"
                            color="secondary"
                            className={classes.buttonCancel}
                        >
                            Close
                        </Button>

                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

export default ViewList;