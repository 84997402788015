import {makeStyles} from './../../utilities/PackagesList';
export default makeStyles(theme => ({
  loginLoader: {
    marginLeft: theme.spacing(4),
    color:"#fff"
  },
  dialogContent:{
    width:'100%',
    alignItems: "center",
    display: "flex", 
    justifyContent: "center",

  },
  dialogWindow:{
    backgroundColor:'#000'
  }
}));