import { returnApiCallPromise } from './ApiContent';
import {isJson} from './../utilities/AppFunctions';
export function getStaffs() {
   
    let patameter = "staff";
    return (dispatch) => {
        dispatch({ type: 'FETCHING_STAFFS' });
        returnApiCallPromise("GET", patameter)
            .then(response => {     
                //console.log(response)          
                dispatch({
                    type: 'STAFFS_SUCCESS',
                    data: response.data.data
                })
            }).catch(error => {
                let errorMessage = error;
                if(error.response){
                    if(error.response.data.error){
                        errorMessage = error.response.data;
                    }
                } 
                dispatch({
                    type: 'STAFFS_FAILURE',
                    data: errorMessage
                })
            })
    }
}
export function getStaffTypes() {
   
    let patameter = "staff-type";
    return (dispatch) => {
        dispatch({ type: 'FETCHING_STAFF_TYPES' });
        returnApiCallPromise("GET", patameter)
            .then(response => {     
                //console.log(response)          
                dispatch({
                    type: 'STAFF_TYPES_SUCCESS',
                    data: response.data.data
                })
            }).catch(error => {
                let errorMessage = error;
                if(error.response){
                    if(error.response.data.error){
                        errorMessage = error.response.data;
                    }
                } 
                dispatch({
                    type: 'STAFF_TYPES_FAILURE',
                    data: errorMessage
                })
            })
    }
}
export function UpdateStaff(UpdateObject, StaffID) {
   
    let subURL = "staff/"+StaffID;
   
    return (dispatch) => {
        returnApiCallPromise("PUT", subURL, UpdateObject)
            .then(response => { 
                if(response.data.error){
                    dispatch({
                        type: 'STAFF_UPDATE_FAILURE',
                        data: response.data.message
                    })
                }else{
                    dispatch({
                        type: 'STAFF_UPDATE_SUCCESS',
                        data: response.data
                    })
                }    
               /*  dispatch({
                    type: 'STAFF_UPDATE_SUCCESS',
                    data: response.data
                }) */
            }).catch(error => { 
                let errorMessage = error;
                if(error.response){
                    if(error.response.data.error){
                        errorMessage = error.response.data;
                    }
                } 
                dispatch({
                    type: 'STAFF_UPDATE_FAILURE',
                    data: errorMessage
                })
            })
    }
}
export function DeleteStaff(StaffID) {
   
    let subURL = "staff/"+StaffID;
    
    return (dispatch) => {
        returnApiCallPromise("DELETE", subURL, null)
            .then(response => {
                if(response.data.error){
                    dispatch({
                        type: 'STAFF_DELETE_FAILURE',
                        data: response.data.message
                    })
                }else{
                    dispatch({
                        type: 'STAFF_DELETE_SUCCESS',
                        data: response.data
                    })
                } 
               /*  dispatch({
                    type: 'STAFF_DELETE_SUCCESS',
                    data: response.data
                }) */
            }).catch(error => { 
                let errorMessage = error;
                if(error.response){
                    if(error.response.data.error){
                        errorMessage = error.response.data;
                    }
                } 
                dispatch({
                    type: 'STAFF_DELETE_FAILURE',
                    data: errorMessage
                })
            })
    }
}
export function AddStaff(AddObject) {
   
    let subURL = "staff/";
    return (dispatch) => {
        returnApiCallPromise("POST", subURL, AddObject)
            .then(response => { 
                if(response.data.error){
                    dispatch({
                        type: 'STAFF_ADD_FAILURE',
                        data: response.data.message
                    })
                }else{
                    dispatch({
                        type: 'STAFF_ADD_SUCCESS',
                        data: response.data
                    })
                } 
               /*  dispatch({
                    type: 'STAFF_ADD_SUCCESS',
                    data: response.data
                }) */
            }).catch(error => { 
                let errorMessage = error;
                if(error.response){
                    if(error.response.data.error){
                        errorMessage = error.response.data;
                    }
                }  
                dispatch({
                    type: 'STAFF_ADD_FAILURE',
                    data: errorMessage
                })
            })
    }
}