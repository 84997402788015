export function LoaderReducer(state = {}, action) {    
    switch (action.type) {
        case 'LOADING': {
            return {
                ...state,
                State: true
            }
        }
        case 'UNLOADING': {
            return {
                ...state,
                State: false
            }
        }
        default: {
            return state;
        }
    }
}