import React, { useState, useEffect, Component } from 'react';
import {
    Grid, Dialog, DialogActions, DialogContent,
    DialogContentText, DialogTitle, makeStyles,
    Switch, FormControlLabel, IconButton, DeleteIcon,
    EditIcon, _, swal, toast, useSelector, useDispatch
} from '../../utilities/PackagesList';
import {
    PageTitle, CustomToolbar, AlertDialog,
    CustomModel, InputField, Dropdown,
    Buttons, LoadingOverlay, PasswordField
} from "../../utilities/ComponentsList";
import { isEmptyObj } from "../../utilities/AppFunctions";
import { UpdateStaff } from '../../api/Staff';
import useStyles from "./../../utilities/Styles";
/* const useStyles = makeStyles(theme => ({
    dialogTitle: {

    }
})) */
const EditStaffs = (props) => {
    const dispatch = useDispatch();
    const EditState = useSelector((state) => state.UpdateStaffReducer);
    const classes = useStyles();
    const [Editvalues, setEditValues] = useState({
        EEmpID:"",
        EFName: "",
        ELName: "",
        EEmail: "",
        EPhone: "",
        EStaffTypeSelected: '',
        EStaffType: '',
        EPassword:'',
        EStatus: false,
        showPassword:false
      });
      
    const [Editerrors, setEditErrors] = useState({});
    let ParentState = props.parentState;
    let userSelection = ParentState.SelectedIndex;
    let EditingID = ParentState.EditID;
   // console.log(userSelection,JSON.stringify(ParentState))
    let staffTypeList = ParentState.StaffTypes ? ParentState.StaffTypes : [];
    let staffList = ParentState.StaffLists ? ParentState.StaffLists : [];
    staffList = _.filter(staffList, { 'id': EditingID });  
    let editDetails = ParentState.EditID ? staffList[0] : null;
    let typeSelected = ParentState.EditID ? _.filter(staffTypeList, { 'staffType': editDetails.staffTypeId }) : [];
    //console.log(userSelection,JSON.stringify(editDetails))
    useEffect(() => {
        if(ParentState.EditID){
          setEditValues({
            EEmpID : ParentState.EditID ? editDetails.empId : "",
            EFName: ParentState.EditID ? editDetails.firstName : "",
            ELName: ParentState.EditID ? editDetails.lastName : "",
            EEmail: ParentState.EditID ? editDetails.email : "",
            EPhone: ParentState.EditID ? editDetails.phone : "",
            EStaffTypeSelected: ParentState.EditID ? typeSelected[0].id : "",
            EStaffType: ParentState.EditID ? typeSelected[0] : "",
            EPassword: "",
            EStatus: ParentState.EditID ? true : false,
            modelState: props.Status,
            showPassword:false
          })
        }
        
      }, [ParentState.EditID])

    if (EditState.Success && !isEmptyObj(EditState.UpdateResponse)) {
        toast.success('Updated Successfully!', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: false,
        });
        let EditedObject={
            "id": parseInt(ParentState.EditID),
            "empId": Editvalues.EEmpID,
            "firstName": Editvalues.EFName,
            "lastName": Editvalues.ELName,
            "email": Editvalues.EEmail,
            "password": Editvalues.EPassword ? Editvalues.EPassword : "",
            "phone": Editvalues.EPhone,
            "staffTypeId": Editvalues.EStaffType.staffType
        }
        props.parentClass.EditedStaff(EditedObject)
    } else if (EditState.Failure && !isEmptyObj(EditState.UpdateResponse)) {
        toast.error(EditState.UpdateResponse.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: false,
        });
    }
    const handleChange = name => event => {
        let targetValue = event.target.value;
        /* if (name === 'EStatus') {
          targetValue = !Editvalues.Status;
        } */
        setEditValues({ ...Editvalues, [name]: targetValue });
        setEditErrors({ ...Editerrors, [name]: targetValue ? false : true })
      };
      const saveEdit = () => {
        let srrorObj = {};
        let errorStatus = false;
        Object.keys(Editvalues).map(function (k) {
          if(k==='vehicleNumber'){
            srrorObj[k] = Editvalues[k] ? false : true;
            errorStatus = errorStatus ? true : false;
          }else if(k=='EStatus' || k=='modelState' || k=='showPassword'){
            srrorObj[k] = false;
            errorStatus = errorStatus ? true : false;
          }else{
            srrorObj[k] = Editvalues[k] ? false : true;
            errorStatus = errorStatus ? true : !Editvalues[k];
          }
        })
        setEditErrors(srrorObj);
        if(Editvalues.EEmpID && Editvalues.EStaffTypeSelected && 
            Editvalues.EFName && Editvalues.ELName && Editvalues.EEmail && Editvalues.EPhone){
          let updateObject = {
            empId: Editvalues.EEmpID,
            firstName : Editvalues.EFName,
            lastName: Editvalues.ELName,
            email : Editvalues.EEmail,
            phone : Editvalues.EPhone,
            password : Editvalues.EPassword ? Editvalues.EPassword : "",
            staffTypeId : Editvalues.EStaffTypeSelected,
            isActive : Editvalues.EStatus,
            roleId : 1
          }
         // console.log(JSON.stringify(updateObject))
          dispatch(UpdateStaff(updateObject,ParentState.EditID));
        } 
      };

    return (
        <Dialog
            open={Editvalues.modelState}
            //onClose={()=>this.closeDialog()}
            fullWidth
            maxWidth="sm"
            disableBackdropClick={false}
            disableEscapeKeyDown={false}
        >
            <DialogTitle className={classes.dialogTitle} id="dialog-title">{props.Title}</DialogTitle>
            <DialogContent dividers>
            <Grid container spacing={4}>
            <Grid item md={12}>
            <Grid
                container
                direction="row"
            >
                <Grid item md={5}>
                <Dropdown
                    label={"Staff Type"}
                    data={staffTypeList}
                    getOptionLabel={option => (option.staffType ? option.staffType : "")}
                    getOptionSelected={(option, value) => option.id === value.id}
                    value={Editvalues.EStaffType}
                    ItemChange={(event, newValue) => {
                    setEditValues({
                        ...Editvalues,
                        EStaffTypeSelected: newValue ? newValue.id : null,
                        EStaffType: newValue ? newValue : null
                    });
                    setEditErrors({
                        ...Editerrors,
                        EStaffTypeSelected: newValue ? false : true,
                        EStaffType: newValue ? false : true
                    })
                    }}
                    error={Editerrors.EStaffTypeSelected}
                    initialName={"Type"}
                    errorMsg={"Please choose vehicle type."}
                />
                </Grid>
                <Grid item md={1} />
                <Grid item md={5}>
                <InputField
                    label="Employee ID"
                    type={"number"}
                    required={true}
                    value={Editvalues.EEmpID}
                    changeText={handleChange('EEmpID')}
                    error={Editerrors.EEmpID}
                    initialName={"ID"}
                    errorMsg={"Please enter employee id."}
                />
                </Grid>
                <Grid item md={1} />
            </Grid>
            </Grid>
            <Grid item md={12}>
            <Grid
                container
                direction="row"
            >
                <Grid item md={5}>
                <InputField
                    label="First Name"
                    type={"text"}
                    required={true}
                    value={Editvalues.EFName}
                    changeText={handleChange('EFName')}
                    error={Editerrors.EFName}
                    initialName={"First Name"}
                    errorMsg={"Please enter first name."}
                />
                </Grid>
                <Grid item md={1} />
                <Grid item md={5}>
                <InputField
                    label="Last Name"
                    type={"text"}
                    required={true}
                    value={Editvalues.ELName}
                    changeText={handleChange('ELName')}
                    error={Editerrors.ELName}
                    initialName={"Last Name"}
                    errorMsg={"Please enter last name."}
                />
                </Grid>
                <Grid item md={1} />
            </Grid>

            </Grid>
            <Grid item md={12}>
            <Grid
                container
                direction="row"
            >
                <Grid item md={5}>
                <InputField
                    label="Email"
                    type={"email"}
                    required={true}
                    value={Editvalues.EEmail}
                    changeText={handleChange('EEmail')}
                    error={Editerrors.EEmail}
                    initialName={"Email"}
                    errorMsg={"Please enter email address."}
                />
                </Grid>
                <Grid item md={1} />
                <Grid item md={5}>
                <InputField
                    label="Phone"
                    type={"tel"}
                    required={true}
                    value={Editvalues.EPhone}
                    changeText={handleChange('EPhone')}
                    error={Editerrors.EPhone}
                    initialName={"Phone"}
                    errorMsg={"Please enter phone number."}
                />
                </Grid>
                <Grid item md={1} />
            </Grid>

            </Grid>
            <Grid item md={12}>
            <Grid
                container
                direction="row"
            >
                <Grid item md={5}>
                <PasswordField
                    label="Password"
                    type={Editvalues.showPassword ? "text" : "password"}
                    required={false}
                    value={Editvalues.EPassword}
                    showText={()=>setEditValues({ ...Editvalues, showPassword: !Editvalues.showPassword })}
                    HideText={Editvalues.showPassword}
                    changeText={handleChange('EPassword')}
                    //error={Editerrors.EPassword}
                    initialName={"Password"}
                    errorMsg={"Please enter password."}
                />
                </Grid>
                <Grid item md={1} />
                <Grid item md={5}/>
                <Grid item md={1} />
            </Grid> 

            </Grid>
            <Grid item md={12}>
            <FormControlLabel
            fullWidth={true}
            value={Editvalues.Status}
            control={
                <Switch
                size="medium"
                checked={Editvalues.EStatus}
                onChange={()=>{setEditValues({...Editvalues,EStatus:!Editvalues.EStatus})}}
                inputProps={{ 'aria-label': 'primary' }}
                color="primary" />
            }
            label="Activate"
            fullWidth={true}
            labelPlacement="start"
            />
        </Grid> 
            <Buttons
            close={() => props.parentClass.CloseEdit()}
            closeText={"Cancel"}
            save={() => saveEdit()}
            saveText={"Update"}
            />
        </Grid>
        </DialogContent>
        </Dialog>
    );
}

export default EditStaffs;