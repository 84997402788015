import React, { useState, useEffect, Component } from 'react';
import {
    Grid, Dialog, GridList, DialogContent,
    DialogActions, DialogTitle, makeStyles,
    Typography, Divider, IconButton, DeleteIcon,
    EditIcon, _, swal, toast, useSelector, useDispatch,
    Card, CardMedia, CardContent, CardActions, Box, Button,
    List, ListItem, ListItemIcon, ListItemText, Paper,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Link
} from '../../utilities/PackagesList';
import {
    PageTitle, CustomToolbar, AlertDialog,
    CustomModel, InputField, Dropdown,
    Buttons, LoadingOverlay
} from "../../utilities/ComponentsList";
import { GetFilledServiceForms } from '../../api/Services';
import { refreshReduxStates } from './../../api/Refresh';
import { isEmptyObj, DMY, GetYorN, dateTimeFormating, PriceFormat, capsFirstLetter } from "../../utilities/AppFunctions";
const useStyles = makeStyles(theme => ({
    dialogTitle: {

    },
    dialogWindow: {
        width: '80%'
    },
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
    },
    gridList: {
        width: 500,
        height: 300,
    },
    cardStyle: {
        height: 90,
        padding: 10,
        margin: 10,
        boxShadow: "none",
        borderRadius: 10,
        backgroundColor: '#000'

    },
    tableRightBorder: {
        borderWidth: 0,
        borderRightWidth: 1,
        borderColor: 'black',
        borderStyle: 'solid',
    },
    buttonCancel: {
        backgroundColor: theme.palette.btns.default,
        opacity: 0.9,
        borderRadius: 30,
        '&:hover': {
            backgroundColor: theme.palette.btns.gray
        }
    },
    boldText: {
        fontWeight: "600"
    },
}))
const ViewList = (props) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    let ParentState = props.parentState;
    let SelectedID = ParentState.ViewID;
    let PayTypeList = ParentState.PaytmentTypesList;
    let Services = ParentState.FilteredServiceList ? ParentState.FilteredServiceList : [];
    const FilledForms = useSelector((state) => state.GetFilledServiceFormsReducer);
    let FilledFormList = FilledForms.FSForm ? FilledForms.FSForm : [];
    useEffect(() => {
        if(SelectedID){
            dispatch(GetFilledServiceForms(SelectedID));
        }
    }, [SelectedID])

    let SelectedService = _.filter(Services, { id: SelectedID });
    console.log(JSON.stringify(SelectedService))
    let DataExist = SelectedService.length > 0 ? true : false;
    let BalanceAmount = DataExist ? (parseFloat(SelectedService[0].paymentCollection) - parseFloat(SelectedService[0].paymentCollected)) : 0;
    let BalancePaidMode = DataExist ? _.filter(PayTypeList, { id: SelectedService[0].balancePaymentModeId }) : [];
    BalancePaidMode = BalancePaidMode.length>0 ? BalancePaidMode[0].name : "";
    let BalancePaidCode = BalancePaidMode.length>0 ? BalancePaidMode[0].code : "";
    //let AdhocNames = DataExist ? SelectedService[0].adHocServiceItems.map((item, key) => {return item.product ? item.product+', ' : ''}) : "";
    let AdhocServices = DataExist ? (SelectedService[0].adHocServiceItems ? SelectedService[0].adHocServiceItems : []) : [];
    let AdhocNames = AdhocServices.map((item, key) => {return item.product ? item.product+', ' : ''});
    let ArrangedAdhocNames = AdhocNames;//(AdhocNames.toString()).replace(/,(\s+)?$/, '');
    let FormItems = [];
    if(FilledFormList.length>0){
        FormItems = FilledFormList.map(function(a) {return a.type;});
    }
    const ViewFormItem = (type) =>{
        let Formindex = FilledFormList.findIndex(x => x.type === type);
        props.parentClass.ViewSelectedForm(FilledFormList[Formindex], type)
    }
    return (
        <Dialog
            open={props.Status}
            fullWidth={true}
            fullScreen={false}
            maxWidth="sm"
            disableBackdropClick={false}
            disableEscapeKeyDown={false}
        >
            <DialogTitle className={classes.dialogTitle} id="dialog-title">{props.Title}</DialogTitle>
            <DialogContent dividers >
            <Grid container spacing={4} md={12}>
                    <Grid item md={12} spacing={4} >
                    <Grid container item md={12} direction="row" spacing={4} style={{ paddingTop: 10 }}>
                            <Grid item md={6} spacing={4} >
                                <Typography className={classes.boldText}>Date of Service</Typography>
                                <Typography >{DataExist ? DMY(SelectedService[0].serviceDate) : "NA"}</Typography>
                            </Grid>
                            <Grid item md={6} spacing={4} >
                                <Typography className={classes.boldText}>Job Type</Typography>
                                <Typography >{DataExist ? capsFirstLetter(SelectedService[0].jobType) : "NA"}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container item md={12} direction="row" spacing={4} style={{ paddingTop: 10 }}>
                            <Grid item md={6} spacing={4} >
                                <Typography className={classes.boldText}>Pitch before tuning</Typography>
                                <Typography >{DataExist ? SelectedService[0].pitchBeforeTuning : "NA"}</Typography>
                            </Grid>
                            <Grid item md={6} spacing={4} >
                                <Typography className={classes.boldText}>Pitch after tuning</Typography>
                                <Typography >{DataExist ? SelectedService[0].pitchAfterTuning : "NA"}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container item md={12} direction="row" spacing={4} style={{ paddingTop: 10 }} >
                            <Grid item md={6} spacing={4} >
                                <Typography className={classes.boldText}>Assessment</Typography>
                                {FormItems.includes("assesment") ? 
                                <Link component="button" variant="body2" 
                                        onClick={() => {ViewFormItem("assesment")}}
                                    >
                                    View
                                </Link>
                                : <Typography >NA</Typography>}
                                
                                
                            </Grid>
                            <Grid item md={6} spacing={4} >
                                <Typography className={classes.boldText}>Repair</Typography>
                                {FormItems.includes("repair") ? 
                                <Link component="button" variant="body2" 
                                    onClick={() => {ViewFormItem("repair")}}
                                    >
                                    View
                                </Link>
                                : <Typography >NA</Typography>}
                            </Grid>
                        </Grid>
                        <Grid container item md={12} direction="row" spacing={4} style={{ paddingTop: 10 }} >
                            <Grid item md={6} spacing={4} >
                                <Typography className={classes.boldText}>DP/Drum Inspection</Typography>
                                {FormItems.includes("inspection") ? 
                                <Link component="button" variant="body2" 
                                        onClick={() => {ViewFormItem("inspection")}}
                                    >
                                    View
                                </Link>
                                : <Typography >NA</Typography>}
                                
                                
                            </Grid>
                        </Grid>
                        <Grid container item md={12} direction="row" spacing={4} style={{ paddingTop: 10 }}>
                            <Grid item md={12} spacing={4} >
                                <Typography className={classes.boldText}>Adhoc Sales/Services</Typography>
                                {
                                    AdhocServices.map((item, key) => {
                                        return <Typography >{item.product}  {PriceFormat(item.cost)}</Typography>
                                    })
                                }
                                
                            </Grid>
                        </Grid>
                        <Grid container item md={12} direction="row" spacing={4} style={{ paddingTop: 10 }} >
                            <Grid item md={6} spacing={4} >
                                <Typography className={classes.boldText}>CS Remark</Typography>
                                <Typography >{DataExist ? SelectedService[0].serviceRemarks : "NA"}</Typography>
                            </Grid>
                            <Grid item md={6} spacing={4} >
                                <Typography className={classes.boldText}>Other</Typography>
                                <Typography >{DataExist ? SelectedService[0].others : "NA"}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container item md={12} direction="row" spacing={4} style={{ paddingTop: 10 }}>
                            <Grid item md={6} spacing={4} >
                                <Typography className={classes.boldText}>Technician name</Typography>
                                <Typography >{DataExist ? SelectedService[0].technicianName : "NA"}</Typography>
                            </Grid>
                            <Grid item md={6} spacing={4} >
                                <Typography className={classes.boldText}>Technician Remarks</Typography>
                                <Typography >{DataExist ? SelectedService[0].technicianRemark : "NA"}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container item md={12} direction="row" spacing={4} style={{ paddingTop: 10 }} >
                            <Grid item md={6} spacing={4} >
                                <Typography className={classes.boldText}>Total Payable</Typography>
                                <Typography >{DataExist ? PriceFormat(SelectedService[0].paymentCollection) : "NA"}</Typography>
                            </Grid>
                            <Grid item md={6} spacing={4} >
                                <Typography className={classes.boldText}>Balance Payment</Typography>
                                <Typography >{PriceFormat(BalanceAmount)}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container item md={12} direction="row" spacing={4} style={{ paddingTop: 10 }} >
                            <Grid item md={6} spacing={4} >
                            <Typography className={classes.boldText}>Payment Collected</Typography>
                                <Typography >{DataExist ? PriceFormat(SelectedService[0].paymentCollected) : "NA"}</Typography>
                            </Grid>
                            <Grid item md={6} spacing={4} >
                                <Typography className={classes.boldText}>Balance Paid Date</Typography>
                                <Typography >{DataExist ? dateTimeFormating(SelectedService[0].balancePaidDate) : "NA"}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container item md={12} direction="row" spacing={4} style={{ paddingTop: 10 }} >
                            <Grid item md={6} spacing={4} >
                                <Typography className={classes.boldText}>Payment Mode</Typography>
                                <Typography >{DataExist ? SelectedService[0].typeOfPaymentName : "NA"}</Typography>
                            </Grid>
                            <Grid item md={6} spacing={4} >
                                <Typography className={classes.boldText}>Balance Payment Mode</Typography>
                                <Typography >{DataExist ? BalancePaidMode : "NA"}</Typography>
                            </Grid>
                        </Grid>
                        
                        <Grid container item md={12} direction="row" spacing={4} style={{ paddingTop: 10 }} >
                            <Grid item md={6} spacing={4} >
                            <Typography className={classes.boldText}>Payment Status</Typography>
                                <Typography >{DataExist ? SelectedService[0].paymentStatusName : "NA"}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid md={12} style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                        onClick={() => props.parentClass.CloseList()}
                        variant="contained"
                        color="secondary"
                        className={classes.buttonCancel}
                    >
                        Close
                        </Button>

                </Grid>
            </DialogActions>
        </Dialog >
    );
}

export default ViewList;