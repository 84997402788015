import React, { useState, useEffect, Component } from "react";
import {
  Grid, TextField, Button, Switch, Tooltip,
  FormControlLabel, IconButton, DeleteIcon,
  EditIcon, MUIDataTable, Autocomplete, connect,
  _, swal, toast, Typography, Checkbox, withStyles, axios,
  Box
} from "../../utilities/PackagesList";
import {
  PageTitle, CustomToolbar, AlertDialog,
  CustomModel, InputField, Dropdown,
  Buttons, LoadingOverlay, ButtonIcon
} from "../../utilities/ComponentsList";
import { LastDayMonth, DateMinusYMD, todayDate, dateTimetoDate, nullToStr, GetFullMobileNo, convertDateDMY, isFalse, isBoolean, DMYD, AMPM } from "../../utilities/AppFunctions";
import { getAdhoc, DeleteAdhoc, getOrder, getSeller, SyncOrder, UpdateOrderStatus } from '../../api/Adhoc';
import { getTeams } from '../../api/Team';
import { SendSMS } from '../../api/Notification';
import { refreshReduxStates } from '../../api/Refresh';
import { LoaderStatus } from '../../api/Loading';
import CreateAdhoc from "./CreateAdhoc";
import EditAdhoc from "./EditAdhoc";
import AssignTeam from "./AssignTeam";
import ViewList from "./ViewList";
import styles from "../../utilities/StylesClass";
import { returnApiCallPromiseLazarda } from './../../api/ApiContent';
let getAdhocState = false;
let getOrders = true;
class Delivery extends Component {

  constructor(props) {
    super(props);
    this.state = {
      DialogState: false,
      CancelState: false,
      CancelID: null,
      DeleteID: null,
      EditID: null,
      EditIndex: null,
      SelectedIndex: null,
      ModelType: null,
      ModelState: false,
      EditModelState: false,
      TeamsLists: [],
      AdhocLists: [],
      OrderLists: [],
      FilteredOrderList: [],
      isLoading: true,
      getCalls: [0, 1, 1],
      postCalls: [0, 0, 0, 0],
      SelectedOrders: [],
      SelectedOrderIndex: [],
      SellersLists: [],
      AssignTeamState: false,
      PostalCode: "",
      FilterContactNo: "",
      DateStartSelected: DateMinusYMD(15),
      DateEndSelected: todayDate(),
      SlectedMarket: "",
      SlectedMarketID: "",
      Filter2ListTypes: [{ id: 1, name: "Pick List" }],
      Filter2ListID: 1,
      Filter2ListSelected: { id: 1, name: "Pick List" },
      Filter2Date: "",
      TeamSelectedID: "",
      TeamSelected: "",
      ListModel: false,
      SyncMarketID: "",
      SyncMarket: "",
      SyncToDate: "",
      SyncFromDate: "",
      syncErrors: [false, false, false],
      remarkText: "",
      remarksText: []
    }

    props.getTeams(2);
    props.getSeller();

  }
  UNSAFE_componentWillReceiveProps = (props) => {
    if (this.state.getCalls[0]) {
      if (props.OrdersListSuccess) {
        let succed = this.state.getCalls;
        succed[0] = 0;
        let loaderStatus = getAdhocState ? false : succed.includes(1);
        let postalSelected = this.state.PostalCode;
        let ContactNo = this.state.FilterContactNo;
        let orders = props.OrdersLists;//_.filter(props.OrdersLists, { 'orderType': "normalDelivery" });
        /*  let FilteredOrders = _.filter(orders, function (item) {
           return (dateTimetoDate(item.createdAt) >= DateMinusYMD(15) && dateTimetoDate(item.createdAt) <= todayDate());
         }); */
        orders = _.orderBy(orders, ['createdAt'], ['asc']);
        let FilteredOrders = _.filter(orders, function (item) {
          return (postalSelected ? (postalSelected == item.postalCode) : true)
            && (ContactNo ? (ContactNo == item.contactNumber) : true);
        });
        let remarkItem = [];
        FilteredOrders.map((item, key) => { remarkItem.push(nullToStr(item.remark)) });
        getAdhocState = false;
        this.setState({
          OrderLists: orders,
          FilteredOrderList: FilteredOrders,
          isLoading: loaderStatus,
          getCalls: succed,
          remarksText: remarkItem
        })
      } else if (props.OrdersListFailure) {
        let succed = this.state.getCalls;
        succed[0] = 0;
        let loaderStatus = getAdhocState ? false : succed.includes(1);
        getAdhocState = false;
        this.setState({
          OrderLists: [],
          getCalls: succed,
          isLoading: loaderStatus
        })
      }
    }
    if (this.state.getCalls[1]) {
      if (props.TeamListSuccess) {
        let succed = this.state.getCalls;
        succed[1] = 0;
        let loaderStatus = succed.includes(1);
        this.setState({
          TeamsLists: props.TeamLists,
          isLoading: loaderStatus,
          getCalls: succed
        })
      } else if (props.TeamListFailure) {
        let succed = this.state.getCalls;
        succed[1] = 0;
        let loaderStatus = succed.includes(1);
        this.setState({
          TeamsLists: [],
          getCalls: succed,
          isLoading: loaderStatus
        })
      }
    }
    if (this.state.getCalls[2]) {
      if (props.SellerListSuccess) {
        let succed = this.state.getCalls;
        succed[2] = 0;
        succed[0] = 1;
        let loaderStatus = succed.includes(1);
        let Sellers = props.SellerLists;
        this.setState({
          SellersLists: Sellers,
          isLoading: loaderStatus,
          getCalls: succed,
          SlectedMarket: Sellers.length > 0 ? Sellers[0] : "",
          SlectedMarketID: Sellers.length > 0 ? Sellers[0].id : "",
        }, () => {
          let filterObj = {
            StartDate: DateMinusYMD(15),
            EndDate: todayDate(),
            OrderType: 2,
            DeliveryStatus: 1,
            SellerType: Sellers.length > 0 ? (Sellers[0].name).toLowerCase() : ""
          }

          props.getOrder(filterObj);
        })
      } else if (props.SellerListFailure) {
        let succed = this.state.getCalls;
        succed[2] = 0;
        let loaderStatus = succed.includes(1);
        this.setState({
          SellersLists: [],
          getCalls: succed,
          isLoading: loaderStatus
        })
      }
    }
    if (this.state.postCalls[1]) {
      if (props.SyncSellerSuccess) {

        if (!isBoolean(props.SyncSellerResponse.data)) {
          this.setState({
            postCalls: [0, 0, 0, 0],
            isLoading: false
          }, () => {
            toast.warning(props.SyncSellerResponse.message, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: false,
            });
          })
        } else {
          let SDate = this.state.SyncFromDate;
          let EDate = this.state.SyncToDate;
          let SelMID = this.state.SyncMarketID;
          let SelM = this.state.SyncMarket;

          this.setState({
            postCalls: [0, 0, 0, 0],
            getCalls: [1, 0, 0],
            isLoading: true,
            SyncMarketID: "",
            SyncMarket: "",
            SyncToDate: "",
            SyncFromDate: "",
            DateStartSelected: SDate,
            DateEndSelected: EDate,
            SlectedMarket: SelM,
            SlectedMarketID: SelMID,
          }, () => {
            let filterObj = {
              StartDate: SDate,
              EndDate: EDate,
              OrderType: 2,
              DeliveryStatus: 1,
              SellerType: SelMID ? (SelM.name).toLowerCase() : ""
            }
            //props.refreshReduxStates();
            props.getOrder(filterObj);
            toast.success('Orders Synchronized Successfully!', {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: false,
            });
          })
        }

      } else if (props.SyncSellerFailure) {

        this.setState({
          postCalls: [0, 0, 0, 0],
          isLoading: false
        }, () => {
          props.refreshReduxStates();
          toast.error(props.SyncSellerResponse.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: false,
          });
        })
      }
    }
    if (this.state.postCalls[2]) {
      if (props.DeleteAdhocSuccess) {
        let updatedList = this.state.FilteredOrderList;
        updatedList.splice(this.state.SelectedIndex, 1);
        this.setState({
          postCalls: [0, 0, 0, 0],
          isLoading: false,
          DialogState: false,
          DeleteID: null,
          SelectedIndex: null,
          FilteredOrderList: updatedList
        }, () => {
          this.props.refreshReduxStates();
          toast.success('Deleted Successfully!', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: false,
          });
        })
      } else if (props.DeleteAdhocFailure) {
        this.setState({
          postCalls: [0, 0, 0, 0],
          isLoading: false,
          DialogState: false,
          DeleteID: null,
          SelectedIndex: null,
        }, () => {
          toast.error(props.DeleteAdhocResponse.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: false,
          });
        })
      }
    }
    if (this.state.postCalls[3]) {
      if (props.OrderUpdateSuccess) {
        let editingID = this.state.EditID;
        let CancelingID = this.state.CancelID;
        let updatedFilterList = [];
        let updatedordersList = [];
        if(CancelingID){
          let FilterList = this.state.FilteredOrderList;
          let ordersList = this.state.OrderLists;
          updatedFilterList = _.filter(FilterList, function (item) {
            return CancelingID == item.id ? false : true;
          });
          updatedordersList = _.filter(ordersList, function (item) {
            return CancelingID == item.id ? false : true;
          });
        }else{
          
          updatedFilterList = this.state.FilteredOrderList;
          updatedordersList = this.state.OrderLists;
          var Filterindex = updatedFilterList.findIndex(function (o) {
            return o.id === editingID;
          })
          var Orderindex = updatedordersList.findIndex(function (o) {
            return o.id === editingID;
          })
          let updatingRemarks = this.state.remarksText[Filterindex].trim();
          updatedFilterList[Filterindex].remark = updatingRemarks;
          updatedordersList[Orderindex].remark = updatingRemarks;
        }
        
        this.setState({
          postCalls: [0, 0, 0, 0],
          isLoading: false,
          EditID: null,
          EditIndex: null,
          remarkText: "",
          OrderLists: updatedordersList,
          FilteredOrderList: updatedFilterList,
        }, () => {
          this.props.refreshReduxStates();
          toast.success('Updated Successfully!', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: false,
          });
        })
      } else if (props.OrderUpdateFailure) {
        this.setState({
          postCalls: [0, 0, 0, 0],
          isLoading: false,
          EditID: null,
          EditIndex: null,
        }, () => {
          toast.error(props.OrderUpdateResponse.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: false,
          });
        })
      }
    }
  }



  //DELETE ADHOC
  deletePress = (type, index) => {
    let Data = this.state.FilteredOrderList;
    let Selected = Data[index];
    this.setState({
      DialogState: !this.state.DialogState,
      DeleteID: Selected.id,
      SelectedIndex: index,
      postCalls: [0, 0, 1, 0]
    })
  }
  DeleteClose = () => {
    this.setState({
      DialogState: !this.state.DialogState,
      DeleteID: null,
      SelectedIndex: null,
    })
  }

  DeleteConfirm = () => {
    this.setState({
      isLoading: true
    }, () => {
      this.props.DeleteAdhoc(this.state.DeleteID);
    })
  }
  //DELETE ADHOC END
  //ADDING ADHOC
  CloseAdd = () => {
    this.setState({
      ModelState: !this.state.ModelState,
      ModelType: null,
      EditID: null,
      SelectedIndex: null,
    })
  }
  AddingAdhoc = () => {
    this.setState({
      ModelState: true,
      ModelType: "Add",
    })
  }
  AddedAdhoc = () => {
    this.setState({
      ModelState: !this.state.ModelState,
      ModelType: null,
      EditID: null,
      SelectedIndex: null,
      isLoading: true,
      getCalls: [1, 0],
    }, () => {
      getAdhocState = true;
      this.props.getOrder();
    })
  }
  //ADDING ADHOC END
  //EDITING ADHOC
  editPress = (type, index) => {
    let Data = this.state.FilteredOrderList;
    let Selected = Data[index];
    this.setState({
      EditModelState: true,
      EditID: Selected.id,
      ModelType: "Edit",
      SelectedIndex: index
    })
  }
  CloseEdit = () => {
    this.setState({
      EditModelState: false,
      ModelType: null,
      EditID: null,
      SelectedIndex: null,
    })
  }
  EditedAdhoc = (EditedObject) => {
    let ListData = this.state.FilteredOrderList;
    ListData[this.state.SelectedIndex] = EditedObject;
    this.setState({
      EditModelState: false,
      ModelType: null,
      EditID: null,
      SelectedIndex: null,
      FilteredOrderList: ListData,
      //getCalls:[1],
    }, () => {
      this.props.refreshReduxStates();
      /* getAdhocState = true;
     this.props.getAdhoc();  */
    })
  }
  //EDIT ADHOC END
  //ASSIGN TEAM
  AssignedTeam = (updatedDetails) => {
    let ListData = this.state.FilteredOrderList;
    let FullOrderList = this.state.OrderLists;
    let selectedOrd = this.state.SelectedOrders;
    let FselectedOrd = this.state.SelectedOrders;
    let remarkItem = [];
    let smsObj = [];
    let newList = ListData.filter(function (value, index) {
      let orderExist = selectedOrd.includes(value.id);
      if (!orderExist) {
        remarkItem.push(value.remark);
      }else if(orderExist){
        smsObj.push(
          {
            "type": "sms",
            "body": "Thank you for ordering from cristofori. Your order will be delivered on "+DMYD(updatedDetails.date)+" between 9.30am to 5.30pm. Please do not reply. System generated message.",
            "toMobileNo": GetFullMobileNo(value.contactNumber)
          }
        )
      }
      return !orderExist;
    })
    let FullList = FullOrderList.filter(function (value, index) {
      let orderExist = FselectedOrd.includes(value.id);
      /* if (orderExist) {
        var Arrayindex = FselectedOrd.indexOf(value.id);
        FselectedOrd.splice(Arrayindex, 1);
      } */
      return !orderExist;
    })
    this.setState({
      AssignTeamState: false,
      SelectedOrders: [],
      OrderLists: FullList,
      SelectedOrderIndex: [],
      FilteredOrderList: newList,
      remarksText: remarkItem
    }, () => {
      console.log(JSON.stringify(smsObj))
      this.props.SendSMS(smsObj);
    })
  }
  CloseAssign = () => {
    this.setState({
      AssignTeamState: false,
      SelectedOrders: [],
      SelectedOrderIndex: []
    })
  }
  //ASSIGN TEAM END
  checkedItemsChange = (e, value, tableMeta, updateValue) => {
    let selectedItems = this.state.SelectedOrders;
    let selectedIndex = this.state.SelectedOrderIndex;
    let Arrayindex = selectedItems.indexOf(value);
    if (selectedItems.includes(value)) {
      if (Arrayindex > -1) {
        selectedItems.splice(Arrayindex, 1);
        //selectedIndex.splice(Arrayindex, 1);
      }
    } else {
      selectedItems.push(value);
      // selectedIndex.push(Arrayindex);
    }
    this.setState({
      SelectedOrders: selectedItems,
      //SelectedOrderIndex : selectedIndex
    });
  }
  DeleviryStatusClick = () => {
    this.props.history.push('/deliverystatus')
  }
  ScheduleDeleviryClick = () => {
    if (this.state.SelectedOrders.length > 0) {
      this.setState({
        AssignTeamState: true
      })
    } else {
      toast.warning('Please choose order!', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: false,
      });
    }
  }
  SyncClick = () => {
    if (!this.state.SyncFromDate || !this.state.SyncToDate || !this.state.SyncMarketID) {
      /* toast.warning('Please choose order date and marketplace!', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: false,
      }); */
      this.setState({
        syncErrors: [isFalse(this.state.SyncFromDate), isFalse(this.state.SyncToDate), isFalse(this.state.SyncMarketID)],
      });
      return;
    }
    this.setState({
      isLoading: true,
      postCalls: [0, 1, 0, 0],
    }, () => {

      let SyncObject = {
        "sellerId": this.state.SyncMarketID,
        "startDate": convertDateDMY(this.state.SyncFromDate),
        "endDate": convertDateDMY(this.state.SyncToDate)
      };
      this.props.SyncOrder(SyncObject);
    })


  }
  searchClick = (type) => {
    if (type == 1) {
      if (!this.state.DateStartSelected && !this.state.DateEndSelected && !this.state.PostalCode && !this.state.FilterContactNo && !this.state.SlectedMarketID) {
        toast.warning("Please choose any one filter option!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: false,
        });
        return;
      } else if (!this.state.DateStartSelected || !this.state.DateEndSelected) {
        toast.warning("Please choose start and end date", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: false,
        });
        return;
      }
      let dateStartPicked = this.state.DateStartSelected ? this.state.DateStartSelected : null;
      let dateEndPicked = this.state.DateEndSelected ? this.state.DateEndSelected : null;
      let postalSelected = this.state.PostalCode;
      let ContactNo = this.state.FilterContactNo;
      let selectedPlace = this.state.SlectedMarketID;
      let SlectedPlaceObj = this.state.SlectedMarket;

      /* let ItemsSelected = _.filter(this.state.OrderLists, function (item) {
        return (dateStartPicked && dateEndPicked ? (dateTimetoDate(item.createdAt) >= dateStartPicked && dateTimetoDate(item.createdAt) <= dateEndPicked) : true)
          && (postalSelected ? (postalSelected == item.postalCode) : true)
          && (selectedPlace ? (SlectedPlaceObj.name.toLowerCase() == item.sellerType) : true)
          && (ContactNo ? (ContactNo == item.contactNumber) : true);
      }); */
      let filterObj = {
        StartDate: dateStartPicked,
        EndDate: dateEndPicked,
        OrderType: 2,
        DeliveryStatus: 1,
        SellerType: selectedPlace ? (SlectedPlaceObj.name).toLowerCase() : ""
      }
      this.setState({
        isLoading: true,
        getCalls: [1, 0, 0],
      }, () => {
        this.props.getOrder(filterObj);
      })
    } else if (type == 2) {
      if (!this.state.TeamSelectedID) {
        toast.warning('Please choose any team!', {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: false,
        });
        return;
      } else {
        this.setState({
          ListModel: true
        })
      }

    }

  }
  clearSearchClick = (type) => {
    if (type == 1) {
      /*  let FilteredOrders = _.filter(this.state.OrderLists, function (item) {
         return (dateTimetoDate(item.createdAt) >= DateMinusYMD(15) && dateTimetoDate(item.createdAt) <= todayDate());
       }); */

      let filterObj = {
        StartDate: DateMinusYMD(15),
        EndDate: todayDate(),
        OrderType: 2,
        DeliveryStatus: 1,
        SellerType: this.state.SellersLists.length > 0 ? (this.state.SellersLists[0].name).toLowerCase() : ""
      }
      this.setState({
        //FilteredOrderList: FilteredOrders,
        DateStartSelected: DateMinusYMD(15),
        DateEndSelected: todayDate(),
        PostalCode: "",
        FilterContactNo: "",
        SlectedMarketID: this.state.SellersLists.length > 0 ? this.state.SellersLists[0].id : "",
        SlectedMarket: this.state.SellersLists.length > 0 ? this.state.SellersLists[0] : "",
        isLoading: true,
        getCalls: [1, 0, 0],
      }, () => {
        this.props.getOrder(filterObj);
      })
    } else if (type == 2) {
      this.setState({
        Filter2ListID: "",
        Filter2ListSelected: "",
        Filter2Date: "",
        TeamSelectedID: "",
        TeamSelected: ""
      })
    }

  }
  UpdateRemarks = (EditingID, EditingIndex) => {
    
    let updatedFilterList = this.state.FilteredOrderList;
    let Filterindex = updatedFilterList.findIndex(function (o) {
      return o.id === EditingID;
    })
    let newRemarks = this.state.remarksText[Filterindex].trim();
    newRemarks = newRemarks ? newRemarks : null;
    let formData = new FormData();
    formData.append('deliveryStatusId', updatedFilterList[Filterindex].deliveryStatusId);
    formData.append('orderId', EditingID);
    formData.append('remarks', newRemarks);
    //if(newRemarks.length>0){
    this.setState({
      EditID: EditingID,
      EditIndex: EditingIndex,
      postCalls: [0, 0, 0, 1],
      isLoading: true
    }, () => {
      this.props.UpdateOrderStatus(formData);
    })
    // }

  }
  CloseList = () => {
    this.setState({
      ListModel: false
    })
  }
  CanceledConfirm = () => {
    let formData = new FormData();
    formData.append('deliveryStatusId', 5);
    formData.append('orderId', this.state.CancelID);
    this.setState({
      postCalls: [0, 0, 0, 1],
      isLoading: true
    }, () => {
      this.props.UpdateOrderStatus(formData);
    })
  }
  CanceledClose = () => {
    this.setState({
      CancelState: false,
      CancelID: null,
    })
  }
  CancelOrder = (id, index) => {
    this.setState({
      CancelState: true,
      CancelID: id,
    })
  }
  render() {
    const { classes } = this.props;
    const columns = [
      {
        name: "id",
        label: "Select",
        options: {
          sort: false,
          filter: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Grid item lg={12}>
                <Checkbox
                  checked={this.state.SelectedOrders ? (this.state.SelectedOrders).includes(value) : false}
                  onChange={(e) => this.checkedItemsChange(e, value, tableMeta, updateValue)}
                  name="checkedB"
                  color="primary"
                  size="small"
                />
              </Grid>
            )
          }
        }
      },
      {
        name: "orderNumber",
        label: "Order Number",
        options: {
          filter: false,
          sort: true
        }
      },
      {
        name: "customerInvoiceNumber",
        label: "Custom Invoice",
        options: {
          filter: false,
          sort: true,
        }
      },
      {
        name: "createdAt",
        label: "Order Date",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Grid item lg={12}>
                <Typography>{convertDateDMY(value)}</Typography>
              </Grid>
            )
          }
        }
      },
      {
        name: "customerName",
        label: "Customer Name",
        options: {
          filter: false,
          sort: true,
        }
      },
      {
        name: "contactNumber",
        label: "Contact Number",
        options: {
          filter: false,
          sort: true,
        }
      },
      {
        name: "address",
        label: "Address",
        options: {
          filter: false,
          sort: false,
        }
      },
      {
        name: "postalCode",
        label: "Postal Code",
        options: {
          filter: false,
          sort: true,
        }
      },
      {
        name: "items",
        label: "Items to Deliver",
        options: {
          filter: false,
          sort: false,
          // setCellProps: () => ({ style: { minWidth: "250px" } }),
          /* customBodyRender: (value, tableMeta, updateValue) => {
            let checkString = value.includes(",");
            let items = checkString ? value.split(",") : [value];
            return (
              <Grid item lg={12}>
                {items.map((itemVal, index) => {
                  return (
                    <Typography>{itemVal}</Typography>
                  )
                })}

              </Grid>
            )
          } */
        }
      },
      {
        name: "remark",
        label: "Remarks",
        options: {
          filter: false,
          sort: false,
          setCellProps: () => ({ style: { minWidth: "150px" } }),
          customBodyRender: (value, tableMeta, updateValue) => {
            let orderID = tableMeta.rowData[0];
            let Filterindex = this.state.FilteredOrderList.findIndex(function (o) {
              return o.id === orderID;
            })
            let remarks = this.state.remarksText;
            let orderRemark = remarks[Filterindex] ? nullToStr(remarks[Filterindex]) : "";
            return (
              <Grid item lg={12}>
                <InputField
                  label={""}
                  type={"text"}
                  multiline={true}
                  required={false}
                  rows={3}
                  maxLength={600}
                  value={orderRemark}
                  // defaultText={value.toString()}
                  changeText={(event) => {
                    remarks[Filterindex] = event.target.value;
                    this.setState({
                      remarksText: remarks
                    })
                  }}
                  //error={Createerrors.Items}
                  initialName={"Remarks"}
                  errorMsg={"Please enter remarks."}
                />
                <Button
                  onClick={() => this.UpdateRemarks(orderID, tableMeta.rowIndex)}
                  className={classes.smallBtn}
                  variant="contained" color="primary">
                  Save
                </Button>
              </Grid>
            )
          }
        }
      },
      {
        name: "sellerType",
        label: "Marketplace",
        options: {
          filter: false,
          sort: true,
          /* customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Grid item lg={12}>
                <Typography>Singapore</Typography>
              </Grid>
            )
          } */
        }
      },
      {
        name: "",
        label: "Actions",
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <ButtonIcon
                CancelTitle={"Cancel Order"}
                Cancel={() => this.CancelOrder(tableMeta.rowData[0], tableMeta.rowIndex)}
              />
            )
          }
        }
      }
    ];

    return (
      <>
        {/* <PageTitle title="Ad Hoc Delivery" /> */}
        <Grid container spacing={4}>
          {/*  <Grid item lg={12} style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              onClick={() => this.DeleviryStatusClick()}
              className={classes.buttonSuccess}
              variant="contained" color="primary">
              Delivery Status
          </Button>
          </Grid> */}

          <Grid container item spacing={1} lg={12} direction={"row"} >
            <Grid item lg={2} md={2} >
              <InputField
                label={"Start Date"}
                type={"date"}
                required={false}
                value={this.state.SyncFromDate}
                changeText={(data) => {
                  let chosenDate = data ? data.target.value : null;
                  this.setState({
                    SyncFromDate: chosenDate,
                    SyncToDate: LastDayMonth(chosenDate),
                    syncErrors: [isFalse(chosenDate), false, false]
                  })
                }}
                error={this.state.syncErrors[0]}
                initialName={"Date"}
                errorMsg={"Please enter start date."}
              />
            </Grid>
            <Grid item lg={2} md={2} >
              <InputField
                label={"End Date"}
                type={"date"}
                required={false}
                value={this.state.SyncToDate}
                changeText={(data) => {
                  let chosenDate = data ? data.target.value : null;
                  this.setState({
                    SyncToDate: chosenDate,
                    syncErrors: [false, isFalse(chosenDate), false]
                  })
                }}
                error={this.state.syncErrors[1]}
                initialName={"Date"}
                errorMsg={"Please enter end date."}
              />
            </Grid>
            <Grid item md={2}>
              <Dropdown
                label={"Marketplace"}
                data={this.state.SellersLists}
                getOptionLabel={option => (option.name ? option.name : "")}
                getOptionSelected={(option, value) => option.id === value.id}
                value={this.state.SyncMarket}
                ItemChange={(event, newValue) => {
                  this.setState({
                    SyncMarketID: newValue ? newValue.id : null,
                    SyncMarket: newValue ? newValue : null,
                    syncErrors: [false, false, isFalse(newValue.id)]
                  })
                }}
                error={this.state.syncErrors[2]}
                initialName={"Marketplace"}
                errorMsg={"Please choose marketplace."}
              />
            </Grid>
            <Grid item lg={1} lg={1} md={1} >
              <Button
                onClick={() => this.SyncClick()}
                className={[classes.buttonSuccess, { flex: 1 }]}
                variant="contained" color="primary">
                Sync
              </Button>
            </Grid>
            {/* <Grid item lg={5} md={5} sm={5} style={{ display: "flex", justifyContent: "flex-end" }}>

              <Button
                onClick={() => this.ScheduleDeleviryClick()}
                className={classes.buttonSuccess}
                variant="contained" color="primary">
                Schedule Delivery
                </Button>

            </Grid> */}
          </Grid>
          <Grid container item spacing={4} lg={12} style={{ marginLeft: 1 }}>
            <Typography >
              <Box fontWeight='600'  >Filters:</Box>
            </Typography>
          </Grid>
          <Grid container item spacing={1} lg={12} direction={"row"} style={{ marginTop: 0 }}>
            <Grid item md={2}>
              <InputField
                label={"Start Date"}
                type={"date"}
                required={false}
                value={this.state.DateStartSelected}
                changeText={(data) => {
                  this.setState({
                    DateStartSelected: data ? data.target.value : null,
                    DateEndSelected: data ? LastDayMonth(data.target.value) : null
                  })
                }}
                // error={Createerrors.Date}
                initialName={"Start Date"}
                errorMsg={"Please enter date."}
              />
            </Grid>
            <Grid item md={2}>
              <InputField
                label={"End Date"}
                type={"date"}
                required={false}
                value={this.state.DateEndSelected}
                changeText={(data) => { this.setState({ DateEndSelected: data ? data.target.value : null }) }}
                // error={Createerrors.Date}
                initialName={"End Date"}
                errorMsg={"Please enter date."}
              />
            </Grid>
            <Grid item md={2}>
              <InputField
                label={"Postal Code"}
                type={"number"}
                required={false}
                value={this.state.PostalCode}
                changeText={(data) => this.setState({ PostalCode: data ? data.target.value : null })}
                // error={Createerrors.Date}
                initialName={"Postal Code"}
                errorMsg={"Please enter postal code."}
              />
            </Grid>
            <Grid item md={2}>
              <InputField
                label={"Contact Number"}
                type={"number"}
                required={false}
                value={this.state.FilterContactNo}
                changeText={(data) => this.setState({ FilterContactNo: data ? data.target.value : null })}
                // error={Createerrors.Date}
                initialName={"Contact Number"}
                errorMsg={"Please enter contact no."}
              />
            </Grid>
            <Grid item md={2}>
              <Dropdown
                label={"Marketplace"}
                data={this.state.SellersLists}
                getOptionLabel={option => (option.name ? option.name : "")}
                getOptionSelected={(option, value) => option.id === value.id}
                value={this.state.SlectedMarket}
                ItemChange={(event, newValue) => {
                  this.setState({
                    SlectedMarketID: newValue ? newValue.id : null,
                    SlectedMarket: newValue ? newValue : null
                  })
                }}
                //error={Createerrors.TeamSelected}
                initialName={"Marketplace"}
                errorMsg={"Please choose marketplace."}
              />
            </Grid>
            <Grid item lg={1} md={1} sm={1}>
              <Button
                onClick={() => this.clearSearchClick(1)}
                className={[classes.buttonCancel, { flex: 1 }]}
                variant="contained" color="primary">
                Clear
                  </Button>
            </Grid>
            <Grid item lg={1} md={1} sm={1}>
              <Button
                onClick={() => this.searchClick(1)}
                className={[classes.buttonSuccess, { flex: 1 }]}
                variant="contained" color="primary">
                Search
                  </Button>
            </Grid>

          </Grid>
          <Grid item lg={12} style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              onClick={() => this.ScheduleDeleviryClick()}
              className={classes.buttonSuccess}
              variant="contained" color="primary">
              Schedule Delivery
            </Button>
          </Grid>
          {/* <Grid container item spacing={1} lg={12} direction={"row"}>
            <Grid item lg={6} >
              <Button
                onClick={() => this.ScheduleDeleviryClick()}
                className={classes.buttonSuccess}
                variant="contained" color="primary">
                Schedule Delivery
                </Button>
            </Grid>
            <Grid item lg={6} style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                onClick={() => this.DeleviryStatusClick()}
                className={classes.buttonSuccess}
                variant="contained" color="primary">
                Delivery Status
            </Button>
            </Grid>
          </Grid> */}
          <Grid item xs={12}>
            <MUIDataTable
              title="Marketplace Delivery List"
              data={this.state.FilteredOrderList}
              columns={columns}
              options={{
                sort: true,
                selectableRows: false,
                print: false,
                download: false,
                viewColumns: false,
                filter: false,
                responsive: true,
                customToolbar: () => {
                  return (
                    <Typography >
                      <Box fontWeight='600'>Orders : {this.state.FilteredOrderList.length}</Box>
                    </Typography>
                  );
                }
              }}
            />
          </Grid>


          {/* <Grid item lg={12} style={{ display: "flex", justifyContent: "flex-start" }}>
            <Button
              onClick={() => this.ScheduleDeleviryClick()}
              className={classes.buttonSuccess}
              variant="contained" color="primary">
              Schedule Delivery
            </Button>
          </Grid> */}
          <AlertDialog
            Status={this.state.DialogState}
            DialogConfirm={() => { this.DeleteConfirm() }}
            DialogCancel={() => { this.DeleteClose() }}
            Title={"Delete Ad Hoc"}
            Content={"Are you sure, you want to delete the Ad hoc ?"}
          />
          <AlertDialog
            Status={this.state.CancelState}
            DialogConfirm={() => { this.CanceledConfirm() }}
            DialogCancel={() => { this.CanceledClose() }}
            Title={"Cancel Order"}
            Content={"Are you sure, you want to cancel order ?"}
          />
          {this.state.ModelState ? <CreateAdhoc
            Title={"Add Ad Hoc"}
            parentClass={this}
            parentState={this.state}
            Status={this.state.ModelState}
            ModelConfirm={this.AddedAdhoc}
            ModelCancel={this.CloseAdd}
          /> : null}
          {this.state.EditModelState ? <EditAdhoc
            Title={"Edit Ad Hoc"}
            parentClass={this}
            parentState={this.state}
            Status={this.state.EditModelState}
            ModelConfirm={this.EditedAdhoc}
            ModelCancel={this.CloseEdit}
          /> : null}
          {this.state.AssignTeamState ? <AssignTeam
            Title={"Assign Team"}
            parentClass={this}
            parentState={this.state}
            Status={this.state.AssignTeamState}
            ModelConfirm={this.AssignedTeam}
            ModelCancel={this.CloseAssign}
          /> : null}
          {this.state.ListModel ? <ViewList
            Title={"Lists"}
            parentClass={this}
            parentState={this.state}
            Status={this.state.ListModel}
            ModelCancel={this.CloseList}
          /> : null}
          <LoadingOverlay Status={this.state.isLoading} />
        </Grid>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    TeamListSuccess: state.getTeamsReducer.Success,
    TeamListFailure: state.getTeamsReducer.Failure,
    TeamLists: state.getTeamsReducer.TeamList,

    SellerListSuccess: state.getSellerReducer.Success,
    SellerListFailure: state.getSellerReducer.Failure,
    SellerLists: state.getSellerReducer.SellerList,

    OrdersListSuccess: state.getOrderReducer.Success,
    OrdersListFailure: state.getOrderReducer.Failure,
    OrdersLists: state.getOrderReducer.OrdersList,

    AdhocListSuccess: state.getAdhocReducer.Success,
    AdhocListFailure: state.getAdhocReducer.Failure,
    AdhocList: state.getAdhocReducer.AdhocList,

    DeleteAdhocSuccess: state.DeleteAdhocReducer.Success,
    DeleteAdhocFailure: state.DeleteAdhocReducer.Failure,
    DeleteAdhocResponse: state.DeleteAdhocReducer.DeleteResponse,

    DeleteAdhocSuccess: state.DeleteAdhocReducer.Success,
    DeleteAdhocFailure: state.DeleteAdhocReducer.Failure,
    DeleteAdhocResponse: state.DeleteAdhocReducer.DeleteResponse,

    OrderUpdateSuccess: state.UpdateOrderStatusReducer.Success,
    OrderUpdateFailure: state.UpdateOrderStatusReducer.Failure,
    OrderUpdateResponse: state.UpdateOrderStatusReducer.UpdateResponse,

    SyncSellerSuccess: state.SyncOrderReducer.Success,
    SyncSellerFailure: state.SyncOrderReducer.Failure,
    SyncSellerResponse: state.SyncOrderReducer.SyncResponse,

    UserDetails: state.LoginReducer.UserDetails,
    LoaderState: state.LoaderReducer.State
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTeams: (ID) => dispatch(getTeams(ID)),
    getSeller: () => dispatch(getSeller()),
    getOrder: (filterObj) => dispatch(getOrder(filterObj)),
    SendSMS: (SMSBody) => dispatch(SendSMS(SMSBody)),
    getAdhoc: () => dispatch(getAdhoc()),
    DeleteAdhoc: (ID) => dispatch(DeleteAdhoc(ID)),
    refreshReduxStates: () => dispatch(refreshReduxStates()),
    SyncOrder: (ReqObj) => dispatch(SyncOrder(ReqObj)),
    UpdateOrderStatus: (UpdateObj) => dispatch(UpdateOrderStatus(UpdateObj)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Delivery));
