import React, { useState, useEffect, Component } from 'react';
import {
    Grid, Dialog, GridList, DialogContent,
    GridListTile, DialogTitle, makeStyles,
    Typography, Divider, IconButton, DeleteIcon,
    EditIcon, _, swal, toast, useSelector, useDispatch,
    Card, CardMedia, CardContent, CardActions, Box, Button,
    List, ListItem, ListItemIcon, ListItemText, Paper,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow
} from '../../utilities/PackagesList';
import {
    PageTitle, CustomToolbar, AlertDialog,
    CustomModel, InputField, Dropdown,
    Buttons, LoadingOverlay
} from "../../utilities/ComponentsList";
import { getTeamOrder, AdhocScheduleDelivery } from '../../api/Adhoc';
import { isEmptyObj, DMY, AMPM, todayDate } from "../../utilities/AppFunctions";
const useStyles = makeStyles(theme => ({
    dialogTitle: {

    },
    dialogWindow: {
        width: '80%'
    },
    root: {
        width: '100%',
        overflow: 'auto',
        maxHeight: 300,
    },
    cardStyle: {
        height: 90,
        padding: 10,
        margin: 10,
        boxShadow: "none",
        borderRadius: 10,
        backgroundColor: '#000'

    },
    tableRightBorder: {
        borderWidth: 0,
        borderRightWidth: 1,
        borderColor: 'black',
        borderStyle: 'solid',
    },
    buttonCancel: {
        backgroundColor: theme.palette.btns.default,
        opacity: 0.9,
        borderRadius: 30,
        '&:hover': {
            backgroundColor: theme.palette.btns.gray
        }
    }
}))
const ViewList = (props) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    useEffect(() => {
        dispatch(getTeamOrder(props.parentState.TeamSelectedID));
    }, [])
    const teamName = props.parentState.TeamSelected.teamName;
    const TeamOrderState = useSelector((state) => state.getTeamOrderReducer);
    let TeamOrders = TeamOrderState.TeamOrdersList ? TeamOrderState.TeamOrdersList : [];
    if (props.parentState.Filter2Date) {
        TeamOrders = _.filter(TeamOrders, { 'deliveryDate': props.parentState.Filter2Date });
    }
    console.log(JSON.stringify(props.parentState.TeamSelected))
    return (
        <Dialog
            open={props.Status}
            fullWidth={true}
            fullScreen={false}
            maxWidth="md"
            disableBackdropClick={false}
            disableEscapeKeyDown={false}
        >
            <DialogTitle className={classes.dialogTitle} id="dialog-title">{props.Title}</DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={4}>
                    <TableContainer className={classes.root}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableBody>
                                {TeamOrders.length <= 0 ?
                                    <TableRow>
                                        <TableCell style={{ minWidth: "100%" }}>
                                            No orders for the team {teamName}
                                        </TableCell>
                                    </TableRow>
                                    : null}
                                {TeamOrders.map((item, index) => (
                                    <TableRow>
                                        <TableCell style={{ minWidth: "10%" }}>
                                            {index + 1}
                                        </TableCell>
                                        <TableCell style={{ minWidth: "20%" }}>
                                            {item.orderNumber}
                                        </TableCell>
                                        <TableCell style={{ minWidth: "70%" }}>
                                            {item.teamName}
                                        </TableCell>
                                    </TableRow>
                                ))}

                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Grid container md={12} direction="row" item style={{ display: "flex", justifyContent: "flex-end" }}>

                        <Button
                            onClick={() => props.parentClass.CloseList()}
                            variant="contained"
                            color="secondary"
                            className={classes.buttonCancel}
                        >
                            Close
                        </Button>

                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

export default ViewList;