import React, { useState, useEffect, Component } from 'react';
import {
    Grid, Dialog, GridList, DialogContent,
    GridListTile, DialogTitle, makeStyles,
    Typography, Divider, IconButton, DeleteIcon,
    EditIcon, _, swal, toast, useSelector, useDispatch,
    Card, GridListTileBar, CardContent, Checkbox, UnCheckedIcon, CheckedIcon
} from '../../utilities/PackagesList';
import {
    PageTitle, CustomToolbar, AlertDialog,
    CustomModel, InputField, Dropdown,
    Buttons, LoadingOverlay
} from "../../utilities/ComponentsList";
import { dateTimetoDate, isEmptyObj, CheckSame, convertDateDMY, AssignedStatusType, ServiceStatusType, capsFirstLetter } from "../../utilities/AppFunctions";
import { AssignService, GetTeamService, GetTimeSlots } from '../../api/Services';
import { refreshReduxStates } from './../../api/Refresh';
const useStyles = makeStyles(theme => ({
    dialogTitle: {

    },
    boldText:{
        fontSize:"0.72rem",
        fontWeight:"600",
    },
    titleBar: {
        background: 'transparent',
        height: 20
    },
    dialogWindow: {
        width: '80%'
    },
    listStyle: {
        border: "1px solid #eee",
        padding: 10,
        height: "250px",
        width: "100%"
    },
    cardStyle: {
        height: 90,
        padding: 10,
        margin: 10,
        boxShadow: "none",
        borderRadius: 10,
        backgroundColor: '#000'

    },
}))
const AssignTeam = (props) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [Addvalues, setAddValues] = React.useState({
        Team: "",
        Date: '',
        Time: [],
        TimeID: '',
        TeamSelected: '',
        CheckedItems: [],
        ServiceSlotReq: []
    });
    const [Createerrors, setCreateErrors] = useState({});
    useEffect(() => {
        dispatch(refreshReduxStates());
    }, [])
    let ParentState = props.parentState;
    let TeamListData = ParentState.TeamsLists;
    let ServiceList = ParentState.FilteredServiceLists;
    let serviceIDS = ParentState.SelectedServices;
    const createState = useSelector((state) => state.AssignServiceReducer);
    const TimeSlotsList = useSelector((state) => state.GetTimeSlotsReducer);
    const TeamServiceState = useSelector((state) => state.GetTeamServiceReducer);
    let TeamServicesList = TeamServiceState.TeamServices ? TeamServiceState.TeamServices : [];
    TeamServicesList = _.orderBy(TeamServicesList, ['serviceDate'], ['asc']);
    /* if (Addvalues.Date && Addvalues.TeamSelected) {
        TeamServicesList = _.filter(TeamServicesList, function (item) {
            return (dateTimetoDate(item.serviceDate) == Addvalues.Date);
        });
       
    } */
    let timeSlots = TimeSlotsList.TimeSlots ? TimeSlotsList.TimeSlots : [];
    console.log(JSON.stringify(timeSlots))
    let SlectedServiceList = ServiceList.filter(function (value, index) {
        let orderExist = serviceIDS.includes(value.id);
        return orderExist;
    })
    let totalTeamService = TeamServicesList.length;
    if (createState.Success && !isEmptyObj(createState.AssignServiceRsponse)) {
        toast.success('Team Assigned Successfully!', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: false,
        });
        let assignedServices = Addvalues.CheckedItems;
        props.ModelConfirm(assignedServices)
    } else if (createState.Failure && !isEmptyObj(createState.AssignServiceRsponse)) {
        toast.error(createState.AssignServiceRsponse.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: false,
        });
        dispatch(refreshReduxStates());
    }
    const GetServiceByTeam = (dateSel) => {
        let filterObj = {
            StartDate: dateSel,
            EndDate: dateSel,
            StatusID: "",
            JobType: "",
            TeamID: Addvalues.TeamSelected
        }
        if (dateSel) {
            dispatch(GetTeamService(filterObj));
            dispatch(GetTimeSlots(filterObj));
        }

    }
    const handleChange = (data, name) => {
        let targetValue = data.target.value;
        if (name === 'Date') {
            SlectedServiceList = SlectedServiceList.filter(function (value, index) {
                value.timeSlots = [];
                return value;
            });
            setAddValues({
                ...Addvalues,
                [name]: targetValue,
                Time: [],
                TimeID: '',
                CheckedItems: [],
                ServiceSlotReq: []
            });
            setCreateErrors({ ...Createerrors, [name]: targetValue ? false : true });
            if (Addvalues.TeamSelected && targetValue) {
                GetServiceByTeam(targetValue);
            } else {
                dispatch(refreshReduxStates());
            }
        } else {
            setAddValues({ ...Addvalues, [name]: targetValue });
            setCreateErrors({ ...Createerrors, [name]: targetValue ? false : true })
        }
    };
    const AssigningService = (serviceID, Slots, serviceDate, status) => {
        
        let existTimeSlots = Slots ? Slots : [];
        let timeID = Addvalues.TimeID;
        let teamID = Addvalues.TeamSelected;
        let timeObj = Addvalues.Time;
        let selectedItems = Addvalues.CheckedItems;
        let ServiceReqSlot = Addvalues.ServiceSlotReq;
        if(!CheckSame(dateTimetoDate(serviceDate),Addvalues.Date)){
            toast.warning("Team date is different from service date, Please choose same date", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: false,
            });
            return;
        }
        if ((timeID && teamID) || status) {
            let Arrayindex = selectedItems.indexOf(serviceID);
            let index = SlectedServiceList.findIndex(sList => sList.id === serviceID);
            if (selectedItems.includes(serviceID)) {
                if (Arrayindex > -1) {
                    selectedItems.splice(Arrayindex, 1);
                    SlectedServiceList[index].timeSlots = [];
                    ServiceReqSlot = ServiceReqSlot.filter(function (value, index) {
                        return value.serviceId != serviceID;
                    });
                    timeSlots = timeSlots.filter(function (value, index) {
                        let Exist = existTimeSlots.find(function (ele) { return ele.startTime == value.startTime && ele.endTime == value.endTime; });
                        if (Exist) {
                            value.assigned = false;
                        }
                        return value;
                    });
                }
            } else {
                selectedItems.push(serviceID);
                SlectedServiceList[index].timeSlots = timeObj;
                timeSlots = timeSlots.filter(function (value, index) {
                    let Exist = timeID.includes(value.id);
                    if (Exist) {
                        value.assigned = true;
                    }
                    return value;
                });
                let reqObj = {
                    serviceId: serviceID,
                    timeSlotId: timeID.toString()
                }
                ServiceReqSlot.push(reqObj)
            }
            setAddValues({
                ...Addvalues,
                CheckedItems: selectedItems,
                Time: [],
                TimeID: '',
                ServiceSlotReq: ServiceReqSlot
            });
        } else {
            setCreateErrors({
                ...Createerrors,
                Time: timeID ? false : true,
                TimeID: timeID ? false : true,
                Team: teamID ? false : true,
                TeamSelected: teamID ? false : true
            })
        }
    };
    const submitForm = () => {
        let srrorObj = {};
        let errorStatus = false;
        Object.keys(Addvalues).map(function (k) {
            if (k == 'CheckedItems' || k == 'ServiceSlotReq' || k == 'Time' || k == 'TimeID') {
                srrorObj[k] = false;
                errorStatus = errorStatus ? true : false;
            } else {
                srrorObj[k] = Addvalues[k] ? false : true;
                errorStatus = errorStatus ? true : !Addvalues[k];
            }
        })
        setCreateErrors(srrorObj);
        if (!errorStatus && Addvalues.ServiceSlotReq.length > 0) {
            let addObject = {
                teamId: Addvalues.TeamSelected,
                //serviceIds: ParentState.SelectedServices,
                date: Addvalues.Date,
                //time: Addvalues.Time,
                serviceSlots: Addvalues.ServiceSlotReq
            }

            dispatch(AssignService(addObject));
        } else {
            toast.warning("Please schedule atleast one service", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: false,
            });
        }
    };

    return (
        <Dialog
            open={props.Status}
            //onClose={()=>this.closeDialog()}
            fullWidth={true}
            fullScreen={false}
            maxWidth="lg"
            //  className={classes.dialogWindow}
            disableBackdropClick={false}
            disableEscapeKeyDown={false}
        >
            <DialogTitle className={classes.dialogTitle} id="dialog-title">{props.Title}</DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={4}>
                    <Grid item md={12}>
                        <Grid container spacing={1} direction="row"  >
                            <Grid container item md={2} spacing={1} >
                                <Grid item md={12} >
                                    <Dropdown
                                        label={"Select Team"}
                                        data={TeamListData}
                                        getOptionLabel={option => (option.teamName ? option.teamName : "")}
                                        getOptionSelected={(option, value) => option.id === value.id}
                                        value={Addvalues.Team}
                                        ItemChange={(event, newValue) => {
                                            //TeamOrders = [];
                                            setAddValues({
                                                ...Addvalues,
                                                TeamSelected: newValue ? newValue.id : null,
                                                Team: newValue ? newValue : null
                                            });
                                            setCreateErrors({
                                                ...Createerrors,
                                                TeamSelected: newValue ? false : true,
                                                Team: newValue ? false : true
                                            })
                                            let filterObj = {
                                                StartDate: Addvalues.Date,
                                                EndDate: Addvalues.Date,
                                                StatusID: "",
                                                JobType: "",
                                                TeamID: newValue ? newValue.id : null
                                            }
                                            if (newValue && Addvalues.Date) {
                                                GetServiceByTeam(Addvalues.Date);
                                            } else {
                                                dispatch(refreshReduxStates());
                                            }

                                        }}
                                        error={Createerrors.TeamSelected}
                                        initialName={"Team"}
                                        errorMsg={"Please choose team."}
                                    />
                                </Grid>
                                <Grid item md={12}>
                                    <InputField
                                        label={"Select Date"}
                                        type={"date"}
                                        required={true}
                                        value={Addvalues.Date}
                                        changeText={(data) => handleChange(data, 'Date')}
                                        error={Createerrors.Date}
                                        initialName={"Date"}
                                        errorMsg={"Please enter date."}
                                    />
                                </Grid>
                                <Grid item md={12}>
                                    {/* <InputField
                                        label={"Select Time"}
                                        type={"time"}
                                        required={true}
                                        value={Addvalues.Time}
                                        changeText={(data) => handleChange(data, 'Time')}
                                        error={Createerrors.Time}
                                        initialName={"Time"}
                                        errorMsg={"Please enter time."}
                                    /> */}
                                    <Dropdown
                                        label={"Select Time Slot"}
                                        data={timeSlots}
                                        multiple={true}
                                        filterSelectedOptions={true}
                                        getOptionLabel={option => (option.id ? option.startTime + ' - ' + option.endTime : "")}
                                        getOptionSelected={(option, value) => option.id === value.id}
                                        getOptionDisabled={(option) => option.assigned}
                                        value={Addvalues.Time}
                                        ItemChange={(event, newValue) => {

                                            if (newValue !== null) {
                                                let items = [];
                                                let IDs = [];
                                                newValue.map((v) => {
                                                    items.push(v);
                                                    IDs.push(v.id)
                                                });

                                                setAddValues({
                                                    ...Addvalues,
                                                    TimeID: IDs,
                                                    Time: items
                                                });
                                                setCreateErrors({
                                                    ...Createerrors,
                                                    TimeID: IDs.length > 0 ? false : true,
                                                    Time: IDs.length > 0 ? false : true
                                                })
                                            }

                                        }}
                                        error={Createerrors.TimeID}
                                        initialName={"Time Slot"}
                                        errorMsg={"Please choose time slot."}
                                    />
                                </Grid>
                            </Grid>

                            <Grid item md={5} spacing={1} >
                                <Typography className={classes.boldText}>New Services</Typography>
                                <Grid item md={12} style={{ border: "1px solid grey", height: '100%' }}>
                                    <GridList padding={20} cols={2}
                                        style={{
                                            height: "250px",
                                            alignContent: 'flex-start',
                                            justifyContent: 'flex-start',
                                            marginLeft: 10
                                        }}>

                                        {SlectedServiceList.map((items) => {
                                            let tSlots = items.timeSlots ? items.timeSlots : [];
                                            let SlotsAllocated = "";
                                            tSlots.map(a => SlotsAllocated += a.startTime + ' - ' + a.endTime + ",");
                                            SlotsAllocated = SlotsAllocated.replace(/(^,)|(,$)/g, "");
                                            return (
                                                <GridListTile
                                                    style={{
                                                        height: 120,
                                                        width: 230,
                                                        padding: 10,
                                                        margin: 5,
                                                        boxShadow: "1px 1px 5px 1px #9E9E9E",
                                                        borderRadius: 10,
                                                    }} key={items.id} >
                                                    {/* <Checkbox
                                                checked={Addvalues.CheckedItems.includes(items.id)}
                                                onChange={(e) => { AssigningService(items.id) }}
                                                name="checkedB"
                                                color="primary"
                                                size="small"
                                            /> */}
                                                    <Typography>
                                                        Job Type: {capsFirstLetter(items.jobType)}
                                                    </Typography>
                                                    <Typography >
                                                        Customer: {items.customerName}
                                                    </Typography>
                                                    <Typography >
                                                        Service Date: {convertDateDMY(items.serviceDate)}
                                                    </Typography>
                                                    <Typography >
                                                        Status: {AssignedStatusType(Addvalues.CheckedItems.includes(items.id))}
                                                    </Typography>
                                                    <Typography >
                                                        Slots: {SlotsAllocated ? SlotsAllocated : "NA"}
                                                    </Typography>
                                                    <GridListTileBar
                                                        actionPosition={"right"}
                                                        titlePosition="top"
                                                        className={classes.titleBar}
                                                        actionIcon={
                                                            <IconButton
                                                                onClick={() => AssigningService(items.id, items.timeSlots, items.serviceDate, Addvalues.CheckedItems.includes(items.id))}
                                                                aria-label="Assign"
                                                                size="small"
                                                            >
                                                                {Addvalues.CheckedItems.includes(items.id) ?
                                                                    <CheckedIcon fontSize="small" color="primary" />
                                                                    :
                                                                    <UnCheckedIcon fontSize="small" color="primary" />
                                                                }

                                                            </IconButton>
                                                        }
                                                    />
                                                </GridListTile>
                                            )
                                        })}
                                    </GridList>
                                </Grid>

                            </Grid>
                           
                            <Grid item md={5} spacing={1} >
                                <Typography className={classes.boldText}>Existing Services</Typography>
                                <Grid item md={12} style={{ border: "1px solid grey", height: '100%' }}>
                                    <GridList padding={20} cols={2}
                                        style={{
                                            height: "250px",
                                            alignContent: 'flex-start',
                                            justifyContent: 'flex-start',
                                            marginLeft: 10
                                        }}>

                                        {TeamServicesList.map((items) => {
                                            let tSlots = items.timeSlots ? items.timeSlots : [];
                                            let SlotsAllocated = "";
                                            tSlots.map(a => SlotsAllocated += a.startTime + ' - ' + a.endTime + ",");
                                            SlotsAllocated = SlotsAllocated.replace(/(^,)|(,$)/g, "");
                                            return (
                                                <GridListTile
                                                    style={{
                                                        height: 120,
                                                        width: 230,
                                                        padding: 10,
                                                        margin: 5,
                                                        boxShadow: "1px 1px 5px 1px #9E9E9E",
                                                        borderRadius: 10,
                                                    }} key={items.id} >
                                                    <Typography>
                                                        Job Type: {capsFirstLetter(items.jobType)}
                                                    </Typography>
                                                    <Typography >
                                                        Customer: {items.customerName}
                                                    </Typography>
                                                    <Typography >
                                                        Service Date: {convertDateDMY(items.serviceDate)}
                                                    </Typography>
                                                    {/* <Typography >
                                                        Postal: {items.postalCode}
                                                    </Typography> */}
                                                    {/* <Typography >
                                            Fee: {PriceFormat(items.fee)}
                                        </Typography> */}
                                                    <Typography >
                                                        Slots: {SlotsAllocated ? SlotsAllocated : "Not Assigned"}
                                                    </Typography>
                                                    <Typography >
                                                        Status: {ServiceStatusType(items.serviceStatusId)}
                                                    </Typography>
                                                    {/* <Typography >
                                            Date: {DMY(items.serviceDate)}
                                        </Typography> */}
                                                    {/* <Divider /> */}
                                                </GridListTile>

                                            )
                                        })}
                                    </GridList>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={12} style={{ display: "flex", justifyContent: "flex-end" }} >
                        <Typography  >
                            Total Service : {totalTeamService}
                        </Typography>
                    </Grid>
                    <Buttons
                        close={() => props.ModelCancel()}
                        closeText={"Cancel"}
                        save={() => submitForm()}
                        saveText={"Submit"}
                    />
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

export default AssignTeam;