export function syncSellnchillCustomerReducer(state = {}, action) {    
    
    switch (action.type) {
        case 'SYNC_CUST_INFO_SUCCESS': {
            return {
                ...state,
                Success: true,
                Failure: false,
                SyncResponse: action.data
            }
        }
        case 'SYNC_CUST_INFO_FAILURE': {
            return {
                ...state,
                Failure: true,
                Success:false,
                SyncResponse: action.data
            }
        }
        case 'SYNC_CUST_INFO': {
            return {
                ...state,
                Success: false,
                Failure: false,
                SyncResponse: null
            }
        }
        default: {
            return state;
        }
    }
}