
export function LoaderStatus(status) {
    return (dispatch) => {
        if(status===false){
            dispatch({ type: 'UNLOADING' });
        }else if(status===true){
            dispatch({ type: 'LOADING' });
        }else{
            dispatch({ type: 'UNLOADING' });
        }
    }
}