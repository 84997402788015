import React, { useState, useEffect, Component } from 'react';
import {
    Grid, Dialog, GridList, DialogContent,
    GridListTile, DialogTitle, makeStyles,
    Typography, Divider, IconButton, DeleteIcon,
    EditIcon, _, swal, toast, useSelector, useDispatch,
    Card ,CardMedia, CardContent, CardActions, Box
} from '../../utilities/PackagesList';
import {
    PageTitle, CustomToolbar, AlertDialog,
    CustomModel, InputField, Dropdown,
    Buttons, LoadingOverlay
} from "../../utilities/ComponentsList";
import { isEmptyObj, DMY, dateTimetoDate, PriceFormat } from "../../utilities/AppFunctions";
import { AssignService, GetTeamService } from '../../api/Services';
import { refreshReduxStates } from './../../api/Refresh';
const useStyles = makeStyles(theme => ({
    dialogTitle: {

    },
    dialogWindow:{
        width:'80%'
    },
    listStyle:{
        border:"1px solid #eee",
        padding:10,
        height:"250px",
        width:"100%"
    },
    cardStyle: {
        height:90,
        padding:10,
        margin:10,
        boxShadow: "none",
        borderRadius: 10,
        backgroundColor:'#000'

    },
}))
const AssignTeam = (props) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [Addvalues, setAddValues] = React.useState({
        Team: '',
        Date: '',
        Time: '',
        TeamSelected: ''
    });
    const [Createerrors, setCreateErrors] = useState({});
    useEffect(() => {
        dispatch(refreshReduxStates());
    }, [])
    const createState = useSelector((state) => state.AssignServiceReducer);
    const TeamServiceState = useSelector((state) => state.GetTeamServiceReducer);
    let TeamServicesList = TeamServiceState.TeamServices ? TeamServiceState.TeamServices : [];
    TeamServicesList = _.orderBy(TeamServicesList, ['serviceDate'], ['asc']);
    let selectedSer = ParentState.SelectedServices;
    if(Addvalues.Date){
        TeamServicesList = _.filter(TeamServicesList, function (item) {
            return (dateTimetoDate(item.serviceDate) == Addvalues.Date);
          });
    }
    TeamServicesList = TeamServicesList.filter(function (value, index) {
        let ItemExist = selectedSer.includes(value.id);
        return !ItemExist;
      });
    let ParentState = props.parentState;
    let TeamListData = ParentState.TeamsLists;
    let totalTeamService = TeamServicesList.length;
    if (createState.Success && !isEmptyObj(createState.AssignServiceRsponse)) {
        toast.success('Team Assigned Successfully!', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: false,
        });
        props.parentClass.AssignedTeam()
    } else if (createState.Failure && !isEmptyObj(createState.AssignServiceRsponse)) {
        toast.error(createState.AssignServiceRsponse.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: false,
        });
    }
    const handleChange = (data,name) => {
        let targetValue = data.target.value;
        setAddValues({ ...Addvalues, [name]: targetValue });
        setCreateErrors({ ...Createerrors, [name]: targetValue ? false : true })
    };

    const submitForm = () => {
        let srrorObj = {};
        let errorStatus = false;
        Object.keys(Addvalues).map(function (k) {
            srrorObj[k] = Addvalues[k] ? false : true;
            errorStatus = errorStatus ? true : !Addvalues[k];
        })
        setCreateErrors(srrorObj);
        if (!errorStatus) {
            let addObject = {
                teamId : Addvalues.TeamSelected,
                serviceIds: ParentState.SelectedServices,
                date: Addvalues.Date,
                time:Addvalues.Time,
            }
            console.log(JSON.stringify(addObject))
            dispatch(AssignService(addObject));
        }
    };
    
   return (
        <Dialog
            open={props.Status}
            //onClose={()=>this.closeDialog()}
            fullWidth={true}
            fullScreen={false}
            maxWidth="md"
          //  className={classes.dialogWindow}
            disableBackdropClick={false}
            disableEscapeKeyDown={false}
        >
            <DialogTitle className={classes.dialogTitle} id="dialog-title">{props.Title}</DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={4}>
                    <Grid item md={12}>
                        <Grid
                            container
                            spacing={1}
                            direction="row"
                        >
                            <Grid
                                container
                                item
                                md={3}
                                spacing={1}
                            >
                                <Grid item md={12} >
                                    <Dropdown
                                        label={"Select Team"}
                                        data={TeamListData}
                                        getOptionLabel={option => (option.teamName ? option.teamName : "")}
                                        getOptionSelected={(option, value) => option.id === value.id}
                                        value={Addvalues.Team}
                                        ItemChange={(event, newValue) => {
                                            //TeamOrders = [];
                                            setAddValues({
                                                ...Addvalues,
                                                TeamSelected: newValue ? newValue.id : null,
                                                Team: newValue ? newValue : null
                                            });
                                            setCreateErrors({
                                                ...Createerrors,
                                                TeamSelected: newValue ? false : true,
                                                Team: newValue ? false : true
                                            })
                                            let filterObj = {
                                                StartDate: Addvalues.Date,
                                                EndDate: Addvalues.Date,
                                                StatusID: "",
                                                JobType: "",
                                                TeamID : newValue ? newValue.id : null
                                              }
                                            if(newValue){
                                                dispatch(GetTeamService(filterObj));
                                            }else{
                                                dispatch(refreshReduxStates());
                                            }
                                            
                                        }}
                                        error={Createerrors.TeamSelected}
                                        initialName={"Team"}
                                        errorMsg={"Please choose team."}
                                    />
                                </Grid>
                                <Grid item md={12}>
                                    <InputField
                                        label={"Select Date"}
                                        type={"date"}
                                        required={true}
                                        value={Addvalues.Date}
                                        changeText={(data)=>handleChange(data ,'Date')}
                                        error={Createerrors.Date}
                                        initialName={"Date"}
                                        errorMsg={"Please enter date."}
                                    />
                                </Grid>
                                <Grid item md={12}>
                                    <InputField
                                        label={"Select Time"}
                                        type={"time"}
                                        required={true}
                                        value={Addvalues.Time}
                                        changeText={(data)=>handleChange(data ,'Time')}
                                        error={Createerrors.Time}
                                        initialName={"Time"}
                                        errorMsg={"Please enter time."}
                                    />
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                md={9}
                                spacing={1}
                            >
                                <GridList padding={20}  cols={3}  
                                style={{height:"250px",
                                alignContent:'flex-start',
                                justifyContent:'flex-start',
                                marginLeft:20
                                }}>
                               
                                    {TeamServicesList.map((items) => (
                                        <GridListTile  
                                            style={{ 
                                            height:85,
                                            width:200,
                                            padding:10,
                                            margin:5,
                                            boxShadow: "1px 1px 5px 1px #9E9E9E",
                                            borderRadius: 10,
                                        }} key={items.id} >
                                            <Typography style={{ fontWeight: "bold" }}>
                                               Cust. No.: {items.customerNumber}
                                            </Typography>
                                            <Typography >
                                               Fee: {PriceFormat(items.fee)}
                                            </Typography>
                                            <Typography >
                                               Status: {items.serviceStatusName}
                                            </Typography>
                                            <Typography >
                                               Date: {DMY(items.serviceDate)}
                                            </Typography>
                                            {/* <Divider /> */}
                                        </GridListTile>

                                    ))}
                                    {TeamServicesList.length<=0 ? 
                                        <GridListTile  style={{ height: 'auto',paddingBottom:"10px"}} rows={1}>
                                        <Typography  >
                                           No service assigned to the selected team
                                        </Typography>
                                    </GridListTile>
                                        : null}
                                </GridList>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={12} style={{ display: "flex", justifyContent: "flex-end" }} >
                        <Typography  >
                            Total Service : {totalTeamService}
                        </Typography>
                    </Grid>
                    <Buttons
                        close={() => props.parentClass.CloseAssign()}
                        closeText={"Cancel"}
                        save={()=>submitForm()}
                        saveText={"Submit"}
                    />
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

export default AssignTeam;