
import { combineReducers } from './../utilities/PackagesList';
import {
  LoginReducer,
  LogOutReducer
} from './LoginReducer';
import {
  getVechileTypesReducer,
  AddVechileReducer,
  getVechilesReducer,
  getVechileSizeReducer,
  UpdateVechileReducer,
  DeleteVechileReducer
} from './VechileReducer';
import {
  getStaffTypesReducer,
  getStaffsReducer,
  AddStaffReducer,
  UpdateStaffReducer,
  DeleteStaffReducer
} from './StaffReducer';
import {
  getTeamsReducer,
  getUnAssignedReducer,
  AddTeamReducer,
  DeleteTeamReducer,
  UpdateTeamReducer,
  getTeamTypesReducer,
  GetTeamLeavesReducer,
  DeleteTeamLeavesReducer,
  AddTeamLeavesReducer
} from './TeamReducer';
import {
  getAdhocReducer,
  AddAdhocReducer,
  DeleteAdhocReducer,
  UpdateAdhocReducer,
  getOrderReducer,
  AdhocScheduleDeliveryReducer,
  getTeamOrderReducer,
  getSellerReducer,
  SyncOrderReducer,
  UpdateOrderStatusReducer,
  UpdateSchedulesTeamReducer,
  RestoreDeliveryReducer,
  getPTripReportReducer
} from './AdhocReducer';
import {
  GetServiceReducer,
  AddServiceReducer,
  UpdateServiceReducer,
  DeleteServiceReducer,
  AddServiceDetailReducer,
  GetAdhocServiceReducer,
  AddAdhocServiceReducer,
  UpdateAdhocServiceReducer,
  DeleteAdhocServiceReducer,
  GetCustomerProductReducer,
  AssignServiceReducer,
  GetServiceStatusReducer,
  GetTeamServiceReducer,
  GetJobTypesReducer,
  AddJobTypesReducer,
  UpdateJobTypesReducer,
  DeleteJobTypesReducer,
  GetPaymentTypesReducer,
  AddPaymentTypesReducer,
  UpdatePaymentTypesReducer,
  DeletePaymentTypesReducer,
  GetPaymentStatusReducer,
  GetTimeSlotsReducer,
  AddTimeSlotsReducer,
  UpdateTimeSlotsReducer,
  DeleteTimeSlotsReducer,
  GetTimeSlotReportReducer,
  GetServiceFormsReducer,
  GetFilledServiceFormsReducer,
  GetServiceReportReducer,
  ExportCustomersReducer,
  GetSellnchillReducer
} from './ServiceReducer';
import { SendSMSReducer } from './NotificationReducer';
import { syncSellnchillCustomerReducer } from './SchedulerReducer';
import { LoaderReducer } from './LoaderReducer';
import { RefreshReducer } from './RefreshReducer';

const appReducer = combineReducers({
  LoginReducer,
  LogOutReducer,
  getVechileTypesReducer,
  AddVechileReducer,
  getVechilesReducer,
  getVechileSizeReducer,
  LoaderReducer,
  UpdateVechileReducer,
  DeleteVechileReducer,
  getStaffTypesReducer,
  getStaffsReducer,
  AddStaffReducer,
  UpdateStaffReducer,
  DeleteStaffReducer,
  getTeamsReducer,
  getTeamTypesReducer,
  GetTeamLeavesReducer,
  DeleteTeamLeavesReducer,
  AddTeamLeavesReducer,
  getUnAssignedReducer,
  AddTeamReducer,
  DeleteTeamReducer,
  UpdateTeamReducer,
  RefreshReducer,
  getAdhocReducer,
  AddAdhocReducer,
  DeleteAdhocReducer,
  UpdateAdhocReducer,
  getOrderReducer,
  AdhocScheduleDeliveryReducer,
  getTeamOrderReducer,
  getSellerReducer,
  SyncOrderReducer,
  UpdateOrderStatusReducer,
  UpdateSchedulesTeamReducer,
  RestoreDeliveryReducer,
  getPTripReportReducer,
  GetServiceReducer,
  AddServiceReducer,
  UpdateServiceReducer,
  DeleteServiceReducer,
  AddServiceDetailReducer,
  GetAdhocServiceReducer,
  AddAdhocServiceReducer,
  UpdateAdhocServiceReducer,
  DeleteAdhocServiceReducer,
  GetCustomerProductReducer,
  AssignServiceReducer,
  GetServiceStatusReducer,
  GetTeamServiceReducer,
  GetJobTypesReducer,
  AddJobTypesReducer,
  UpdateJobTypesReducer,
  DeleteJobTypesReducer,
  GetPaymentTypesReducer,
  AddPaymentTypesReducer,
  UpdatePaymentTypesReducer,
  DeletePaymentTypesReducer,
  GetPaymentStatusReducer,
  GetTimeSlotsReducer,
  AddTimeSlotsReducer,
  UpdateTimeSlotsReducer,
  DeleteTimeSlotsReducer,
  GetTimeSlotReportReducer,
  GetServiceFormsReducer,
  GetFilledServiceFormsReducer,
  GetServiceReportReducer,
  ExportCustomersReducer,
  SendSMSReducer,
  GetSellnchillReducer,
  syncSellnchillCustomerReducer
});
const initialState = appReducer({}, {})

const reducer = (state, action) => {
  if (action.type === 'LOG_OUT') {
    state = initialState
  }

  return appReducer(state, action)
}
export default reducer;
