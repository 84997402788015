import React from "react";
import { Button, Grid } from "./../../utilities/PackagesList";
import useStyles from "./../../utilities/Styles";
const TwoButtons = (props) => {
    const classes = useStyles();
    return (
        <Grid container md={12} direction="row" item spacing={4}>
            <Grid item md={6} style={{ display: "flex", justifyContent: "flex-start" }} >
                <Button
                    onClick={props.close}
                    variant="contained" 
                    color="secondary"
                    className={classes.buttonCancel}
                    >
                    {props.closeText}
                    </Button>
            </Grid>
            <Grid item md={6} style={{ display: "flex", justifyContent: "flex-end" }} >
                <Button
                    onClick={props.save}
                    variant="contained" 
                    color="primary"
                    className={classes.buttonSuccess}
                    >
                    {props.saveText}
                    </Button>

            </Grid>
        </Grid>
    )
}
export default TwoButtons;