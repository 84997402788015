export function RefreshReducer(state = {}, action) {    
    switch (action.type) {
        case 'REFRESHING': {
            return {
                ...state,
            }
        }
        default: {
            return state;
        }
    }
}