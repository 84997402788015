import React, { useState, useEffect, Component } from 'react';
import {
    Grid, Dialog, GridList, DialogContent,
    GridListTile, DialogTitle, makeStyles,
    Typography, Divider, IconButton, DeleteIcon,
    EditIcon, _, swal, toast, useSelector, useDispatch,
    Card ,GridListTileBar, CheckedIcon, UnCheckedIcon, Box
} from '../../utilities/PackagesList';
import {
    PageTitle, CustomToolbar, AlertDialog,
    CustomModel, InputField, Dropdown,
    Buttons, LoadingOverlay
} from "../../utilities/ComponentsList";
import { dateTimetoDate, isEmptyObj, CheckSame, convertDateDMY, AssignedStatusType, ServiceStatusType, capsFirstLetter, UnChangeObj } from "../../utilities/AppFunctions";
import { UpdateService, GetTeamService, GetTimeSlots } from '../../api/Services';
import { refreshReduxStates } from './../../api/Refresh';
const useStyles = makeStyles(theme => ({
    dialogTitle: {

    },
    boldText:{
        fontSize:"0.72rem",
        fontWeight:"600",
    },
    titleBar: {
        background: 'transparent',
        height: 20
    },
    dialogWindow:{
        width:'80%'
    },
    listStyle:{
        border:"1px solid #eee",
        padding:10,
        height:"250px",
        width:"100%"
    },
    cardStyle: {
        height:90,
        padding:10,
        margin:10,
        boxShadow: "none",
        borderRadius: 10,
        backgroundColor:'#000'

    },
}))
const EditTeam = (props) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [Addvalues, setAddValues] = React.useState({
        Team: "",
        Date: '',
        Time: [],
        TimeID: '',
        TeamSelected: '',
        CheckedItems: [],
        ServiceSlotReq: [],
        ServiceListData : [],
        SlectedSSlots : [],
        CheckedStatus : false,
        ServiceDate :'',
        TeamChange : false
    });
    const [Createerrors, setCreateErrors] = useState({});
    const UpdateState = useSelector((state) => state.UpdateServiceReducer);
    const TimeSlotsList = useSelector((state) => state.GetTimeSlotsReducer);
    const TeamServiceState = useSelector((state) => state.GetTeamServiceReducer);

    let ParentState = props.parentState;
    let TeamListData = ParentState.TeamsLists;
    
    
    let TeamServicesList = TeamServiceState.TeamServices ? TeamServiceState.TeamServices : [];
    TeamServicesList = _.orderBy(TeamServicesList, ['serviceDate'], ['asc']);
    let timeSlots = TimeSlotsList.TimeSlots ? TimeSlotsList.TimeSlots : [];
    timeSlots = Addvalues.TeamChange ? [] : timeSlots;
    let EditingID = ParentState.EditID;
     useEffect(() => {
        if(EditingID){
          let ServiceList = ParentState.FilteredServiceList;
          let EditingService = _.filter(ServiceList, { 'id': EditingID });
          var SlotsIDs = EditingService[0].timeSlots.map(function(a) {return a.id;});
          let ServiceReqSlot = [];
          let reqObj = {
                serviceId: EditingID,
                timeSlotId: SlotsIDs.toString()
            }
            ServiceReqSlot.push(reqObj)
          setAddValues({
            Team: EditingService[0],
            TeamSelected: EditingService[0].teamId,
            Date: EditingService[0].teamDate,
            Time: EditingService[0].timeSlots,
            TimeID: SlotsIDs,
            CheckedItems: [EditingID],
            ServiceSlotReq: ServiceReqSlot,
            ServiceListData : UnChangeObj(ParentState.FilteredServiceList),
            SlectedSSlots : EditingService[0].timeSlots,
            CheckedStatus : false,
            ServiceDate :dateTimetoDate(EditingService[0].serviceDate),
            TeamChange : false
          })
          GetServiceByTeam(EditingService[0].teamDate, EditingService[0].teamId);
        } 
        
    }, [EditingID])
    
    let ServiceList = Addvalues.ServiceListData;
    
     if(ServiceList.length>0 && Addvalues.CheckedStatus){
        let Serindex = ServiceList.findIndex(sList => sList.id === EditingID);
        let EditinfService = ServiceList[Serindex];
        if(CheckSame(Addvalues.Date,dateTimetoDate(EditinfService.serviceDate)) && EditinfService.teamId==Addvalues.TeamSelected.toString()){
            timeSlots = timeSlots.filter(function (value, index) {
                let Exist = Addvalues.SlectedSSlots.find(function (ele) { return ele.id == value.id; });
                if (Exist) {
                    value.assigned = false;
                }
                return value;
            });
        }
    } 
    let SlectedServiceList = ServiceList.filter(function (value, index) {
        let orderExist = EditingID == value.id;
        return orderExist;
    });
    TeamServicesList = TeamServicesList.filter(function (value, index) {
        let orderExist = EditingID == value.id;
        return !orderExist;
    })
    let totalTeamService = TeamServicesList.length;
    if (UpdateState.Success && !isEmptyObj(UpdateState.UpdateResponse)) {
        toast.success('Updated Successfully!', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: false,
        });
        let addedObject = {
            teamId : Addvalues.TeamSelected,
            teamName: Addvalues.Team.teamName,
            teamDate : Addvalues.Date,
            timeSlots: Addvalues.Time
        }
        props.ModelConfirm(addedObject)
    } else if (UpdateState.Failure && !isEmptyObj(UpdateState.UpdateResponse)) {
        toast.error(UpdateState.UpdateResponse.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: false,
        });
        dispatch(refreshReduxStates());
    }
    const GetServiceByTeam = (dateSel,teamID=null) => {
        let filterObj = {
            StartDate: dateSel,
            EndDate: dateSel,
            StatusID: "",
            JobType: "",
            TeamID: teamID ? teamID : Addvalues.TeamSelected
        }
        if (dateSel) {
            dispatch(GetTeamService(filterObj));
            dispatch(GetTimeSlots(filterObj));
        }

    }
    const handleChange = (data, name) => {
        let targetValue = data.target.value;
        if (name === 'Date') {
            SlectedServiceList = SlectedServiceList.filter(function (value, index) {
                value.timeSlots = [];
                return value;
            }); 
            setAddValues({
                ...Addvalues,
                [name]: targetValue,
                CheckedStatus : true,
                Time: [],
                TimeID: '',
                TeamChange : false,
                CheckedItems: [],
                ServiceSlotReq: []
            });
            setCreateErrors({ ...Createerrors, [name]: targetValue ? false : true });
            if (Addvalues.TeamSelected && targetValue) {
                GetServiceByTeam(targetValue);
            } else {
                dispatch(refreshReduxStates());
            }
        } else {
            setAddValues({ ...Addvalues, [name]: targetValue, CheckedStatus : false, TeamChange : false, });
            setCreateErrors({ ...Createerrors, [name]: targetValue ? false : true })
        }
    };
    const AssigningService = (serviceID, Slots, serviceDate, status) => {
        
        let existTimeSlots = Slots ? Slots : [];
        let timeID = Addvalues.TimeID;
        let teamID = Addvalues.TeamSelected;
        let timeObj = Addvalues.Time;
        let selectedItems = Addvalues.CheckedItems;
        let ServiceReqSlot = Addvalues.ServiceSlotReq;
        if(!CheckSame(dateTimetoDate(serviceDate),Addvalues.Date)){
            toast.warning("Team date is different from service date, Please choose same date", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: false,
            });
            return;
        }
        if ((timeID && teamID) || status) {
            let Arrayindex = selectedItems.indexOf(serviceID);
            let index = SlectedServiceList.findIndex(sList => sList.id === serviceID);
            if (selectedItems.includes(serviceID)) {
                if (Arrayindex > -1) {
                    selectedItems.splice(Arrayindex, 1);
                    SlectedServiceList[index].timeSlots = [];
                    ServiceReqSlot = ServiceReqSlot.filter(function (value, index) {
                        return value.serviceId != serviceID;
                    });
                    timeSlots = timeSlots.filter(function (value, index) {
                        let Exist = existTimeSlots.find(function (ele) { return ele.startTime == value.startTime && ele.endTime == value.endTime; });
                        if (Exist) {
                            value.assigned = false;
                        }
                        return value;
                    });
                }
            } else {
                selectedItems.push(serviceID);
                SlectedServiceList[index].timeSlots = timeObj;
                timeSlots = timeSlots.filter(function (value, index) {
                    let Exist = timeID.includes(value.id);
                    if (Exist) {
                        value.assigned = true;
                    }
                    return value;
                });
                let reqObj = {
                    serviceId: serviceID,
                    timeSlotId: timeID.toString()
                }
                ServiceReqSlot.push(reqObj)
            }
            
            setAddValues({
                ...Addvalues,
                CheckedItems: selectedItems,
                TeamChange : false,
                //Time: [],
                CheckedStatus : false,
                //TimeID: '',
                ServiceSlotReq: ServiceReqSlot
            });
        } else {
            setCreateErrors({
                ...Createerrors,
                Time: timeID ? false : true,
                TimeID: timeID ? false : true,
                Team: teamID ? false : true,
                TeamSelected: teamID ? false : true
            })
        }
    };
    const changeTimeSlot = (event, newValue) =>{
        let index = SlectedServiceList.findIndex(sList => sList.id === EditingID);
        let existTimeSlots = Addvalues.Time ? Addvalues.Time : [];
        let items = [];
        let IDs = [];
        
        let PickedSlot = newValue ? newValue : [];
        PickedSlot.map((v) => {
            items.push(v);
            IDs.push(v.id)
        });
        if(existTimeSlots.length > PickedSlot.length){
            SlectedServiceList[index].timeSlots = PickedSlot;
            for(let i=0;i<existTimeSlots.length;i++){
                let Sindex = PickedSlot.findIndex(PSlot => PSlot.id === existTimeSlots[i].id);
                if(Sindex==-1){
                    let Pindex = timeSlots.findIndex(TSlot => TSlot.id === existTimeSlots[i].id);
                    timeSlots[Pindex].assigned = false;
                }
            } 
                /* alert(Exist)
                if (Exist) {
                    value.assigned = false;
                } */
                
        }
        let ServiceReqSlot = [];
        let reqObj = {
            serviceId: EditingID,
            timeSlotId: IDs.toString()
        }
        ServiceReqSlot.push(reqObj)
        setAddValues({
            ...Addvalues,
            TimeID: IDs,
            Time: items,
            CheckedItems : [],
            TeamChange : false,
            ServiceSlotReq : ServiceReqSlot
        });
        setCreateErrors({
            ...Createerrors,
            TimeID: IDs.length > 0 ? false : true,
            Time: IDs.length > 0 ? false : true
        })
    }
    const submitForm = () => {
        let srrorObj = {};
        let errorStatus = false;
        Object.keys(Addvalues).map(function (k) {
            if (k == 'CheckedItems' || k == 'ServiceSlotReq' || k == 'Time' || k == 'TimeID' || k == 'SlectedSSlots'|| k == 'CheckedStatus'|| k == 'TeamChange') {
                srrorObj[k] = false;
                errorStatus = errorStatus ? true : false;
            } else {
                srrorObj[k] = Addvalues[k] ? false : true;
                errorStatus = errorStatus ? true : !Addvalues[k];
            }
        })
        setCreateErrors(srrorObj);
        if (!errorStatus && Addvalues.ServiceSlotReq.length > 0 && Addvalues.CheckedItems.length>0) {
            let index = SlectedServiceList.findIndex(sList => sList.id === EditingID);
            if(CheckSame(dateTimetoDate(SlectedServiceList[index].serviceDate),Addvalues.Date)){
               /*  let updateObject = {
                    teamId: Addvalues.TeamSelected,
                    date: Addvalues.Date,
                    timeSlotId: Addvalues.TimeID.toString()
                }
                console.log(JSON.stringify(updateObject)) */
                var updateObject = new FormData();
                updateObject.append('teamId', Addvalues.TeamSelected);
                updateObject.append('teamDate', Addvalues.Date);
                updateObject.append('timeSlotId', Addvalues.TimeID.toString());
                dispatch(UpdateService(updateObject, EditingID));
            }else{
                toast.warning("Team date is different from service date, Please choose same date", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: false,
                });
                return;
            }
           
        } else {
            toast.warning("Please select the service you want to edit", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: false,
            });
        }
        /* if (!errorStatus) {
            let addObject = {
                teamId : Addvalues.TeamSelected,
                teamName: Addvalues.Team.teamName,
                date: Addvalues.Date,
                time:Addvalues.Time,
            }
            var updateObject = new FormData();
            updateObject.append('teamId', parseInt(Addvalues.TeamSelected));
            updateObject.append('teamName', Addvalues.Team.teamName);
            updateObject.append('teamDate', Addvalues.Date);
            updateObject.append('teamTime', Addvalues.Time);
            
            dispatch(UpdateService(updateObject, selectedSer));
        } */
    };
    
    return (
        <Dialog
            open={props.Status}
            //onClose={()=>this.closeDialog()}
            fullWidth={true}
            fullScreen={false}
            maxWidth="lg"
            //  className={classes.dialogWindow}
            disableBackdropClick={false}
            disableEscapeKeyDown={false}
        >
            <DialogTitle className={classes.dialogTitle} id="dialog-title">{props.Title}</DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={4}>
                    <Grid item md={12}>
                        <Grid container spacing={1} direction="row"  >
                            <Grid container item md={2} spacing={1} >
                                <Grid item md={12} >
                                    <Dropdown
                                        label={"Select Team"}
                                        data={TeamListData}
                                        getOptionLabel={option => (option.teamName ? option.teamName : "")}
                                        getOptionSelected={(option, value) => option.id === value.id}
                                        value={Addvalues.Team}
                                        ItemChange={(event, newValue) => {
                                            //TeamOrders = [];
                                            if(newValue!=null && Addvalues.TeamSelected!=newValue.id){
                                                setAddValues({
                                                    ...Addvalues,
                                                    TeamSelected: newValue ? newValue.id : null,
                                                    Team: newValue ? newValue : null,
                                                    Time: [],
                                                    TimeID: '',
                                                    CheckedItems: [],
                                                    ServiceSlotReq: [],
                                                    Date:'',
                                                    TeamChange : true
                                                });
                                                setCreateErrors({
                                                    ...Createerrors,
                                                    TeamSelected: newValue ? false : true,
                                                    Team: newValue ? false : true
                                                })
                                            }else if(newValue==null){
                                                setAddValues({
                                                    ...Addvalues,
                                                    TeamSelected: null,
                                                    Team: null,
                                                    Time: [],
                                                    TimeID: '',
                                                    CheckedItems: [],
                                                    ServiceSlotReq: [],
                                                    Date:'',
                                                    TeamChange : true
                                                });
                                                setCreateErrors({
                                                    ...Createerrors,
                                                    TeamSelected: newValue ? false : true,
                                                    Team: newValue ? false : true
                                                })
                                            }
                                            let SSindex = SlectedServiceList.findIndex(sList => sList.id === EditingID);
                                            SlectedServiceList[SSindex].timeSlots = [];
                                            //AssigningService(EditingID, Addvalues.SlectedSSlots, Addvalues.ServiceDate, Addvalues.CheckedItems.includes(EditingID));
                                           
                                        }}
                                        error={Createerrors.TeamSelected}
                                        initialName={"Team"}
                                        errorMsg={"Please choose team."}
                                    />
                                </Grid>
                                <Grid item md={12}>
                                    <InputField
                                        label={"Select Date"}
                                        type={"date"}
                                        required={true}
                                        value={Addvalues.Date}
                                        changeText={(data) => handleChange(data, 'Date')}
                                        error={Createerrors.Date}
                                        initialName={"Date"}
                                        errorMsg={"Please enter date."}
                                    />
                                </Grid>
                                <Grid item md={12}>
                                    {/* <InputField
                                        label={"Select Time"}
                                        type={"time"}
                                        required={true}
                                        value={Addvalues.Time}
                                        changeText={(data) => handleChange(data, 'Time')}
                                        error={Createerrors.Time}
                                        initialName={"Time"}
                                        errorMsg={"Please enter time."}
                                    /> */}
                                    <Dropdown
                                        label={"Select Time Slot"}
                                        data={timeSlots}
                                        multiple={true}
                                        filterSelectedOptions={true}
                                        getOptionLabel={option => (option.id ? option.startTime + ' - ' + option.endTime : "")}
                                        getOptionSelected={(option, value) => option.id === value.id}
                                        getOptionDisabled={(option) => option.assigned}
                                        value={Addvalues.Time}
                                        ItemChange={(event, newValue) => {changeTimeSlot(event, newValue)}}
                                        error={Createerrors.TimeID}
                                        initialName={"Time Slot"}
                                        errorMsg={"Please choose time slot."}
                                    />
                                </Grid>
                            </Grid>

                            <Grid item md={5} spacing={1} >
                                <Typography className={classes.boldText}>Editing Services</Typography>
                                <Grid item md={12} style={{ border: "1px solid grey", height: '100%' }}>
                                    <GridList padding={20} cols={2}
                                        style={{
                                            height: "250px",
                                            alignContent: 'flex-start',
                                            justifyContent: 'flex-start',
                                            marginLeft: 10
                                        }}>

                                        {SlectedServiceList.map((items) => {
                                            let tSlots = items.timeSlots ? items.timeSlots : [];
                                            let SlotsAllocated = "";
                                            tSlots.map(a => SlotsAllocated += a.startTime + ' - ' + a.endTime + ",");
                                            SlotsAllocated = SlotsAllocated.replace(/(^,)|(,$)/g, "");
                                            return (
                                                <GridListTile
                                                    style={{
                                                        height: 120,
                                                        width: 230,
                                                        padding: 10,
                                                        margin: 5,
                                                        boxShadow: "1px 1px 5px 1px #9E9E9E",
                                                        borderRadius: 10,
                                                    }} key={items.id} >
                                                    {/* <Checkbox
                                                checked={Addvalues.CheckedItems.includes(items.id)}
                                                onChange={(e) => { AssigningService(items.id) }}
                                                name="checkedB"
                                                color="primary"
                                                size="small"
                                            /> */}
                                                    <Typography>
                                                        Job Type: {capsFirstLetter(items.jobType)}
                                                    </Typography>
                                                    <Typography >
                                                        Customer: {items.customerName}
                                                    </Typography>
                                                    <Typography >
                                                        Service Date: {convertDateDMY(items.serviceDate)}
                                                    </Typography>
                                                    <Typography >
                                                        Status: {AssignedStatusType(Addvalues.CheckedItems.includes(items.id))}
                                                    </Typography>
                                                    <Typography >
                                                        Slots: {SlotsAllocated ? SlotsAllocated : "NA"}
                                                    </Typography>
                                                    <GridListTileBar
                                                        actionPosition={"right"}
                                                        titlePosition="top"
                                                        className={classes.titleBar}
                                                        actionIcon={
                                                            <IconButton
                                                                onClick={() => AssigningService(items.id, items.timeSlots, items.serviceDate, Addvalues.CheckedItems.includes(items.id))}
                                                                aria-label="Assign"
                                                                size="small"
                                                            >
                                                                {Addvalues.CheckedItems.includes(items.id) ?
                                                                    <CheckedIcon fontSize="small" color="primary" />
                                                                    :
                                                                    <UnCheckedIcon fontSize="small" color="primary" />
                                                                }

                                                            </IconButton>
                                                        }
                                                    />
                                                </GridListTile>
                                            )
                                        })}
                                    </GridList>
                                </Grid>

                            </Grid>
                           
                            <Grid item md={5} spacing={1} >
                                <Typography className={classes.boldText}>Existing Services</Typography>
                                <Grid item md={12} style={{ border: "1px solid grey", height: '100%' }}>
                                    <GridList padding={20} cols={2}
                                        style={{
                                            height: "250px",
                                            alignContent: 'flex-start',
                                            justifyContent: 'flex-start',
                                            marginLeft: 10
                                        }}>

                                        {TeamServicesList.map((items) => {
                                            let tSlots = items.timeSlots ? items.timeSlots : [];
                                            let SlotsAllocated = "";
                                            tSlots.map(a => SlotsAllocated += a.startTime + ' - ' + a.endTime + ",");
                                            SlotsAllocated = SlotsAllocated.replace(/(^,)|(,$)/g, "");
                                            return (
                                                <GridListTile
                                                    style={{
                                                        height: 120,
                                                        width: 230,
                                                        padding: 10,
                                                        margin: 5,
                                                        boxShadow: "1px 1px 5px 1px #9E9E9E",
                                                        borderRadius: 10,
                                                    }} key={items.id} >
                                                    <Typography>
                                                        Job Type: {capsFirstLetter(items.jobType)}
                                                    </Typography>
                                                    <Typography >
                                                        Customer: {items.customerName}
                                                    </Typography>
                                                    <Typography >
                                                        Service Date: {convertDateDMY(items.serviceDate)}
                                                    </Typography>
                                                    {/* <Typography >
                                                        Postal: {items.postalCode}
                                                    </Typography> */}
                                                    {/* <Typography >
                                            Fee: {PriceFormat(items.fee)}
                                        </Typography> */}
                                                    <Typography >
                                                        Slots: {SlotsAllocated ? SlotsAllocated : "Not Assigned"}
                                                    </Typography>
                                                    <Typography >
                                                        Status: {ServiceStatusType(items.serviceStatusId)}
                                                    </Typography>
                                                    {/* <Typography >
                                            Date: {DMY(items.serviceDate)}
                                        </Typography> */}
                                                    {/* <Divider /> */}
                                                </GridListTile>

                                            )
                                        })}
                                    </GridList>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={12} style={{ display: "flex", justifyContent: "flex-end" }} >
                        <Typography  >
                            Total Service : {totalTeamService}
                        </Typography>
                    </Grid>
                    <Buttons
                        close={() => props.ModelCancel()}
                        closeText={"Cancel"}
                        save={() => submitForm()}
                        saveText={"Submit"}
                    />
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

export default EditTeam;