import React, { useState, useEffect, Component } from 'react';
import {
    Grid, Dialog, GridList, DialogContent,
    GridListTile, DialogTitle, makeStyles,
    Typography, Divider, IconButton, DeleteIcon,
    EditIcon, _, swal, toast, useSelector, useDispatch,
    Card, CardMedia, CardContent, moment, Box, DialogActions
} from '../../utilities/PackagesList';
import {
    PageTitle, CustomToolbar, AlertDialog,
    CustomModel, InputField, Dropdown,
    Buttons, LoadingOverlay
} from "../../utilities/ComponentsList";
import { isEmptyObj, DDMY, dateTimetoDate, CDatePlusYMD, CheckBetween , CDateMinusYMD} from "../../utilities/AppFunctions";
import { AssignService, GetTeamService } from '../../api/Services';
import { getTeams, AddTeamLeaves } from './../../api/Team';
import { refreshReduxStates } from './../../api/Refresh';
import useStyles from "./../../utilities/Styles";
const AddLeaves = (props) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [Addvalues, setAddValues] = React.useState({
        TeamSelected: '',
        TeamSelectedID: ''
    });
    const [Createerrors, setCreateErrors] = useState({});
    useEffect(() => {
        dispatch(getTeams(1));
    }, [])
    const AddResponse = useSelector((state) => state.AddTeamLeavesReducer);
    const TeamsResponse = useSelector((state) => state.getTeamsReducer);
    const TeamListData = TeamsResponse.TeamList ? TeamsResponse.TeamList : [];    
    let ParentState = props.parentState;
    let AddingData = ParentState.AddingData;
    let LeaveDates = AddingData ? DDMY(AddingData.startStr)+" to "+DDMY(CDateMinusYMD(AddingData.endStr, 1)) : "";
    let ExistEvents = ParentState.initialEvents;
    let ExistTeams = [];
    if(AddingData){
        ExistEvents.forEach((entry) => {
            let startDate = CDateMinusYMD(entry.start,1);
            let EndDate = CDatePlusYMD((entry.end ? entry.end : entry.start), 1);
            let SelLeaveStart = ParentState.AddingData.startStr;
            let SelLeaveEnd = CDateMinusYMD(ParentState.AddingData.endStr, 1);
            if(CheckBetween(SelLeaveStart,startDate,EndDate) || CheckBetween(SelLeaveEnd,startDate,EndDate)){
                ExistTeams.push(entry.teamId)
            }
        });
    }
    if (AddResponse.Success && !isEmptyObj(AddResponse.AddingResponse)) {
        toast.success('Added Successfully!', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: false,
        });
        props.ModelConfirm()
      } else if (AddResponse.Failure && !isEmptyObj(AddResponse.AddingResponse)) {
        toast.error(AddResponse.AddingResponse.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: false,
        });
      }
    const enumerateDaysBetweenDates = (startDate, endDate) => {
        let date = []
        while(moment(startDate) < moment(endDate)){
          date.push(startDate);
          startDate = moment(startDate).add(1, 'days').format("YYYY-MM-DD");
        }
        return date;
      }
    const submitForm = () => {
        let srrorObj = {};
        let errorStatus = false;
        Object.keys(Addvalues).map(function (k) {
            srrorObj[k] = Addvalues[k] ? false : true;
            errorStatus = errorStatus ? true : !Addvalues[k];
        })
        setCreateErrors(srrorObj);
        let dateArr = enumerateDaysBetweenDates(ParentState.AddingData.startStr, ParentState.AddingData.endStr);
        let addingObject = {
            dates : dateArr
        };
         if (!errorStatus) {
            dispatch(AddTeamLeaves(Addvalues.TeamSelectedID,addingObject))
        }
    };

    return (
        <Dialog
            open={props.Status}
            //onClose={()=>this.closeDialog()}
            fullWidth={true}
            fullScreen={false}
            maxWidth="sm"
            //  className={classes.dialogWindow}
            disableBackdropClick={false}
            disableEscapeKeyDown={false}
        >
            <DialogTitle className={classes.dialogTitle} id="dialog-title">{props.Title}</DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={4}>
                    <Grid item md={12}>
                        <Dropdown
                            label={"Select Team"}
                            data={TeamListData}
                            getOptionLabel={option => (option.teamName ? option.teamName : "")}
                            getOptionSelected={(option, value) => option.id === value.id}
                            getOptionDisabled={(option) => ExistTeams.includes(option.id)}
                            value={Addvalues.Team}
                            ItemChange={(event, newValue) => {
                                setAddValues({
                                    ...Addvalues,
                                    TeamSelectedID: newValue ? newValue.id : null,
                                    TeamSelected: newValue ? newValue : null
                                });
                                setCreateErrors({
                                    ...Createerrors,
                                    TeamSelectedID: newValue ? false : true,
                                    TeamSelected: newValue ? false : true
                                })
                            }}
                            error={Createerrors.TeamSelectedID}
                            initialName={"Team"}
                            errorMsg={"Please choose team."}
                        />
                    </Grid>
                    <Grid item md={12} >
                        <Typography className={classes.boldText}>Leave Date(s) : {LeaveDates}</Typography>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Buttons
                    close={() => props.ModelCancel()}
                    closeText={"Cancel"}
                    save={() => submitForm()}
                    saveText={"Submit"}
                />
            </DialogActions>
        </Dialog>
    );
}

export default AddLeaves;