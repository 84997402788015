import React from "react";
import { TextField, makeStyles, InputAdornment, IconButton} from "../../utilities/PackagesList";
const useStyles = makeStyles(theme => ({
    forNumber: {
        "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
            margin: 0
          }
    },
    forNumber1: {
        "& input[type=number]": {
            "-moz-appearance":"textfield",
          }
    }
  }))
const InputFieldIcon = (props) => { 
    const classes = useStyles();
    return (
        <TextField
            required={props.required}
            className={[classes.forNumber,classes.forNumber1]} 
            size="small"
            multiline={props.multiline ? true : false}
            rows={props.multiline ? props.rows : 0}
            type={props.type}
            label={props.label}
            value={props.value}
            onChange={props.changeText}
            onKeyPress={props.KeyPress}
            variant="outlined"
            fullWidth={true}
            defaultValue={props.defaultText}
            color="primary"
            error={props.error}
            InputLabelProps={{
            shrink: true,
            }}
            InputProps={{
                maxLength: props.maxLength ? props.maxLength : null,
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={props.iconClick}
                      edge="end"
                    >
                      {props.Icon}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            placeholder={props.initialName}
            helperText={props.error ? props.errorMsg : ""}
            />
    )
}
export default InputFieldIcon;