import React from "react";
import { withStyles, IconButton, Tooltip, AddIcon, ScheduleIcon} from "./../../utilities/PackagesList";

const defaultToolbarStyles = {
  iconButton: {
  },
};

class CustomToolbar extends React.Component {
  
  handleClick = () => {
    this.props.iconOnPress();
  }

  render() {
    const { classes, title, icontype } = this.props;

    return (
      <React.Fragment>
        <Tooltip title={title}>
          <IconButton className={classes.iconButton} onClick={this.handleClick}>
          {icontype=='time' ? <ScheduleIcon className={classes.deleteIcon} /> : <AddIcon className={classes.deleteIcon} />}
            
          </IconButton>
        </Tooltip>
      </React.Fragment>
    );
  }

}

export default withStyles(defaultToolbarStyles, { name: "CustomToolbar" })(CustomToolbar);