import React from "react";
import {
    Grid, TextField, Typography, Checkbox, Radio
} from '../../utilities/PackagesList';
import {
    PageTitle, CustomToolbar, AlertDialog,
    CustomModel, InputField, Dropdown,
    Buttons, LoadingOverlay, PasswordField
} from "../../utilities/ComponentsList";
import { capsFirstLetter } from "../../utilities/AppFunctions";
import useStyles from "./../../utilities/Styles";

const getTitles = (props, cls) => {
    let rowLength = parseInt(props.length);
    const titleDefault = 4;
    let fieldWidth = rowLength > 1 ? parseInt(8 / (rowLength - 1)) : 12;
    return (
        <Grid container direction="row" spacing={4}>
            {props.map((data, index) => {
                let flexWidth = index == 0 ? titleDefault : fieldWidth;
                return (
                    <Grid item md={flexWidth}>
                        <Typography className={[cls.boldText7]}>{data}</Typography>
                    </Grid>
                )
            })
            }

        </Grid>
    )
}
const renderBooleanField = (type, fieldValue, fieldtype, changeFunction, SubChildIndex, ParentIndex, ChildIndex, cls) => {
    switch (type) {
        case 'checkbox':
            return (
                <Checkbox
                    checked={fieldValue}
                    onChange={() => { changeFunction(!fieldValue, ParentIndex, ChildIndex, SubChildIndex) }}
                    color="primary"
                />
            );
        case 'radio':
            return (
                <Radio
                    checked={fieldValue}
                    onClick={() => { changeFunction(!fieldValue, ParentIndex, ChildIndex, SubChildIndex) }}
                    color="primary"
                />
            );
        default:
            return null;
    }
}
const getSubFields = (type, fieldValue, fieldtype, changeFunction, SubChildIndex, ParentIndex, ChildIndex, cls) => {
    switch (fieldtype) {
        case 'string':
            return (
                <InputField
                    label=""
                    type={"text"}
                    //required={true}
                    value={fieldValue}
                    changeText={(event) => { changeFunction(event.target.value, ParentIndex, ChildIndex, SubChildIndex) }}
                    //error={!fieldValue}
                    initialName={""}
                    errorMsg={""}
                />
            );
        case 'boolean':
            return (
                <Grid >
                    {renderBooleanField(type, fieldValue, fieldtype, changeFunction, SubChildIndex, ParentIndex, ChildIndex, cls)}
                </Grid>
            );
        case 'numeric':
            return (
                <InputField
                    label=""
                    type={"number"}
                    //required={true}
                    value={fieldValue}
                    changeText={(event) => { changeFunction(event.target.value, ParentIndex, ChildIndex, SubChildIndex) }}
                    //error={!fieldValue}
                    initialName={""}
                    errorMsg={""}
                />
            );
        default:
            return null;
    }
}
const getFields = (singleItem, itemsLength, changeFunction, SubChildIndex, ParentIndex, ChildIndex, cls, HeadSupport = false) => {
    let rowLength = parseInt(itemsLength);
    const titleDefault = 4;
    let fieldWidth = rowLength > 1 ? parseInt(8 / (rowLength - 1)) : 12;
    fieldWidth = HeadSupport ? 4 : fieldWidth;
    
    switch (singleItem.field) {
        case 'label':
            return (
                <Grid item md={titleDefault} >
                    <Typography >{singleItem.title}</Typography>
                </Grid>
            );
        case 'text':
            return (
                <Grid item md={fieldWidth} >
                    <Typography >{singleItem.value}</Typography>
                </Grid>
            );
        case 'textinput':
            return (
                <Grid item md={fieldWidth} >
                    {getSubFields(singleItem.field, singleItem.value, singleItem.type, changeFunction, SubChildIndex, ParentIndex, ChildIndex, cls)}
                </Grid>
            );
        case 'checkbox':
            return (
                <Grid item md={fieldWidth} >
                    {getSubFields(singleItem.field, singleItem.value, singleItem.type, changeFunction, SubChildIndex, ParentIndex, ChildIndex, cls)}
                </Grid>
            );
        case 'radio':
            return (
                <Grid item md={fieldWidth} >
                    {getSubFields(singleItem.field, singleItem.value, singleItem.type, changeFunction, SubChildIndex, ParentIndex, ChildIndex, cls)}
                </Grid>
            );
        default:
            return null;
    }
}
const getHFields = (singleItem, itemsLength, changeFunction, SubChildIndex, ParentIndex, ChildIndex, cls, HeadSupport = false) => {
    let rowLength = parseInt(itemsLength);
    const titleDefault = 4;
    let fieldWidth = rowLength > 1 ? parseInt(8 / (rowLength - 1)) : 12;
    fieldWidth = HeadSupport ? 4 : fieldWidth;
    switch (singleItem.field) {
        case 'label':
            return (
                <Grid item md={titleDefault} >
                    <Typography className={[cls.boldText]}>{singleItem.title}</Typography>
                </Grid>
            );
        case 'textinput':
            return (
                <Grid item md={fieldWidth} >
                    {getSubFields(singleItem.field, singleItem.value, singleItem.type, changeFunction, SubChildIndex, ParentIndex, ChildIndex, cls)}
                </Grid>
            );
        case 'checkbox':
            return (
                <Grid item md={fieldWidth} >
                    {getSubFields(singleItem.field, singleItem.value, singleItem.type, changeFunction, SubChildIndex, ParentIndex, ChildIndex, cls)}
                </Grid>
            );
        case 'radio':
            return (
                <Grid item md={fieldWidth} >
                    {getSubFields(singleItem.field, singleItem.value, singleItem.type, changeFunction, SubChildIndex, ParentIndex, ChildIndex, cls)}
                </Grid>
            );
        default:

            return null;
    }
}
const HeaderRender = (parentProps, HeadProps, cls) => {
    return (
        <Grid item spacing={4}>
            {HeadProps.map((pData, pIndex) => {
                return (
                    <Grid container md={12} direction="row" spacing={4}>
                        {
                            pData.body.map((childdata, childindex) => {
                                let ChildIndex = childindex;
                                let childLength = childdata.items.length;
                                return (
                                    <Grid item md={6} >
                                        {childdata.items.map((subdata, subindex) => {
                                            let SubChildIndex = subindex;
                                            return (
                                                <Grid item md={12} >
                                                    {getHFields(subdata, childLength, parentProps.onDataChange, SubChildIndex, pIndex, ChildIndex, cls, true)}
                                                </Grid>
                                            )

                                        })
                                        }
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                )
            })
            }
        </Grid>
    )


}
const DynamicForm = (props) => {
    const classes = useStyles();
    let FormList = props.input;
    let formtypeSelected = props.Type;
    if (formtypeSelected && FormList.length > 0) {
        let Formindex = FormList.findIndex(x => x.formtype === formtypeSelected);
        let SelecFormData = FormList[Formindex];
        let inputData = SelecFormData;
        return (
            <Grid container spacing={4}>
                {/* <Grid item md={12} style={{marginBottom:"-4%"}}>
                    <Typography className={[classes.boldText1]}>{capsFirstLetter(formtypeSelected)} Form</Typography>
                </Grid> */}
                <Grid item md={12}>
                    {HeaderRender(props, inputData.header, classes)}
                </Grid>
                {
                    inputData.body.map((item, index) => {
                        let ParentIndex = index;
                        let bodyLength = item.body.length;
                        return (
                            <Grid item md={12} >

                                {index === 0 ? getTitles(item.title, classes) : null}
                                {item.body.map((childdata, childindex) => {
                                    let ChildIndex = childindex;
                                    let childLength = childdata.items.length;
                                    return (
                                        <Grid container md={12} direction="row" spacing={4}>
                                            {childdata.items.map((subdata, subindex) => {
                                                let SubChildIndex = subindex;
                                                return getFields(subdata, childLength, props.onDataChange, SubChildIndex, ParentIndex, ChildIndex, classes)
                                            })
                                            }
                                        </Grid>
                                    )
                                })
                                }
                            </Grid>
                        )
                    })
                }

            </Grid>
        );
    } else {
        return (
            <Grid />
        )
    }

}
export default DynamicForm;