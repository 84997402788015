import { returnApiCallPromise } from './ApiContent';
export function getVechiles() {
   
    let patameter = "vehicle";
    return (dispatch) => {
        dispatch({ type: 'FETCHING_VECHILES' });
        returnApiCallPromise("GET", patameter)
            .then(response => {     
                //console.log(response)          
                dispatch({
                    type: 'VECHILES_SUCCESS',
                    data: response.data.data
                })
            }).catch(error => {
                let errorMessage = error;
                if(error.response){
                    if(error.response.data.error){
                        errorMessage = error.response.data;
                    }
                }
                dispatch({
                    type: 'VECHILES_FAILURE',
                    data: errorMessage
                })
            })
    }
}
export function getVechileTypes() {
   
    let patameter = "vehicle-type";
    
    return (dispatch) => {
        dispatch({ type: 'FETCHING_VECHILE_TYPES' });
        returnApiCallPromise("GET", patameter)
            .then(response => {               
                dispatch({
                    type: 'VECHILE_TYPES_SUCCESS',
                    data: response.data.data
                })
            }).catch(error => {
                let errorMessage = error;
                if(error.response){
                    if(error.response.data.error){
                        errorMessage = error.response.data;
                    }
                }
                dispatch({
                    type: 'VECHILE_TYPES_FAILURE',
                    data: errorMessage
                })
            })
    }
}
export function getVechileSize() {
   
    let patameter = "vehicle-size";
    
    return (dispatch) => {
        dispatch({ type: 'FETCHING_VECHILE_SIZE' });
        returnApiCallPromise("GET", patameter)
            .then(response => {               
                dispatch({
                    type: 'VECHILE_SIZE_SUCCESS',
                    data: response.data.data
                })
            }).catch(error => {
                let errorMessage = error;
                if(error.response){
                    if(error.response.data.error){
                        errorMessage = error.response.data;
                    }
                }
                dispatch({
                    type: 'VECHILE_SIZE_FAILURE',
                    data: errorMessage
                })
            })
    }
}
export function UpdateVechile(AddObject, VechileID) {
   
    let subURL = "vehicle/"+VechileID;
    
    return (dispatch) => {
        returnApiCallPromise("PUT", subURL, AddObject)
            .then(response => { 
                if(response.data.error){
                    dispatch({
                        type: 'VECHILE_UPDATE_FAILURE',
                        data: response.data.message
                    })
                }else{
                    dispatch({
                        type: 'VECHILE_UPDATE_SUCCESS',
                        data: response.data
                    })
                } 
                /* dispatch({
                    type: 'VECHILE_UPDATE_SUCCESS',
                    data: response.data
                }) */
            }).catch(error => { 
                let errorMessage = error;
                if(error.response){
                    if(error.response.data.error){
                        errorMessage = error.response.data;
                    }
                }
                dispatch({
                    type: 'VECHILE_UPDATE_FAILURE',
                    data: errorMessage
                })
            })
    }
}
export function DeleteVechile(VechileID) {
   
    let subURL = "vehicle/"+VechileID;
    
    return (dispatch) => {
        returnApiCallPromise("DELETE", subURL, null)
            .then(response => { 
                if(response.data.error){
                    dispatch({
                        type: 'VECHILE_DELETE_FAILURE',
                        data: response.data.message
                    })
                }else{
                    dispatch({
                        type: 'VECHILE_DELETE_SUCCESS',
                        data: response.data
                    })
                } 
               /*  dispatch({
                    type: 'VECHILE_DELETE_SUCCESS',
                    data: response.data
                }) */
            }).catch(error => { 
                let errorMessage = error;
                if(error.response){
                    if(error.response.data.error){
                        errorMessage = error.response.data;
                    }
                }
                dispatch({
                    type: 'VECHILE_DELETE_FAILURE',
                    data: errorMessage
                })
            })
    }
}
export function AddVechile(AddObject) {
   
    let subURL = "vehicle/";
    return (dispatch) => {
        returnApiCallPromise("POST", subURL, AddObject)
            .then(response => { 
                if(response.data.error){
                    dispatch({
                        type: 'VECHILE_ADD_FAILURE',
                        data: response.data.message
                    })
                }else{
                    dispatch({
                        type: 'VECHILE_ADD_SUCCESS',
                        data: response.data
                    })
                } 
                /* dispatch({
                    type: 'VECHILE_ADD_SUCCESS',
                    data: response.data
                }) */
            }).catch(error => { 
                let errorMessage = error;
                if(error.response){
                    if(error.response.data.error){
                        errorMessage = error.response.data;
                    }
                }
                dispatch({
                    type: 'VECHILE_ADD_FAILURE',
                    data: errorMessage
                })
            })
    }
}