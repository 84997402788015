import React, { useState, useEffect, Component } from 'react';
import {
    Grid, Dialog, DialogActions, DialogContent,
    DialogContentText, DialogTitle, makeStyles,
    Switch, FormControlLabel, IconButton, DeleteIcon,
    EditIcon, _, swal, toast, useSelector, useDispatch
} from './../../utilities/PackagesList';
import {
    PageTitle, CustomToolbar, AlertDialog,
    CustomModel, InputField, Dropdown,
    Buttons, LoadingOverlay
} from "./../../utilities/ComponentsList";
import { isEmptyObj } from "./../../utilities/AppFunctions";
import { UpdateJobTypes } from './../../api/Services';
const StatusList = [
    {
        "id": 1,
        "name": 'Active',
        "type": true
    },
    {
        "id": 2,
        "name": 'InActive',
        "type": false
    }
]
const useStyles = makeStyles(theme => ({
    dialogTitle: {

    }
}))
const Edit = (props) => {
    const dispatch = useDispatch();
    const EditState = useSelector((state) => state.UpdateJobTypesReducer);
    const classes = useStyles();
    const [Editvalues, setEditValues] = useState({
        JobCode: '',
        JobType: ''
    });
    const [errors, setErrors] = useState({});
    let ParentState = props.parentState;
    let userSelection = ParentState.SelectedIndex;
    let EditingID = ParentState.EditID;
    let Lists = ParentState.SelectingList ? ParentState.SelectingList : [];
    Lists = _.filter(Lists, { 'jobTypeId': EditingID });
    let editDetails = Lists.length > 0 ? Lists[0] : null;
    useEffect(() => {
        if (EditingID) {
            const statusJob = editDetails.status ? StatusList[0] : StatusList[1];
            setEditValues({
                ...Editvalues,
                JobCode: editDetails.jobCode,
                JobType: editDetails.jobType,
                JobStatusID: statusJob.id,
                JobStatus: statusJob
            })
        }

    }, [EditingID])

    if (EditState.Success && !isEmptyObj(EditState.UpdateResponse)) {
        toast.success('Updated Successfully!', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: false,
        });
        let EditedObject = {
            "id": parseInt(EditingID),
            "jobCode": Editvalues.JobCode,
            "jobType": Editvalues.JobType,
            "status": Editvalues.JobStatus.type
        }
        props.ModelConfirm(EditedObject)
    } else if (EditState.Failure && !isEmptyObj(EditState.UpdateResponse)) {
        toast.error(EditState.UpdateResponse.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: false,
        });
    }
    const handleChange = name => event => {
        let targetValue = event.target.value;
        setEditValues({ ...Editvalues, [name]: targetValue });
        setErrors({ ...errors, [name]: targetValue ? false : true })
    };
    const saveEdit = () => {
        let srrorObj = {};
        let errorStatus = false;
        Object.keys(Editvalues).map(function (k) {
            srrorObj[k] = Editvalues[k] ? false : true;
            errorStatus = errorStatus ? true : !Editvalues[k];
        })

        setErrors(srrorObj);

        if (!errorStatus) {
            let updateObject = {
                jobCode: Editvalues.JobCode,
                jobType: Editvalues.JobType,
                status: Editvalues.JobStatus.type
            }

            dispatch(UpdateJobTypes(updateObject, EditingID));
        }
    };

    return (
        <Dialog
            open={props.Status}
            fullWidth
            maxWidth="sm"
            disableBackdropClick={false}
            disableEscapeKeyDown={false}
        >
            <DialogTitle className={classes.dialogTitle} id="dialog-title">{props.Title}</DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={4}>
                    <Grid item md={12}>
                        <Grid
                            container
                            spacing={4}
                            direction="column"
                        >
                            <Grid item md={12} spacing={4}>
                                <InputField
                                    label="Job Code"
                                    type={"text"}
                                    required={true}
                                    value={Editvalues.JobCode}
                                    changeText={handleChange('JobCode')}
                                    error={errors.JobCode}
                                    initialName={"Job Code"}
                                    errorMsg={"Please enter job code."}
                                />
                            </Grid>
                            <Grid item md={12} spacing={4}>
                                <InputField
                                    label="Job Type Name"
                                    type={"text"}
                                    required={true}
                                    value={Editvalues.JobType}
                                    changeText={handleChange('JobType')}
                                    error={errors.JobType}
                                    initialName={"Job Type Name"}
                                    errorMsg={"Please enter job type name."}
                                />
                            </Grid>
                            <Grid item md={12} spacing={4}>
                                <Dropdown
                                    label={"Select Status"}
                                    data={StatusList}
                                    getOptionLabel={option => (option.name ? option.name : "")}
                                    getOptionSelected={(option, value) => option.id === value.id}
                                    value={Editvalues.JobStatus}
                                    ItemChange={(event, newValue) => {
                                        setEditValues({
                                            ...Editvalues,
                                            JobStatusID: newValue ? newValue.id : null,
                                            JobStatus: newValue ? newValue : null
                                        })
                                    }}
                                    //error={Createerrors.TeamSelectedID}
                                    initialName={"Status"}
                                    errorMsg={"Please choose status."}
                                />
                            </Grid>
                        </Grid>

                    </Grid>
                    <Buttons
                        close={() => props.ModelCancel()}
                        closeText={"Cancel"}
                        save={() => saveEdit()}
                        saveText={"Save"}
                    />
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

export default Edit;