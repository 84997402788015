import React, { useState, useEffect, Component } from 'react';
import {
  Grid, Dialog, DialogActions, DialogContent,
  DialogContentText, DialogTitle, makeStyles,
  Switch, FormControlLabel, IconButton, DeleteIcon,
  EditIcon, _, swal, toast, useSelector, useDispatch,
  Checkbox
} from '../../utilities/PackagesList';
import {
  PageTitle, CustomToolbar, AlertDialog,
  CustomModel, InputField, Dropdown,
  Buttons, LoadingOverlay
} from "../../utilities/ComponentsList";
import { isEmptyObj , AddTime2Date, dateTimetoDate, AddTimeStamp2Date} from "../../utilities/AppFunctions";
import { UpdateService } from '../../api/Services';
import { refreshReduxStates } from '../../api/Refresh';
const useStyles = makeStyles(theme => ({
  dialogTitle: {

  }
}))
const EditService = (props) => {
  const dispatch = useDispatch();
  const UpdateState = useSelector((state) => state.UpdateServiceReducer);
  const classes = useStyles();
  const [Editvalues, setEditValues] = React.useState({
    JobType: '',
    JobTypeID: '',
    ServiceDate: '',
    Fee: '',
    Complementry: false
  });
  const [EditErrors, setEditErrors] = useState({});
  let ParentState = props.parentState;
  let IDSelected = ParentState.EditID;
  let IndexSelected = ParentState.SelectedIndex;
  let JobTypeList = ParentState.JobTypeList;
  let ServiceList =  _.filter(ParentState.SelectingList, { 'id': IDSelected });
  ServiceList = ServiceList.length>0 ? ServiceList[0] : [];
   
    useEffect(() => {
        if(IDSelected){
          let FilteredJobs = _.filter(JobTypeList, { jobTypeId: parseInt(ServiceList.jobTypeId) });
          let JobsAssigned = "";let JobAssignedID = "";
          if(FilteredJobs.length>0){
            JobsAssigned = FilteredJobs[0]; 
            JobAssignedID = FilteredJobs[0].jobTypeId;
          }
          setEditValues({
            JobType: JobsAssigned,
            JobTypeID: JobAssignedID,
            ServiceDate: dateTimetoDate(ServiceList.serviceDate),
            Fee: parseFloat(ServiceList.fee).toFixed(2),
            Complementry: ServiceList.complementary,
            Remarks: ServiceList.serviceRemarks,
          })
        } 
        
      }, [IDSelected])
 
  if (UpdateState.Success && !isEmptyObj(UpdateState.UpdateResponse)) {
    toast.success('Updated Successfully!', {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: false,
    });
    let Fees = Editvalues.Fee ? Editvalues.Fee : 0;
    let addedService = {
      JobType: Editvalues.JobType.jobType,
      jobTypeId: parseInt(Editvalues.JobTypeID),
      serviceDate: AddTimeStamp2Date(Editvalues.ServiceDate),
      fee: parseFloat(Fees).toFixed(2),
      complementary: Editvalues.Complementry,
      serviceRemarks : Editvalues.Remarks,
    }
    props.ModelConfirm(addedService);
  } else if (UpdateState.Failure && !isEmptyObj(UpdateState.UpdateResponse)) {
    toast.error(UpdateState.UpdateResponse.message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: false,
    });
    dispatch(refreshReduxStates());
  }
  const handleChange = name => event => {
    let targetValue = event.target.value;
    setEditValues({ ...Editvalues, [name]: targetValue });
    setEditErrors({ ...EditErrors, [name]: targetValue ? false : true })

  };

  const submitForm = () => {
    let srrorObj = {};
    let errorStatus = false;
    Object.keys(Editvalues).map(function (k) {
      if (k === 'Complementry') {
        srrorObj[k] = false;
        errorStatus = errorStatus ? true : false;
      } else if (k === 'Fee') {
        let compStatus = Editvalues.Complementry;
        let dataStatus = Editvalues[k] ? false : true;
        srrorObj[k] = Editvalues[k] ? false : (compStatus ? false : dataStatus);
        errorStatus = errorStatus ? true : (compStatus ? false : dataStatus);
      } else if(k==='Remarks'){
        srrorObj[k] = false;
        errorStatus = errorStatus ? true : false;
      }else {
        srrorObj[k] = Editvalues[k] ? false : true;
        errorStatus = errorStatus ? true : !Editvalues[k];
      }

    })
    setEditErrors(srrorObj);
    if (!errorStatus) {
      let Fees = Editvalues.Fee ? Editvalues.Fee : 0;
     /*  let addedService = {
        JobType: Editvalues.JobType.jobType,
        jobTypeId: Editvalues.JobTypeID,
        serviceDate: AddTime2Date(Editvalues.ServiceDate),
        fee: parseFloat(Fees).toFixed(2),
        complementary: Editvalues.Complementry
      } */
      var updateObject = new FormData();
      updateObject.append('jobTypeId', parseInt(Editvalues.JobTypeID));
      updateObject.append('serviceDate', AddTimeStamp2Date(Editvalues.ServiceDate));
      updateObject.append('fee', parseFloat(Fees).toFixed(2));
      updateObject.append('serviceRemarks', Editvalues.Remarks);
      updateObject.append('complementary', Editvalues.Complementry);
      
      console.log(JSON.stringify(updateObject))
     // props.ModelConfirm(addedService)
      dispatch(UpdateService(updateObject, IDSelected));
    }
  };

  return (
    <Dialog
      open={props.Status}
      //onClose={()=>this.closeDialog()}
      fullWidth
      maxWidth="sm"
      disableBackdropClick={false}
      disableEscapeKeyDown={false}
    >
      <DialogTitle className={classes.dialogTitle} id="dialog-title">{props.Title}</DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={4}>
          <Grid item md={12}>
            <Grid
              container
              spacing={4}
              direction="column"
              >
              <Grid
                item
                md={12}
                spacing={4}
              >
                <Dropdown
                  label={"Job Type"}
                  data={JobTypeList}
                  getOptionLabel={option => (option.jobType ? option.jobType : "")}
                  getOptionSelected={(option, value) => option.jobTypeId === value.jobTypeId}
                  value={Editvalues.JobType}
                  ItemChange={(event, newValue) => {
                    setEditValues({
                      ...Editvalues,
                      JobTypeID: newValue ? newValue.jobTypeId : null,
                      JobType: newValue ? newValue : null
                    });
                    setEditErrors({
                      ...EditErrors,
                      JobTypeID: newValue ? false : true,
                      JobType: newValue ? false : true
                    })
                  }}
                  error={EditErrors.JobTypeID}
                  initialName={"Job Type"}
                  errorMsg={"Please choose job type."}
                />
              </Grid>
              <Grid
                item
                md={12}
                spacing={4}
              >
                <InputField
                  label={"Select Date"}
                  type={"date"}
                  required={true}
                  value={Editvalues.ServiceDate}
                  changeText={handleChange('ServiceDate')}
                  error={EditErrors.ServiceDate}
                  initialName={"Select Date"}
                  errorMsg={"Please enter date."}
                />
              </Grid>
              <Grid
                item
                md={12}
                spacing={4}
              >
                <InputField
                  label={"Fee"}
                  type={"number"}
                  required={true}
                  disabled={Editvalues.Complementry}
                  value={Editvalues.Fee}
                  changeText={handleChange('Fee')}
                  error={!Editvalues.Complementry && EditErrors.Fee}
                  initialName={"Fee"}
                  errorMsg={"Please enter fee."}
                />
              </Grid>
              <Grid
                  item
                  md={12}
                  spacing={4}
                >
                <InputField
                    label={"Remarks"}
                    type={"text"}
                    multiline={true}
                    required={false}
                    maxLength={600}
                    rows={3}
                    value={Editvalues.Remarks}
                    changeText={handleChange('Remarks')}
                    error={EditErrors.Remarks}
                    initialName={"Remarks"}
                    errorMsg={"Please enter remarks."}
                  />
                  </Grid>
                  <Grid
                item
                md={12}
                spacing={6}
              >
                <FormControlLabel
                  control={<Checkbox checked={Editvalues.Complementry} onChange={(e) => {
                    let newStatus = !Editvalues.Complementry;
                    setEditValues({
                      ...Editvalues,
                      Complementry: newStatus,
                      Fee : newStatus ? 0 : Editvalues.Fee
                    });
                  }} name="Complementry" color="primary" />}
                  label="Complementry"
                />
                </Grid>
            </Grid>
          </Grid>
          <Buttons
            close={() => props.ModelCancel()}
            closeText={"Cancel"}
            save={() => submitForm()}
            saveText={"Update"}
          />
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default EditService;