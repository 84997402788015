import defaultTheme from "./default";
import { createMuiTheme } from "@material-ui/core";

const overrides = {
  
  typography: {  
    fontFamily: `"Poppins", sans-serif`,  
    h1: {
      fontSize: "2rem",
    },
    h2: {
      fontSize: "1.6rem",
    },
    h3: {
      fontSize: "1.4rem",
    },
    h4: {
      fontSize: "1.2rem",
    },
    h5: {
      fontSize: "1rem",
    },
    h6: {
      fontSize: "0.9rem",
      fontWeight:"600"
    },
    body1: {
      fontSize: "0.7rem",
    },
    body2: {
      fontSize: "0.7rem",
    },
    body3: {
      fontSize: "0.7rem",
      fontWeight:"600"
    },
  }
};

const themes = {
  default: createMuiTheme({ ...defaultTheme, ...overrides }),
};

export default themes;
