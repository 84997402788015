import React, { useState, useEffect, Component } from "react";
import {
  Grid, Button, connect, _, swal, toast,
  Table, TableBody, TableCell, TableHead,
  TableContainer, TableRow, Paper, withStyles,
  Typography, Tooltip
} from "./../../utilities/PackagesList";
import {
  PageTitle, CustomToolbar, AlertDialog,
  CustomModel, InputField, Dropdown,
  Buttons, LoadingOverlay
} from "./../../utilities/ComponentsList";
import { isEmptyObj, LastDayMonth, DDMY, DateMinusYMD, todayDate, DatePlusYMD } from "./../../utilities/AppFunctions";
import { GetTimeSlotReport } from './../../api/Services';
import { getTeams } from '../../api/Team';
import { refreshReduxStates } from './../../api/Refresh';
import { LoaderStatus } from './../../api/Loading';
import { TimeSummary1, TimeSummary } from "../../utilities/MockData";
import styles from "./../../utilities/StylesClass";
class TimeSlotReport extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      getCalls: [1, 1],
      TeamsLists: [],
      StartDate: todayDate(),
      EndDate: DatePlusYMD(7),
      TeamSelectedID: "",
      TeamSelected: "",
      TimeSlotSummary: []
    }
    let filterObj = {
      StartDate: todayDate(),
      EndDate: DatePlusYMD(7),
      TeamID: ""
    }
    this.props.GetTimeSlotReport(filterObj);
    props.getTeams(1);
  }

  UNSAFE_componentWillReceiveProps = (props) => {
    if (this.state.getCalls[0]) {
      if (props.TeamListSuccess) {
        let succed = this.state.getCalls;
        succed[0] = 0;
        let loaderStatus = succed.includes(1);
        let newTeam = {
          "id": 0,
          "teamName": "All",
          "vehicleNumber": "",
          "vehicelId": "",
          "assignedStaff": [],
          "teamTypeId": 0,
          "teamTypeName": "all"
        };
        let Teams = props.TeamLists;
        Teams.unshift(newTeam);
        this.setState({
          TeamsLists: props.TeamLists,
          isLoading: loaderStatus,
          TeamSelectedID: Teams[0].id,
          TeamSelected: Teams[0],
          getCalls: succed
        })
      } else if (props.TeamListFailure) {
        let succed = this.state.getCalls;
        succed[0] = 0;
        let loaderStatus = succed.includes(1);
        this.setState({
          TeamsLists: [],
          getCalls: succed,
          isLoading: loaderStatus
        })
      }
    }
    if (this.state.getCalls[1]) {
      if (props.TSlotReportSuccess) {
        let succed = this.state.getCalls;
        succed[1] = 0;
        let loaderStatus = succed.includes(1);
        this.setState({
          TimeSlotSummary: props.TSlotReportList,
          isLoading: loaderStatus,
          getCalls: succed
        })
      } else if (props.TSlotReportFailure) {
        let succed = this.state.getCalls;
        succed[1] = 0;
        let loaderStatus = succed.includes(1);
        this.setState({
          TimeSlotSummary: [],
          getCalls: succed,
          isLoading: loaderStatus
        })
      }
    }
  }

  searchClick = () => {
    if (!this.state.EndDate && !this.state.StartDate){
      toast.warning('Please choose date filter!', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: false,
      });
      return;
    }
    let dateStartPicked = this.state.StartDate ? this.state.StartDate : null;
    let dateEndPicked = this.state.EndDate ? this.state.EndDate : null;
    let FTeamID = this.state.TeamSelectedID ? this.state.TeamSelectedID : "";
    let filterObj = {
      StartDate: dateStartPicked,
      EndDate: dateEndPicked,
      TeamID: FTeamID
    }
    this.setState({
      isLoading: true,
      getCalls: [0, 1]
    }, () => {
      this.props.GetTimeSlotReport(filterObj);
    })
  }
  clearSearchClick = () => {
    this.setState({
      StartDate: todayDate(),
      EndDate: DatePlusYMD(7),
      TeamSelectedID: "",
      TeamSelected: "",
      TimeSlotSummary: []
    })

  }
  getcolors=(status)=>{
    const { classes } = this.props;
    if(status){
      return classes.complete;
    }else{
      return classes.open;
    }
    /*if(status=='completed'){
      return classes.complete;
    }else if(status=='progress' || status=='start'){
      return classes.progress;
    }else if(status==''){
      return classes.open; 
    }else{
      return classes.others;
    }*/
  }
  getstatus=(status)=>{
    if(status){
      return "Not Available"; 
    }else{
      return "Available";
    }
    /*if(status==''){
      return "Not Assigned";
    }else if(status=='inprogess' || status=='progress' || status=='start'){
      return "In Progress";
    }else if(status==''){
      return "Available"; 
    }else{
      return "";
    }*/
  }
  renderColumn = (colData) => {
    const { classes } = this.props;
    let teamRows = colData.slots;
    let searchDate = colData.date;
    return teamRows.map((row, index) => {
      return (
        <>
          <TableCell >
            {
              row.service.map((data, index) => {
                let colors = this.getcolors(data.isBooked);
                let tipStatus = this.getstatus(data.isBooked);
                return (
                  <Tooltip title={tipStatus} >
                  <div 
                  style={{ display: "flex", height: "10%", paddingBottom: "2%", paddingTop: "2%", alignItems: 'center', margin: "3%", justifyContent: 'center' }} 
                  className={[colors]}>
                   
                    <TableRow style={{ height: "100%", }}>
                      <Typography align="center" className={[classes.whiteText, classes.boldText]}>{data.timeslot}</Typography>
                    </TableRow>
                   
                  </div>
                   </Tooltip>
                )
              })
            }
          </TableCell>
        </>
      )
    })

  }
  renderTable = () => {
    const { classes } = this.props;
    return (
      <TableContainer  component={Paper} className={[classes.paper]}>
        <Table aria-label="simple table" stickyHeader >
          <TableBody>
            {this.state.TimeSlotSummary.length>0 ?
            <>
            <TableRow >
              <TableCell style={{minWidth: 100}}>
                <Typography align="center" className={[classes.boldText]}>Teams</Typography>
              </TableCell>
              {
                this.state.TimeSlotSummary[0].slots.map((row, index) => {
                  let searchDate = row.date;
                  return (
                    <TableCell style={{minWidth: 150}}>
                      <Typography align="center" className={[classes.boldText]}>{DDMY(searchDate)}</Typography>
                    </TableCell>
                  )

                })
              }
            </TableRow>
            {this.state.TimeSlotSummary.map((row, index) => {
              return (
                <TableRow >
                  <TableCell >
                  <Typography align="center" className={[classes.boldText]}>{row.teamName}</Typography>
                  </TableCell>
                  {this.renderColumn(row)}
                </TableRow>
              )
            })
            }
            </>
            : 
            null
            }
            

          </TableBody>
        </Table>
      </TableContainer>
    )
  }
  render() {
    const { classes } = this.props;
    return (
      <>
        <PageTitle title="Time Slot Summary" />
        <Grid container spacing={4}>
          <Grid item md={12}>
            <Grid container spacing={4} md={12} direction={"row"} >
              <Grid item md={2} >
                <InputField
                  label={"Start Date"}
                  type={"date"}
                  required={true}
                  value={this.state.StartDate}
                  changeText={(data) => {
                    this.setState({
                      StartDate: data ? data.target.value : null,
                      EndDate: data ? LastDayMonth(data.target.value) : null
                    })
                  }}
                  // error={Createerrors.Date}
                  errorMsg={"Please enter date."}
                />
              </Grid>
              <Grid item md={2} >
                <InputField
                  label={"End Date"}
                  type={"date"}
                  required={true}
                  value={this.state.EndDate}
                  changeText={(data) => { this.setState({ EndDate: data ? data.target.value : null }) }}
                  // error={Createerrors.Date}
                  errorMsg={"Please enter date."}
                />
              </Grid>
              <Grid item md={2} >
                <Dropdown
                  label={"Select Team"}
                  data={this.state.TeamsLists}
                  getOptionLabel={option => (option.teamName ? option.teamName : "")}
                  getOptionSelected={(option, value) => option.id === value.id}
                  value={this.state.TeamSelected}
                  ItemChange={(event, newValue) => {
                    this.setState({
                      TeamSelectedID: newValue ? newValue.id : null,
                      TeamSelected: newValue ? newValue : null
                    })
                  }}
                  //error={Createerrors.TeamSelectedID}
                  initialName={"Team"}
                  errorMsg={"Please choose team."}
                />
              </Grid>
              <Grid item md={1} >
                <Button
                  onClick={() => this.clearSearchClick()}
                  className={classes.buttonCancel}
                  variant="contained" color="primary">
                  Clear
                  </Button>
              </Grid>
              <Grid item md={1} >
                <Button
                  onClick={() => this.searchClick()}
                  className={classes.buttonSuccess}
                  variant="contained" color="primary">
                  Search
                  </Button>
              </Grid>
            </Grid>
            <Grid item spacing={4} xs={12} style={{ marginTop: 30 }}>
              {this.renderTable()}
            </Grid>

          </Grid>
          <LoadingOverlay Status={this.state.isLoading} />
        </Grid>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    TeamListSuccess: state.getTeamsReducer.Success,
    TeamListFailure: state.getTeamsReducer.Failure,
    TeamLists: state.getTeamsReducer.TeamList,

    TSlotReportSuccess: state.GetTimeSlotReportReducer.Success,
    TSlotReportFailure: state.GetTimeSlotReportReducer.Failure,
    TSlotReportList: state.GetTimeSlotReportReducer.TSlotReport,

    UserDetails: state.LoginReducer.UserDetails,
    LoaderState: state.LoaderReducer.State
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetTimeSlotReport: (filterObj) => dispatch(GetTimeSlotReport(filterObj)),
    getTeams: (ID) => dispatch(getTeams(ID)),
    refreshReduxStates: () => dispatch(refreshReduxStates()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TimeSlotReport));
