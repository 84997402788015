import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, CircularProgress, DialogTitle, makeStyles} from './../../utilities/PackagesList';
import useStyles from "./styles";

export default function CustomModel(props) {
  const classes = useStyles();
  return(    
      <Dialog
        open={props.Status}
        fullScreen={true}
        fullWidth={true}
        PaperProps={{
          style: {
            backgroundColor: 'rgba(0,0,0,0.3)',
            boxShadow: 'none',
          },
        }}
        maxWidth="sm"
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
      >
        <DialogContent className={classes.dialogContent}>
        <CircularProgress color="secondary" size={30} className={classes.loginLoader} />
        </DialogContent>
      </Dialog>
  );
}