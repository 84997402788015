import React, { useState, useEffect, Component } from "react";
import {
  Grid, Tab, AppBar, TabContext, TabList, connect,
  _, swal, toast, Typography, Button, withStyles
} from "../../utilities/PackagesList";
import {
  PageTitle, CustomToolbar, AlertDialog,
  CustomModel, InputField, Dropdown,
  Buttons, LoadingOverlay, ButtonIcon,
  DynamicForm, PDF 
} from "../../utilities/ComponentsList";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import { VechileItems } from "../../utilities/MockData";
import { isEmptyObj, capsFirstLetter, UnChangeObj } from "../../utilities/AppFunctions";
import { GetServiceForms } from '../../api/Services';
import { refreshReduxStates } from '../../api/Refresh';
import styles from "../../utilities/StylesClass";
import Create from "./Create";
import Edit from "./Edit";
import FormTable from "./FormTable";
import html2canvas from "html2canvas";
class Forms extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      AddState: false,
      EditState: false,
      SFormData: [],
      getCalls: [1],
      FormTypeChosen: "1",
      SelectedForm: "repair",
      SelectingItem:[]
    }
    
    props.GetServiceForms();
  }
  UNSAFE_componentWillReceiveProps = (props) => {
    if (this.state.getCalls[0]) {
      if (props.SFormSuccess) {
        let succed = this.state.getCalls;
        succed[0] = 0;
        let loaderStatus = succed.includes(1);
        // console.log(JSON.stringify(props.SFormList))
        let GetForms = props.SFormList.length > 0 ? props.SFormList[0].metadata : [];
        let GetFormsType = props.SFormList.length > 0 ? 1 : "";
        this.setState({
          SFormData: GetForms,
          FormTypeChosen: GetFormsType,
          isLoading: loaderStatus,
          getCalls: succed
        })
      } else if (props.SFormFailure) {
        let succed = this.state.getCalls;
        succed[0] = 0;
        let loaderStatus = succed.includes(1);
        this.setState({
          SFormData: [],
          getCalls: succed,
          isLoading: loaderStatus
        })
      }
    }
  }
  AddedFItem = () => {
    this.setState({
      AddState: false,
      SelectingItem:[]
    })
  }
  CloseAddFItem = () => {
    this.setState({
      AddState: false,
      SelectingItem:[]
    })
  }
  EditedFItem = () => {
    this.setState({
      EditState: false,
      SelectingItem:[]
    })
  }
  CloseEditFItem = () => {
    this.setState({
      EditState: false,
      SelectingItem:[]
    })
  }
  AddFormItems = () =>{
    let FormDatas = UnChangeObj(this.state.SFormData);
    this.setState({
      AddState: true,
      SelectingItem: FormDatas
    })
  }
  formItemChange = (inpData, ParentIndex, ChildIndex, SubChildIndex) => {

    let FormType = this.state.SelectedForm;
    let selectedFormItem = this.state.SFormData;
    let index = selectedFormItem.findIndex(x => x.formtype === FormType);
    selectedFormItem[index].body[ParentIndex].body[ChildIndex].items[SubChildIndex].value = inpData;
    this.setState({
      SFormData: selectedFormItem
    })
  }
  FormTypeChange = (e, val) => {
    let selectedFormItem = UnChangeObj(this.state.SFormData);
    //alert(selectedFormItem[parseInt(val)-1].formtype)
    this.setState({
      FormTypeChosen: val,
      SelectedForm : selectedFormItem[parseInt(val)-1].formtype
    })
  }
 
  render() {
    const { classes } = this.props;
    return (
      <>
        <Grid container spacing={4}>
        <Grid item lg={12} style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              onClick={() => this.AddFormItems()}
              className={classes.buttonSuccess}
              variant="contained" color="primary">
              Add Form / Items
          </Button>
          </Grid>
          <Grid item xs={12}>
            <TabContext value={this.state.FormTypeChosen}>
              <AppBar position="static" color="default">
                <TabList onChange={(event, newValue) => this.FormTypeChange(event, newValue)} aria-label="">
                  {
                    this.state.SFormData.map((item, index) => {
                      //formtype
                      return (
                        <Tab label={capsFirstLetter(item.formtype)} value={index + 1} />
                      )
                    })
                  }
                </TabList>
              </AppBar>
              <Grid item md={12} style={{ marginTop: 20 }}>
               <DynamicForm
                  input={this.state.SFormData}
                  Type={this.state.SelectedForm}
                  onDataChange={(inpData, ParentIndex, ChildIndex, SubChildIndex, type) => this.formItemChange(inpData, ParentIndex, ChildIndex, SubChildIndex, type)}
                /> 
              </Grid>
            </TabContext>
          </Grid>
          {this.state.AddState ? <Create
            Title={"Add Form Item"}
            parentClass={this}
            parentState={this.state}
            Status={this.state.AddState}
            ModelConfirm={this.AddedFItem}
            ModelCancel={this.CloseAddFItem}
          /> : null}
          {this.state.EditState ? <Edit
            Title={"Edit Form Item"}
            parentClass={this}
            parentState={this.state}
            Status={this.state.EditState}
            ModelConfirm={this.EditedFItem}
            ModelCancel={this.CloseEditFItem}
          /> : null}
          <LoadingOverlay Status={this.state.isLoading} />
        </Grid>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    SFormSuccess: state.GetServiceFormsReducer.Success,
    SFormFailure: state.GetServiceFormsReducer.Failure,
    SFormList: state.GetServiceFormsReducer.SForm,

    UserDetails: state.LoginReducer.UserDetails,
    LoaderState: state.LoaderReducer.State
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetServiceForms: () => dispatch(GetServiceForms()),
    refreshReduxStates: () => dispatch(refreshReduxStates()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Forms));
