const DeliveryList = [
    {ID:1, Type:"Truck", Name:"Six Wheeler", Number:"CAT 45 6677", Staff:"Peter", Size:"20ft", Status:true},
    {ID:2, Type:"Car", Name:"Four Wheeler", Number:"CAT 46 6678", Staff:"Raj", Size:"15ft", Status:true},
    {ID:3, Type:"Bike", Name:"Two Wheeler", Number:"CAT 47 6679", Staff:"John", Size:"10ft", Status:true}
  ];
const VechileItems = [
    {TypeID:1, Type:"Truck", Name:"Six Wheeler", Number:"CAT 45 6677", Size:"20ft", Status:true},
    {TypeID:2, Type:"Car", Name:"Four Wheeler", Number:"CAT 46 6678", Size:"15ft", Status:true},
    {TypeID:3, Type:"Bike", Name:"Two Wheeler", Number:"CAT 47 6679", Size:"10ft", Status:true}
  ];
const MockDatas = [
    {ID:1, Type:"Truck", Name:"Formal Truck", Number:"CAT 45 6677", Staff:"Peter", Size:"20ft", Status:true},
    {ID:2, Type:"Car", Name:"Four Wheeler", Number:"CAT 46 6678", Staff:"Raj", Size:"15ft", Status:true},
    {ID:3, Type:"Bike", Name:"Two Wheeler", Number:"CAT 47 6679", Staff:"John", Size:"10ft", Status:true},
    {ID:4, Type:"Bike", Name:"Two Wheeler", Number:"CAT 47 6679", Staff:"John", Size:"10ft", Status:true},
    {ID:5, Type:"Bike", Name:"Two Wheeler", Number:"CAT 47 6679", Staff:"John", Size:"10ft", Status:true},
    {ID:6, Type:"Bike", Name:"Two Wheeler", Number:"CAT 47 6679", Staff:"John", Size:"10ft", Status:true},
    {ID:7, Type:"Bike", Name:"Two Wheeler", Number:"CAT 47 6679", Staff:"John", Size:"10ft", Status:true},
    {ID:8, Type:"Bike", Name:"Two Wheeler", Number:"CAT 47 6679", Staff:"John", Size:"10ft", Status:true},
    {ID:9, Type:"Bike", Name:"Two Wheeler", Number:"CAT 47 6679", Staff:"John", Size:"10ft", Status:true},
    {ID:10, Type:"Bike", Name:"Two Wheeler", Number:"CAT 47 6679", Staff:"John", Size:"10ft", Status:true},
    {ID:11, Type:"Bike", Name:"Two Wheeler", Number:"CAT 47 6679", Staff:"John", Size:"10ft", Status:true},
    {ID:12, Type:"Bike", Name:"Two Wheeler", Number:"CAT 47 6679", Staff:"John", Size:"10ft", Status:true},
    {ID:13, Type:"Bike", Name:"Bike Truck", Number:"CAT 47 6679", Staff:"John", Size:"10ft", Status:true}
  ];
const EmployeeData = [
    {empID:1, Fname:"Joe James", Lname:"Example Inc.", Email:"Yonkers", Phone:"NY", Type:"NY", Status:true},
    {empID:2, Fname:"John Walsh", Lname:"Example Inc.", Email:"Hartford", Phone:"CT", Type:"NY", Status:true},
  ];
const FormFields = [
    {id:1, name:"label", type:"Label"},
    {id:2, name:"textfield", type:"Text Input"},
    {id:3, name:"numeric", type:"Numeric Input"},
    {id:4, name:"radio", type:"Radio Button"},
    {id:5, name:"checkbox", type:"Checkbox"}
  ];
  const collectionReport = [
    {
       "serviceID":1,
       "serviceDate":"2021-07-16",
       "completedDate":"2021-07-16",
       "customerName":"xxx",
       "customerPhone":"123456",
       "jobType":"jobtypename",
       "jobTypeCode":"jobtypecode",
       "paymentCollection":"100.00",
       "paymentMode":"NETS",
       "teamId":44,
       "teamName":"MM Team"
    },
    {
       "serviceID":2,
       "serviceDate":"2021-07-16",
       "customerName":"yyy",
       "customerPhone":"2222",
       "jobType":"jobtypename",
       "jobTypeCode":"jobtypecode",
       "paymentCollection":"50.0",
       "paymentMode":"NETS",
       "teamId":44,
       "teamName":"MM Team"
    }
 ];
 const jobtypeReport = [
  {
     "jobTypeCode":"FT",
     "jobTypeName":"FT-service",
     "totalAssigned":10,
     "completed":"8",
     "cancelled":2
  },
  {
     "jobTypeCode":"FT1",
     "jobTypeName":"FT1-service",
     "totalAssigned":25,
     "completed":25,
     "cancelled":0
  }
];
const paymentReport = [
  {
     "serviceID":1,
     "serviceDate":"2021-07-16",
     "completedDate":"2021-07-16",
     "customerName":"xxx",
     "customerAddress":"xxx",
     "customerEmail":"xxx",
     "customerPhone":"123456",
     "jobType":"jobtypename",
     "jobTypeCode":"jobtypecode",
     "technicianName":"ssss",
     "paymentCollection":"100.00",
     "paymentCollected":"50.00",
     "paymentMode":"NETS",
     "paymentType":"Partially Paid",
     "teamId":44,
     "teamName":"MM Team"
  },
  {
     "serviceID":2,
     "serviceDate":"2021-07-16",
     "customerName":"yyy",
     "customerPhone":"2222",
     "jobType":"jobtypename",
     "jobTypeCode":"jobtypecode",
     "paymentCollection":"50.0",
     "paymentMode":"NETS",
     "teamId":44,
     "teamName":"MM Team"
  }
];
const TimeSummary = [
  {
      "teamName": "MM Team",
      "slots": [
          {
              "date": "2021-06-10",
              "service": [
                  {
                      "id": 2,
                      "timeslot": "12:00-14:00",
                      "isBooked": false,
                      "serviceStatus": ""
                  },
                  {
                      "id": 3,
                      "timeslot": "15:20-17:20",
                      "isBooked": true,
                      "serviceStatus": "progress"
                  },
                  {
                      "id": 4,
                      "timeslot": "18:00-20:30",
                      "isBooked": true,
                      "serviceStatus": "progress"
                  },
                  {
                      "id": 1,
                      "timeslot": "11:00-11:30",
                      "isBooked": true,
                      "serviceStatus": "start"
                  },
                  {
                      "id": 6,
                      "timeslot": "01:10-03:00",
                      "isBooked": false,
                      "serviceStatus": ""
                  }
              ]
          },
          {
              "date": "2021-06-12",
              "service": [
                  {
                      "id": 2,
                      "timeslot": "12:00-14:00",
                      "isBooked": true,
                      "serviceStatus": "progress"
                  },
                  {
                      "id": 3,
                      "timeslot": "15:20-17:20",
                      "isBooked": false,
                      "serviceStatus": ""
                  },
                  {
                      "id": 4,
                      "timeslot": "18:00-20:30",
                      "isBooked": true,
                      "serviceStatus": "progress"
                  },
                  {
                      "id": 1,
                      "timeslot": "11:00-11:30",
                      "isBooked": false,
                      "serviceStatus": ""
                  },
                  {
                      "id": 6,
                      "timeslot": "01:10-03:00",
                      "isBooked": false,
                      "serviceStatus": ""
                  }
              ]
          }
      ]
  },
  {
      "teamName": "Test Team",
      "slots": [
          {
              "date": "2021-06-11",
              "service": [
                  {
                      "id": 2,
                      "timeslot": "12:00-14:00",
                      "isBooked": false,
                      "serviceStatus": ""
                  },
                  {
                      "id": 3,
                      "timeslot": "15:20-17:20",
                      "isBooked": true,
                      "serviceStatus": "completed"
                  },
                  {
                      "id": 4,
                      "timeslot": "18:00-20:30",
                      "isBooked": false,
                      "serviceStatus": ""
                  },
                  {
                      "id": 1,
                      "timeslot": "11:00-11:30",
                      "isBooked": true,
                      "serviceStatus": "start"
                  },
                  {
                      "id": 6,
                      "timeslot": "01:10-03:00",
                      "isBooked": false,
                      "serviceStatus": ""
                  }
              ]
          }
      ]
  }
];
  const TimeSummary1 = [
    {
      "teamName":"Team 1",      
      "slots" : [
        {
          "date" : "2021-01-01",
          "service" : [
            {
              "timeslot" : "9:00 - 10:00",
              "isBooked" : true,
              "serviceStatus" : "completed" 
            },
            {
              "timeslot" : "10:00 - 11:00",
              "isBooked" : false,
              "serviceStatus" : "" 
            }
          ]
        }
      ]
    },
    {
      "teamName":"Team 1",      
      "slots" : [
        {
          "date" : "2021-01-01",
          "service" : [
            {
              "timeslot" : "9:00 - 10:00",
              "isBooked" : true,
              "serviceStatus" : "completed" 
            },
            {
              "timeslot" : "10:00 - 11:00",
              "isBooked" : false,
              "serviceStatus" : "" 
            }
          ]
        }
      ]
    },
    {
      "teamName":"Team 1",      
      "slots" : [
        {
          "date" : "2021-01-01",
          "service" : [
            {
              "timeslot" : "9:00 - 10:00",
              "isBooked" : true,
              "serviceStatus" : "completed" 
            },
            {
              "timeslot" : "10:00 - 11:00",
              "isBooked" : false,
              "serviceStatus" : "" 
            }
          ]
        }
      ]
    },
    {
      "teamName":"Team 1",      
      "slots" : [
        {
          "date" : "2021-01-01",
          "service" : [
            {
              "timeslot" : "9:00 - 10:00",
              "isBooked" : true,
              "serviceStatus" : "completed" 
            },
            {
              "timeslot" : "10:00 - 11:00",
              "isBooked" : false,
              "serviceStatus" : "" 
            }
          ]
        }
      ]
    },
    {
      "teamName":"Team 1",      
      "slots" : [
        {
          "date" : "2021-01-01",
          "service" : [
            {
              "timeslot" : "9:00 - 10:00",
              "isBooked" : true,
              "serviceStatus" : "completed" 
            },
            {
              "timeslot" : "10:00 - 11:00",
              "isBooked" : false,
              "serviceStatus" : "" 
            }
          ]
        }
      ]
    },
    {
      "teamName":"Team 1",      
      "slots" : [
        {
          "date" : "2021-01-01",
          "service" : [
            {
              "timeslot" : "9:00 - 10:00",
              "isBooked" : true,
              "serviceStatus" : "completed" 
            },
            {
              "timeslot" : "10:00 - 11:00",
              "isBooked" : false,
              "serviceStatus" : "" 
            }
          ]
        }
      ]
    },
    {
      "teamName":"Team 1",      
      "slots" : [
        {
          "date" : "2021-01-01",
          "service" : [
            {
              "timeslot" : "9:00 - 10:00",
              "isBooked" : true,
              "serviceStatus" : "completed" 
            },
            {
              "timeslot" : "10:00 - 11:00",
              "isBooked" : false,
              "serviceStatus" : "" 
            }
          ]
        }
      ]
    },
    {
      "teamName":"Team 1",      
      "slots" : [
        {
          "date" : "2021-01-01",
          "service" : [
            {
              "timeslot" : "9:00 - 10:00",
              "isBooked" : true,
              "serviceStatus" : "completed" 
            },
            {
              "timeslot" : "10:00 - 11:00",
              "isBooked" : false,
              "serviceStatus" : "" 
            }
          ]
        }
      ]
    },
    {
      "teamName":"Team 1",      
      "slots" : [
        {
          "date" : "2021-01-01",
          "service" : [
            {
              "timeslot" : "9:00 - 10:00",
              "isBooked" : true,
              "serviceStatus" : "completed" 
            },
            {
              "timeslot" : "10:00 - 11:00",
              "isBooked" : false,
              "serviceStatus" : "" 
            }
          ]
        }
      ]
    },
    {
      "teamName":"Team 1",      
      "slots" : [
        {
          "date" : "2021-01-01",
          "service" : [
            {
              "timeslot" : "9:00 - 10:00",
              "isBooked" : true,
              "serviceStatus" : "completed" 
            },
            {
              "timeslot" : "10:00 - 11:00",
              "isBooked" : false,
              "serviceStatus" : "" 
            }
          ]
        }
      ]
    }
  ];
export{
    DeliveryList,
    MockDatas,
    EmployeeData,
    VechileItems,
    TimeSummary,
    TimeSummary1,
    FormFields,
    collectionReport,
    jobtypeReport,
    paymentReport
}