import React from "react";
import { ScheduleIcon, Grid, Tooltip, IconButton, DeleteIcon, EditIcon, VisibilityIcon, AddCheckIcon, RestoreIcon, CancelIcon } from "../../utilities/PackagesList";
import useStyles from "../../utilities/Styles";
const ButtonIcon = (props) => {
    const classes = useStyles();
    return (
        <Grid item md={12} >
            {props.ScheduleTitle ?
                <Tooltip title={props.ScheduleTitle} className={classes.toolTipSpace}>
                    <IconButton
                        onClick={props.Schedule}
                        aria-label="edit"
                        size="small"
                        color="primary">
                        <ScheduleIcon fontSize="small" />
                    </IconButton>
                </Tooltip> : null}
            {props.EditTitle ?
                <Tooltip title={props.EditTitle} className={classes.toolTipSpace}>
                    <IconButton
                        onClick={props.Edit}
                        aria-label="edit"
                        size="small"
                        color="primary">
                        <EditIcon fontSize="small" />
                    </IconButton>
                </Tooltip> : null}

            {props.EditTitle1 ?
                <Tooltip title={props.EditTitle1} className={classes.toolTipSpace}>
                    <IconButton
                        onClick={props.Edit1}
                        aria-label="edit"
                        size="small"
                        color="primary">
                        <EditIcon fontSize="small" />
                    </IconButton>
                </Tooltip> : null}
            {props.RestoreTitle ?
                <Tooltip title={props.RestoreTitle} className={classes.toolTipSpace}>
                    <IconButton
                        onClick={props.Restore}
                        aria-label="restore"
                        size="small"
                        color="primary">
                        <RestoreIcon fontSize="small" />
                    </IconButton>
                </Tooltip> : null}
            {props.EditDateTitle ?
                <Tooltip title={props.EditDateTitle} className={classes.toolTipSpace}>
                    <IconButton
                        onClick={props.EditDate}
                        aria-label="edit"
                        size="small"
                        color="primary">
                        <AddCheckIcon fontSize="small" />
                    </IconButton>
                </Tooltip> : null}
            {props.CancelTitle ?
                <Tooltip title={props.CancelTitle} className={classes.toolTipSpace}>
                    <IconButton
                        onClick={props.Cancel}
                        aria-label="cancel"
                        size="small"
                        color="secondary">
                        {<CancelIcon fontSize="small" />}

                    </IconButton>
                </Tooltip>
                : null}
            {props.DeleteTitle ?
                <Tooltip title={props.DeleteTitle} className={classes.toolTipSpace}>
                    <IconButton
                        onClick={props.Delete}
                        aria-label="delete"
                        size="small"
                        color="secondary">
                        {props.MultiEdit ? <EditIcon fontSize="small" /> : <DeleteIcon fontSize="small" />}

                    </IconButton>
                </Tooltip>
                : null}
            {props.ViewTitle ?
                <Tooltip title={props.ViewTitle}>
                    <IconButton
                        onClick={props.View}
                        aria-label="view"
                        size="small"
                        color="primary">
                        <VisibilityIcon fontSize="small" />

                    </IconButton>
                </Tooltip>
                : null}

        </Grid>
    )
}
export default ButtonIcon;