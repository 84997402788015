import React, { useState, useEffect, Component } from 'react';
import {
    Grid, Dialog, GridList, DialogContent,
    GridListTile, DialogTitle, makeStyles,
    Typography, Divider, IconButton, DeleteIcon,
    EditIcon, _, swal, toast, useSelector, useDispatch,
    Card, CardMedia, CardContent, CardActions, Box, Button,
    List, ListItem, ListItemIcon, ListItemText, Paper,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    MUIDataTable, Radio, RadioGroup, FormControl, FormLabel, FormControlLabel, DialogActions
} from '../../utilities/PackagesList';
import {
    PageTitle, CustomToolbar, AlertDialog,
    CustomModel, InputField, Dropdown,
    Buttons, LoadingOverlay
} from "../../utilities/ComponentsList";
import { getTeamOrder, AdhocScheduleDelivery } from '../../api/Adhoc';
import { isEmptyObj, DMY, AMPM, convertDateDMY, DecimalFormat} from "../../utilities/AppFunctions";
const useStyles = makeStyles(theme => ({
    dialogTitle: {

    },
    dialogWindow: {
        width: '80%'
    },
    root: {
        width: '100%',
        overflow: 'auto',
        maxHeight: 300,
    },
    cardStyle: {
        height: 90,
        padding: 10,
        margin: 10,
        boxShadow: "none",
        borderRadius: 10,
        backgroundColor: '#000'

    },
    tableRightBorder: {
        borderWidth: 0,
        borderRightWidth: 1,
        borderColor: 'black',
        borderStyle: 'solid',
    },
    buttonCancel: {
        backgroundColor: theme.palette.btns.default,
        opacity: 0.9,
        borderRadius: 30,
        '&:hover': {
            backgroundColor: theme.palette.btns.gray
        }
    }
    
}))
const ViewList = (props) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [Addvalues, setAddValues] = React.useState({
        ProductType: "exist"
    });
    const handleChange = name => event => {
        let targetValue = event.target.value;
        setAddValues({ ...Addvalues, [name]: targetValue });

    };
    const ParentState = props.parentState;
    const ProductList = ParentState.ProductList ? ParentState.ProductList : [];    
    const SeleStatus = ParentState.SelecitalStatus;
    useEffect(() => {
        if (SeleStatus) {
            setAddValues({ ...Addvalues, ProductType: SeleStatus ? "snew" : "exist" });
        }
    }, [SeleStatus])
    const columns = [
        {
            name: "id",
            label: "Select",
            options: {
                sort: false,
                filter: false,
                display: false
            }
        },
        {
            name: "customerName",
            label: "Name",
            options: {
                sort: true,
                filter: false,
                display: true
            }
        },
        {
            name: "customerNumber",
            label: "Customer Number",
            options: {
                sort: true,
                filter: false,
                display: true
            }
        },
        {
            name: "address",
            label: "Address",
            options: {
                sort: false,
                filter: false,
                display: true
            }
        },
        {
            name: "postalCode",
            label: "Postal Code",
            options: {
                sort: false,
                filter: false,
                display: true
            }
        },
        {
            name: "phoneNumber",
            label: "Phone",
            options: {
                sort: false,
                filter: false,
                display: true
            }
        },
        {
            name: "serialNumber",
            label: "Serial No.",
            options: {
                sort: true,
                filter: false,
                display: true
            }
        },
        {
            name: "brand",
            label: "Brand",
            options: {
                sort: true,
                filter: false,
                display: true
            }
        },
        {
            name: "model",
            label: "Model",
            options: {
                sort: true,
                filter: false,
                display: true
            }
        },
        {
            name: "purchasePrice",
            label: "Purchase Price",
            options: {
                sort: false,
                filter: false,
                display: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Grid item lg={12}>
                            <Typography>${DecimalFormat(value)}</Typography>
                        </Grid>
                    )
                }
            }
        },
        {
            name: "purchaseDate",
            label: "Purchase Date",
            options: {
                sort: true,
                filter: false,
                display: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Grid item lg={12}>
                            <Typography>{convertDateDMY(value)}</Typography>
                        </Grid>
                    )
                }
            }
        },
        {
            name: "serviceDetails",
            label: "Last Service Date",
            options: {
                sort: false,
                filter: false,
                display: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    let filtered_array = _.filter(value, { 'serviceStatusName': 'completed' });
                    const ServiceList = _.orderBy(filtered_array, ['serviceDate'],['desc']);
                    let ServiceDate = "";
                    if(ServiceList.length>0){
                        ServiceDate = ServiceList[0].serviceDate;
                    }
                    return (
                        <Grid item lg={12}>
                            <Typography>{convertDateDMY(ServiceDate)}</Typography>
                        </Grid>
                    )
                }
            }
        },
        {
            name: "serviceDetails",
            label: "Job Type",
            options: {
                sort: false,
                filter: false,
                display: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const ServiceList = _.orderBy(value, ['serviceDate'],['desc']);
                    let JobTypes = "";
                    if(ServiceList.length>0){
                        JobTypes = ServiceList[0].jobType;
                    }
                    return (
                        <Grid item lg={12}>
                            <Typography>{JobTypes}</Typography>
                        </Grid>
                    )
                }
            }
        }
    ];
    return (
        <Dialog
            open={props.Status}
            fullWidth={true}
            fullScreen={false}
            maxWidth="lg"
            disableBackdropClick={false}
            disableEscapeKeyDown={false}
            scroll={"paper"}
            PaperProps={{
                style: {
                  overflowY: 'hidden'
                },
              }}
        >
            <DialogTitle className={classes.dialogTitle} id="dialog-title">{props.Title}</DialogTitle>
            <DialogContent dividers >
                <Grid item md={12} spacing={4} >
                    <RadioGroup aria-label="anonymous" name="anonymous" onChange={handleChange('ProductType')} value={Addvalues.ProductType} row>
                        <FormControlLabel value="exist" control={<Radio />} label="Existing Product" />
                        <FormControlLabel value="new" control={<Radio />} label="New Product(Existing Customer)" />
                        <FormControlLabel value="snew" disabled={!SeleStatus} control={<Radio />} label="New Product(Sellnchill Customer)" />
                    </RadioGroup>
                </Grid>
                <Grid item md={12} spacing={4} >
                <MUIDataTable
                    title=""
                    data={ProductList}
                    columns={columns}
                    options={{
                        sort: true,
                        selectableRows: false,
                        print: false,
                        search: false,
                        download: false,
                        viewColumns: false,
                        responsive: true,
                        filter: false,
                        onRowClick: (rowData, rowState) => {
                            props.ModelConfirm(rowData[0], Addvalues.ProductType)
                        },
                    }}
                />
                </Grid>
            </DialogContent>
            <DialogActions>
                {/* <Grid container md={12} direction="row" item style={{ display: "flex", justifyContent: "flex-end"}}> */}
                    <Button
                        onClick={() => props.ModelCancel()}
                        variant="contained"
                        color="secondary"
                        className={classes.buttonCancel}
                    >
                        Close
                </Button>
               {/*  </Grid> */}
            </DialogActions>
        </Dialog>
    );
}

export default ViewList;