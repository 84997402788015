import React, { useState, useEffect, Component } from 'react';
import {
    Grid, Dialog, DialogActions, DialogContent,
    DialogContentText, DialogTitle, makeStyles,
    Switch, FormControlLabel, IconButton, DeleteIcon,
    EditIcon, _, swal, toast, useSelector, useDispatch
} from '../../utilities/PackagesList';
import {
    PageTitle, CustomToolbar, AlertDialog,
    CustomModel, InputField, Dropdown,
    Buttons, LoadingOverlay
} from "../../utilities/ComponentsList";
import { isEmptyObj } from "../../utilities/AppFunctions";
import { UpdateAdhocService } from '../../api/Services';
const useStyles = makeStyles(theme => ({
    dialogTitle: {

    }
}))
const EditAdhoc = (props) => {
    const dispatch = useDispatch();
    const EditState = useSelector((state) => state.UpdateAdhocServiceReducer);
    const classes = useStyles();
    const [Editvalues, setEditValues] = useState({
        Product: '',
        Cost: '',
        SKU : ''
      });
      
    const [Editerrors, setEditErrors] = useState({});

    let ParentState = props.parentState;
    let EditingID = ParentState.EditID;
    let userSelection = ParentState.ChosenIndex;
    let ServiceData =  _.filter(ParentState.SelectingList, { 'id': EditingID });
    ServiceData = ServiceData.length>0 ? ServiceData[0] : [];
   
    useEffect(() => {
        if(ParentState.EditID){
          setEditValues({
            Product: ServiceData.product,
            Cost: ServiceData.cost,
            SKU : ServiceData.sku
          })
        } 
        
      }, [ParentState.EditID])
      if (EditState.Success && !isEmptyObj(EditState.UpdateResponse)) {
        toast.success('Updated Successfully!', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: false,
        });
        let EditedObject = {
          product: Editvalues.Product,
          cost : Editvalues.Cost,
          sku : Editvalues.SKU
        }
        props.parentClass.EditedAdhoc(EditedObject)
    } else if (EditState.Failure && !isEmptyObj(EditState.UpdateResponse)) {
        toast.error(EditState.UpdateResponse.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: false,
        });
    }
    const handleChange = name => event => {
        let targetValue = event.target.value;
        setEditValues({ ...Editvalues, [name]: targetValue });
        setEditErrors({ ...Editerrors, [name]: targetValue ? false : true })
      };
      const saveEdit = () => {
        let srrorObj = {};
        let errorStatus = false;
        Object.keys(Editvalues).map(function (k) {
          if(k==='Remarks'){
            srrorObj[k] = false;
            errorStatus = errorStatus ? true : false;
            
          }else{
            srrorObj[k] = Editvalues[k] ? false : true;
            errorStatus = errorStatus ? true : !Editvalues[k];
          }
        })
        setEditErrors(srrorObj);
        if(Editvalues.Product && Editvalues.Cost){
          let updateObject = {
            product: Editvalues.Product,
            cost : Editvalues.Cost,
            sku : Editvalues.SKU
          }
         
          dispatch(UpdateAdhocService(updateObject,ParentState.EditID));
        } 
      };

      return (
        <Dialog
          open={props.Status}
          fullWidth
          maxWidth="sm"
          disableBackdropClick={false}
          disableEscapeKeyDown={false}
        >
          <DialogTitle className={classes.dialogTitle} id="dialog-title">{props.Title}</DialogTitle>
          <DialogContent dividers>
          <Grid container spacing={4}>
          <Grid item md={12}>
          <Grid
              container
              spacing={4}
              direction="column">
              <Grid
                  item
                  md={12}
                  spacing={4}
                >
                 <InputField
                    label={"Product/Service"}
                    type={"text"}
                    required={true}
                    value={Editvalues.Product}
                    changeText={handleChange('Product')}
                    error={Editerrors.Product}
                    initialName={"Product/Service"}
                    errorMsg={"Please enter product/service."}
                  />
              </Grid>
              <Grid
                item
                md={12}
                spacing={4}
              >
                <InputField
                  label={"SKU"}
                  type={"text"}
                  required={true}
                  value={Editvalues.SKU}
                  changeText={handleChange('SKU')}
                  error={Editerrors.Product}
                  initialName={"SKU"}
                  errorMsg={"Please enter sku."}
                />
              </Grid>
              <Grid
                  item
                  md={12}
                  spacing={4}
                >
                 <InputField
                    label={"Cost"}
                    type={"number"}
                    required={true}
                    value={Editvalues.Cost}
                    changeText={handleChange('Cost')}
                    error={Editerrors.Cost}
                    initialName={"Cost"}
                    errorMsg={"Please enter cost."}
                  />
              </Grid>
            </Grid>
          </Grid>
          <Buttons
              close={() => props.parentClass.CloseEdit()}
              closeText={"Cancel"}
              save={() => saveEdit()}
              saveText={"Update"}
            />
        </Grid>
     </DialogContent>
        </Dialog>
      );
}

export default EditAdhoc;