import React, { useState, useEffect, Component } from 'react';
import {
    Grid, Dialog, GridList, DialogContent,
    GridListTile, DialogTitle, makeStyles,
    Typography, Divider, IconButton, DeleteIcon,
    EditIcon, _, swal, toast, useSelector, useDispatch,
    Card, CardMedia, CardContent, CardActions, Box
} from '../../utilities/PackagesList';
import {
    PageTitle, CustomToolbar, AlertDialog,
    CustomModel, InputField, Dropdown,
    Buttons, LoadingOverlay
} from "../../utilities/ComponentsList";
import { isEmptyObj, DMY, AddTimeStamp2Date, PriceFormat, CurrentTime } from "../../utilities/AppFunctions";
import { UpdateService, GetPaymentTypes } from '../../api/Services';
import { refreshReduxStates } from './../../api/Refresh';
import useStyles from "./../../utilities/Styles";
/* const useStyles = makeStyles(theme => ({
    dialogTitle: {

    },
    dialogWindow: {
        width: '80%'
    },
    listStyle: {
        border: "1px solid #eee",
        padding: 10,
        height: "250px",
        width: "100%"
    },
    cardStyle: {
        height: 90,
        padding: 10,
        margin: 10,
        boxShadow: "none",
        borderRadius: 10,
        backgroundColor: '#000'

    },
})) */
const UpdateDate = (props) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [Editvalues, setEditValues] = React.useState({
        Date: '',
        Time: '',
        PaymentModeID: "",
        PayModeObj: "",
        PayReference: ""
    });
    const [EditErrors, setEditErrors] = useState({});
    const UpdateState = useSelector((state) => state.UpdateServiceReducer);
    const PaymentType = useSelector((state) => state.GetPaymentTypesReducer);
    let ParentState = props.parentState;
    let IDSelected = ParentState.EditID;
    let PaymentList = PaymentType.PaymentTypes ? PaymentType.PaymentTypes : [];
    let Services = ParentState.FilteredServiceList ? ParentState.FilteredServiceList : [];
    
    let SelectedService = _.filter(Services, { id: IDSelected });
    let DataExist = SelectedService.length > 0 ? true : false;
    let BalanceAmount = DataExist ? (parseFloat(SelectedService[0].paymentCollection) - parseFloat(SelectedService[0].paymentCollected)) : 0;
    useEffect(() => {
        if (IDSelected) {
            dispatch(GetPaymentTypes());
        }
    }, [IDSelected])
    if (UpdateState.Success && !isEmptyObj(UpdateState.UpdateResponse)) {
        toast.success('Updated Successfully!', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: false,
        });
        let UpdatedService = {
            balancePaidDate: AddTimeStamp2Date(Editvalues.Date),
            paymentStatusId: 1,
            paymentStatusName: "Fully Paid"
        }
        props.ModelConfirm(UpdatedService);
    } else if (UpdateState.Failure && !isEmptyObj(UpdateState.UpdateResponse)) {
        toast.error(UpdateState.UpdateResponse.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: false,
        });
    }
    const handleChange = name => event => {
        let targetValue = event.target.value;
        setEditValues({ ...Editvalues, [name]: targetValue });
        setEditErrors({ ...EditErrors, [name]: targetValue ? false : true })

    };

    const submitForm = () => {
        let srrorObj = {};
        let errorStatus = false;
        Object.keys(Editvalues).map(function (k) {
            if (k === 'PayReference') {
                srrorObj[k] = false;
                errorStatus = errorStatus ? true : false;

            } else if (k === 'Time') {
                srrorObj[k] = false;
                errorStatus = errorStatus ? true : false;

            } else {
                srrorObj[k] = Editvalues[k] ? false : true;
                errorStatus = errorStatus ? true : !Editvalues[k];
            }
        })
        setEditErrors(srrorObj);
        if (!errorStatus) {
            let timeData = Editvalues.Time ? Editvalues.Time : CurrentTime();
            let fullTime = Editvalues.Date + 'T' + timeData + ':00.000Z';
            var updateObject = new FormData();
            updateObject.append('balancePaidDate', fullTime);
            updateObject.append('paymentStatusId', "1");
            updateObject.append('balancePaymentModeId', Editvalues.PaymentModeID.toString());
            updateObject.append('balancePaymentReference', Editvalues.PayReference);
            dispatch(UpdateService(updateObject, IDSelected));
        }
    };

    return (
        <Dialog
            open={props.Status}
            fullWidth={true}
            fullScreen={false}
            maxWidth="sm"
            disableBackdropClick={false}
            disableEscapeKeyDown={false}
        >
            <DialogTitle className={classes.dialogTitle} id="dialog-title">{props.Title}</DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={4}>
                    <Grid item md={12}>
                        <Grid
                            container
                            spacing={4}
                            direction="column"
                        >
                            <Grid item md={12} >
                                <Typography className={classes.boldText}>Balance Amount : {PriceFormat(BalanceAmount)}</Typography>
                            </Grid>
                            <Grid item md={12} >
                                <Dropdown
                                    label={"Payment Mode"}
                                    data={PaymentList}
                                    getOptionLabel={option => (option.name ? option.name : "")}
                                    getOptionSelected={(option, value) => option.id === value.id}
                                    value={Editvalues.PayModeObj}
                                    ItemChange={(event, newValue) => {
                                        setEditValues({
                                            ...Editvalues,
                                            PaymentModeID: newValue ? newValue.id : null,
                                            PayModeObj: newValue,
                                        })
                                        setEditErrors({
                                            ...EditErrors,
                                            PaymentModeID: newValue ? false : true,
                                            PayModeObj: newValue ? false : true
                                        })
                                    }}
                                    error={EditErrors.PaymentModeID}
                                    initialName={"Payment Mode"}
                                    errorMsg={"Please choose payment mode."}
                                />
                            </Grid>
                            <Grid item md={12}>
                                <InputField
                                    label={"Payment Reference"}
                                    type={"text"}
                                    required={false}
                                    value={Editvalues.PayReference}
                                    changeText={handleChange('PayReference')}
                                    error={EditErrors.PayReference}
                                    initialName={"Payment Reference"}
                                    errorMsg={"Please enter payment reference."}
                                />
                            </Grid>
                            <Grid item md={12}>
                                <InputField
                                    label={"Select Date"}
                                    type={"date"}
                                    required={true}
                                    value={Editvalues.Date}
                                    changeText={handleChange('Date')}
                                    error={EditErrors.Date}
                                    initialName={"Date"}
                                    errorMsg={"Please enter date."}
                                />
                            </Grid>
                            <Grid item md={12}>
                                <InputField
                                    label={"Select Time"}
                                    type={"time"}
                                    required={false}
                                    value={Editvalues.Time}
                                    changeText={handleChange('Time')}
                                    error={EditErrors.Time}
                                    initialName={"Time"}
                                    errorMsg={"Please enter time."}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Buttons
                        close={() => props.ModelCancel()}
                        closeText={"Cancel"}
                        save={() => submitForm()}
                        saveText={"Submit"}
                    />
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

export default UpdateDate;