import { returnApiCallPromise , returnSellnchillApiPromise} from './ApiContent';

export function syncSellnchillCustomer() {
    let patameter = "sync-customer";
    return (dispatch) => {
        dispatch({ type: 'SYNC_CUST_INFO' });
        returnApiCallPromise("GET", patameter, null, null, "scheduler")
            .then(response => {  
                dispatch({
                    type: 'SYNC_CUST_INFO_SUCCESS',
                    data: response.data.data
                })
            }).catch(error => {
                let errorMessage = error;
                if(error.response){
                    if(error.response.data.error){
                        errorMessage = error.response.data;
                    }
                } 
                dispatch({
                    type: 'SYNC_CUST_INFO_FAILURE',
                    data: errorMessage
                })
            })
    }
}