

export function getTeamsReducer(state = {}, action) {    
    
    switch (action.type) {
        case 'TEAMS_SUCCESS': {
            return {
                ...state,
                Success: true,
                Failure: false,
                TeamList: action.data
            }
        }
        case 'TEAMS_FAILURE': {
            return {
                ...state,
                Failure: true,
                Success:false,
                TeamList: action.data
            }
        }
        case 'FETCHING_TEAMS': {
            return {
                ...state,
                Success: false,
                Failure: false,
                TeamList: action.data
            }
        }
        default: {
            return state;
        }
    }
}
export function getTeamTypesReducer(state = {}, action) {    
    
    switch (action.type) {
        case 'TEAM_TYPE_SUCCESS': {
            return {
                ...state,
                Success: true,
                Failure: false,
                TeamTypes: action.data
            }
        }
        case 'TEAM_TYPE_FAILURE': {
            return {
                ...state,
                Failure: true,
                Success:false,
                TeamTypes: action.data
            }
        }
        case 'FETCHING_TEAM_TYPE': {
            return {
                ...state,
                Success: false,
                Failure: false,
                TeamTypes: action.data
            }
        }
        default: {
            return state;
        }
    }
}
export function getUnAssignedReducer(state = {}, action) {    
    switch (action.type) {
        case 'UNASSIGNED_SUCCESS': {
            return {
                ...state,
                Success: true,
                Failure: false,
                UnAssignedList: action.data
            }
        }
        case 'UNASSIGNED_FAILURE': {
            return {
                ...state,
                Failure: true,
                Success:false,
                UnAssignedList: action.data
            }
        }
        case 'FETCHING_UNASSIGNED': {
            return {
                ...state,
                Success: false,
                Failure: false,
                UnAssignedList: action.data
            }
        }
        default: {
            return state;
        }
    }
}
export function AddTeamReducer(state = {}, action) {
    switch (action.type) {
        case 'TEAM_ADD_SUCCESS': {
            return {
                ...state,
                Success: true,
                Failure: false,
                AddingResponse: action.data
            }
        }
        case 'TEAM_ADD_FAILURE': {
            return {
                ...state,
                Failure: true,
                Success:false,
                AddingResponse: action.data,
            }
        }
        default: {
            return {
                ...state,
                Failure: false,
                Success:false,
                AddingResponse: null,
            }
        }
    }
}
export function UpdateTeamReducer(state = {}, action) {
    switch (action.type) {
        case 'TEAM_UPDATE_SUCCESS': {
            return {
                ...state,
                Success: true,
                Failure: false,
                UpdateResponse: action.data
            }
        }
        case 'TEAM_UPDATE_FAILURE': {
            return {
                ...state,
                Failure: true,
                Success: false,
                UpdateResponse: action.data,
            }
        }
        default: {
            return {
                ...state,
                Failure: false,
                Success: false,
                UpdateResponse: null,
            };
        }
    }
}
export function DeleteTeamReducer(state = {}, action) {
    
    switch (action.type) {
       
        case 'TEAM_DELETE_SUCCESS': {
            return {
                ...state,
                Success: true,
                Failure: false,
                DeleteResponse: action.data
            }
        }
        case 'TEAM_DELETE_FAILURE': {
            return {
                ...state,
                Failure: true,
                Success: false,
                DeleteResponse: action.data,
            }
        }
        default: {
            return {
                ...state,
                Failure: false,
                Success: false,
                DeleteResponse: null,
            }
        }
    }
}
export function AddTeamLeavesReducer(state = {}, action) {
    switch (action.type) {
        case 'ADD_LEAVE_SUCCESS': {
            return {
                ...state,
                Success: true,
                Failure: false,
                AddingResponse: action.data
            }
        }
        case 'ADD_LEAVE_FAILURE': {
            return {
                ...state,
                Failure: true,
                Success:false,
                AddingResponse: action.data,
            }
        }
        default: {
            return {
                ...state,
                Failure: false,
                Success:false,
                AddingResponse: null,
            }
        }
    }
}
export function GetTeamLeavesReducer(state = {}, action) {    
    
    switch (action.type) {
        case 'TEAM_LEAVE_SUCCESS': {
            return {
                ...state,
                Success: true,
                Failure: false,
                TeamLeaves: action.data
            }
        }
        case 'TEAM_LEAVE_FAILURE': {
            return {
                ...state,
                Failure: true,
                Success:false,
                TeamLeaves: action.data
            }
        }
        case 'FETCHING_TEAM_LEAVE': {
            return {
                ...state,
                Success: false,
                Failure: false,
                TeamLeaves: action.data
            }
        }
        default: {
            return state;
        }
    }
}
export function DeleteTeamLeavesReducer(state = {}, action) {
    
    switch (action.type) {
       
        case 'TEAM_LEAVE_DELETE_SUCCESS': {
            return {
                ...state,
                Success: true,
                Failure: false,
                DeleteResponse: action.data
            }
        }
        case 'TEAM_LEAVE_DELETE_FAILURE': {
            return {
                ...state,
                Failure: true,
                Success: false,
                DeleteResponse: action.data,
            }
        }
        default: {
            return {
                ...state,
                Failure: false,
                Success: false,
                DeleteResponse: null,
            }
        }
    }
}
