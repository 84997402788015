import React, { useState, useEffect, Component } from "react";
import {
  Grid, TableFooter, Button, TableRow,
  TableCell, IconButton, DeleteIcon,
  EditIcon, MUIDataTable, Autocomplete, connect,
  _, swal, toast, Typography, Checkbox, Tab,
  TabPanel, AppBar, TabContext, TabList, withStyles,
  CardMedia, SRLWrapper, SimpleReactLightbox
} from "../../utilities/PackagesList";
import {
  PageTitle, CustomToolbar, AlertDialog,
  CustomModel, InputField, Dropdown,
  Buttons, LoadingOverlay, ButtonIcon
} from "../../utilities/ComponentsList";
import {
  isEmptyObj, dateTimeFormating, todayDate,
  LastDayMonth, DatePlusYMD, AMPM,
  convertDateDMY, PriceFormat
} from "../../utilities/AppFunctions";
import { getTeams } from '../../api/Team';
import { GetServiceReport } from '../../api/Services';
import { refreshReduxStates } from '../../api/Refresh';
import styles from "../../utilities/StylesClass";
import BaseDetails from './../../utilities/BaseDetails.json';
import { collectionReport, jobtypeReport, paymentReport } from './../../utilities/MockData';

class CollectionReport extends Component {

  constructor(props) {
    super(props);
    this.state = {
      ReportList: [],
      StartDate: todayDate(),
      EndDate: DatePlusYMD(10),
      TeamSelectedID: "",
      TeamSelected: "",
      isLoading: true,
      getCalls: [1, 1],
      TeamsLists: []

    }
    let filterObj = {
      Type: "techDailyCollection",
      TeamID: "",
      StartDate: todayDate(),
      EndDate: DatePlusYMD(10)
    };
    props.getTeams(1);
    props.GetServiceReport(filterObj);

  }

  UNSAFE_componentWillReceiveProps = (props) => {
    if (this.state.getCalls[0]) {
      if (props.TeamListSuccess) {
        let succed = this.state.getCalls;
        succed[0] = 0;
        let loaderStatus = succed.includes(1);
        let newTeam = {
          "id": 0,
          "teamName": "All",
          "vehicleNumber": "",
          "vehicelId": "",
          "assignedStaff": [],
          "teamTypeId": 0,
          "teamTypeName": "all"
        };
        let Teams = props.TeamLists;
        Teams.unshift(newTeam);
        this.setState({
          TeamsLists: props.TeamLists,
          isLoading: loaderStatus,
          TeamSelectedID: Teams[0].id,
          TeamSelected: Teams[0],
          getCalls: succed
        })
      } else if (props.TeamListFailure) {
        let succed = this.state.getCalls;
        succed[0] = 0;
        let loaderStatus = succed.includes(1);
        this.setState({
          TeamsLists: [],
          getCalls: succed,
          isLoading: loaderStatus
        })
      }
    }
    if (this.state.getCalls[1]) {
      if (props.SReportSuccess) {
        let succed = this.state.getCalls;
        succed[1] = 0;
        let loaderStatus = succed.includes(1);

        console.log(JSON.stringify(props.SReportLists))
        this.setState({
          ReportList: props.SReportLists,
          isLoading: loaderStatus,
          getCalls: succed
        })
      } else if (props.SReportFailure) {
        let succed = this.state.getCalls;
        succed[1] = 0;
        let loaderStatus = succed.includes(1);
        this.setState({
          ReportList: [],
          getCalls: succed,
          isLoading: loaderStatus
        })
      }
    }
  }

  searchClick = () => {
    if (!this.state.StartDate && !this.state.EndDate && !this.state.TeamSelectedID) {
      toast.warning('Please choose any one filter option!', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: false,
      });
      return;
    }
    let Start = this.state.StartDate ? this.state.StartDate : "";
    let End = this.state.EndDate ? this.state.EndDate : "";
    let TeamSelectedID = this.state.TeamSelectedID ? this.state.TeamSelectedID : "";
    let filterObj = {
      Type: "techDailyCollection",
      TeamID: TeamSelectedID,
      StartDate: Start,
      EndDate: End
    };
    this.setState({
      isLoading: true,
      getCalls: [0, 1]
    })
    this.props.GetServiceReport(filterObj);
  }

  clearSearchClick = () => {
    this.setState({
      ReportList: [],
      StartDate: "",//DateMinusYMD(5),
      EndDate: "",
      TeamSelectedID: "",
      TeamSelected: ""
    });

  }
  sumCollection = (inpData) => {
    return PriceFormat(_.sumBy(inpData, function (o) { return o.paymentCollection ? parseFloat(o.paymentCollection) : 0; }));
  }
  render() {
    const { classes } = this.props;

    const columns = [
      {
        name: "serviceDate",
        label: "Date",
        options: {
          filter: true,
          sort: true,
          display: true,
          print: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Grid item md={12}>
                <Typography>{convertDateDMY(value)}</Typography>
              </Grid>
            )
          }
        }
      },
      {
        name: "customerNumber",
        label: "Customer Number",
        options: {
          filter: true,
          sort: true,
          display: true,
          print: true
        }
      },
      {
        name: "customerName",
        label: "Customer Name",
        options: {
          filter: true,
          sort: true,
          display: true,
          print: true
        }
      },
      {
        name: "customerPhone",
        label: "Customer Contact",
        options: {
          filter: true,
          sort: true,
          display: true,
          print: true
        }
      },
      {
        name: "jobType",
        label: "Job Type",
        options: {
          filter: true,
          sort: true,
          display: true,
          print: true
        }
      },
      {
        name: "technicianName",
        label: "Tech Name",
        options: {
          filter: true,
          sort: true,
          display: true,
          print: true
        }
      },
      {
        name: "adHocServiceItems",
        label: "Adhoc Items",
        options: {
          filter: true,
          sort: true,
          display: true,
          print: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            const AdhocServices = value ? value : [];
            return (
              <Grid item md={12}>
                {
                  AdhocServices.map((item, key) => {
                    return <Typography >{item.product}-{PriceFormat(item.cost)}</Typography>
                  })
                }
              </Grid>
            )
          }
        }
      },
      {
        name: "paymentCollection",
        label: "Collection",
        options: {
          filter: true,
          sort: true,
          display: true,
          print: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Grid item md={12}>
                <Typography>{PriceFormat(value)}</Typography>
              </Grid>
            )
          }
        }
      },
      {
        name: "paymentMode",
        label: "Payment Mode",
        options: {
          filter: true,
          sort: true,
          display: true,
          print: true
        }
      }
    ];

    return (
      <>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Grid container spacing={1} md={12} direction={"row"}>
              <Grid item md={2} >
                <InputField
                  label={"Start Date"}
                  type={"date"}
                  required={false}
                  value={this.state.StartDate}
                  changeText={(data) => {
                    this.setState({
                      StartDate: data ? data.target.value : null,
                      EndDate: data ? LastDayMonth(data.target.value) : null
                    })
                  }}
                  initialName={"Start Date"}
                  errorMsg={"Please enter start date."}
                />
              </Grid>
              <Grid item md={2} >
                <InputField
                  label={"End Date"}
                  type={"date"}
                  required={false}
                  value={this.state.EndDate}
                  changeText={(data) => { this.setState({ EndDate: data ? data.target.value : null }) }}
                  // error={Createerrors.Date}
                  initialName={"End Date"}
                  errorMsg={"Please enter end date."}
                />
              </Grid>
              <Grid item md={2} >
                <Dropdown
                  label={"Select Team"}
                  data={this.state.TeamsLists}
                  getOptionLabel={option => (option.teamName ? option.teamName : "")}
                  getOptionSelected={(option, value) => option.id === value.id}
                  value={this.state.TeamSelected}
                  ItemChange={(event, newValue) => {
                    this.setState({
                      TeamSelectedID: newValue ? newValue.id : null,
                      TeamSelected: newValue ? newValue : null
                    })
                  }}
                  //error={Createerrors.TeamSelectedID}
                  initialName={"Team"}
                  errorMsg={"Please choose team."}
                />
              </Grid>
              <Grid item md={1} >
                <Button
                  onClick={() => this.clearSearchClick()}
                  className={classes.buttonCancel}
                  variant="contained" color="primary">
                  Clear
                  </Button>
              </Grid>
              <Grid item md={1} >
                <Button
                  onClick={() => this.searchClick()}
                  className={classes.buttonSuccess}
                  variant="contained" color="primary">
                  Search
                  </Button>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ marginTop: 10 }}>
              <MUIDataTable
                title=""
                data={this.state.ReportList}
                columns={columns}
                options={{
                  downloadOptions: {
                    filename: "Collection_Report.csv",
                    filterOptions: {
                      useDisplayedColumnsOnly: true,
                      useDisplayedRowsOnly: true
                    }
                  },
                  sort: true,
                  selectableRows: false,
                  selectableRowsOnClick: false,
                  responsive: true,
                  print: true,
                  download: true,
                  viewColumns: false,
                  filter: false,
                  onDownload: (buildHead, buildBody, columns, data) => {
                    data.forEach(function (prow, pIndex) {
                      prow.data.forEach(function (crow, cindex) {
                        let check = Array.isArray(crow);
                        if (check) {
                          let items = "";
                          crow.forEach(function (val, index) {
                            items += val.product+'-'+PriceFormat(val.cost)+',';
                          });
                          data[pIndex].data[6] = items;
                        } else {
                          data[pIndex].data[cindex] = crow;
                        }
                      })
                    });
                    return buildHead(columns) + buildBody(data);
                  },
                  customTableBodyFooterRender: (opts) => {
                    return (
                      <TableFooter>
                        <TableRow>
                          <TableCell colSpan={5}>
                            <Typography className={classes.boldText} align="right">Total</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography className={classes.boldText}>{this.sumCollection(this.state.ReportList)}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography className={classes.boldText}></Typography>
                          </TableCell>
                        </TableRow>
                      </TableFooter>
                    )
                  }
                }}
              />
            </Grid>

          </Grid>
          <LoadingOverlay Status={this.state.isLoading} />
        </Grid>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    TeamListSuccess: state.getTeamsReducer.Success,
    TeamListFailure: state.getTeamsReducer.Failure,
    TeamLists: state.getTeamsReducer.TeamList,

    SReportSuccess: state.GetServiceReportReducer.Success,
    SReportFailure: state.GetServiceReportReducer.Failure,
    SReportLists: state.GetServiceReportReducer.SReport,

    UserDetails: state.LoginReducer.UserDetails,
    LoaderState: state.LoaderReducer.State
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTeams: (ID) => dispatch(getTeams(ID)),
    GetServiceReport: (filterObj) => dispatch(GetServiceReport(filterObj)),
    refreshReduxStates: () => dispatch(refreshReduxStates()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CollectionReport));
