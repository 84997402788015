import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, makeStyles} from './../../utilities/PackagesList';

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    
  }
}))

const CustomModel = (props) =>{
  const classes = useStyles();
  const closeDialog = () => {
    props.ModelCancel();
  };
  /* const deeteStaff = () => {
    props.ModelConfirm();
  }; */
  console.log(props.Content)
  return(
    
      <Dialog
        open={props.Status}
        onClose={closeDialog}
        fullWidth
        maxWidth="sm"
        disableBackdropClick={false}
        disableEscapeKeyDown={false}
      >
        <DialogTitle className={classes.dialogTitle} id="dialog-title">{props.Title}</DialogTitle>
        <DialogContent dividers>
           {props.Content}
           
        </DialogContent>
       {/*  <DialogActions>
          <Button size="small" variant="outlined" color="primary" onClick={closeDialog}>
            Cancel
          </Button>
          <Button size="small" variant="outlined" color="secondary" onClick={deeteStaff}>
            Delete
          </Button>
        </DialogActions> */}
      </Dialog>
  );
}
export default CustomModel;