import React, { useState, useEffect, Component } from 'react';
import {
  Grid, Dialog, DialogActions, DialogContent,
  DialogContentText, DialogTitle, makeStyles,
  Switch, FormControlLabel, IconButton, DeleteIcon,
  EditIcon, _, swal, toast, useSelector, useDispatch,
  Checkbox, Radio, RadioGroup
} from '../../utilities/PackagesList';
import {
  PageTitle, CustomToolbar, AlertDialog,
  CustomModel, InputField, Dropdown,
  Buttons, LoadingOverlay
} from "../../utilities/ComponentsList";
import { isEmptyObj, capsFirstLetter } from "../../utilities/AppFunctions";
import useStyles from "./../../utilities/Styles";
import { FormFields } from "./../../utilities/MockData";
let fieldsList = [];
const Create = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  let ParentState = props.parentState;
  const StateForms = ParentState.SelectingItem;
  const [AddValues, setAddValues] = useState({
    FormTypes: [],
    SlectedFormTypeName: "",
    SlectedFormType: "",
    FormTitle: "head",
    ColumnCountList: [],
    ColumnCount: "",
    ColumnCountID: "",
    LabelText: ""
  });
  const [AddErrors, setAddErrors] = useState({});
  useEffect(() => {
    if (props.Status) {
      let userFormTypes = StateForms;
      userFormTypes.push({
        formtype: "new form",
        header: [],
        body: []
      })
      let columns = [];
      for (let i = 1; i <= 5; i++) {
        columns.push({
          id: i,
          name: i.toString()
        })
      }
      setAddValues({
        ...AddValues,
        FormTypes: userFormTypes,
        SlectedFormTypeName: "",
        SlectedFormType: "",
        ColumnCountList: columns
      })
    }

  }, [props.Status])

  const handleChange = name => event => {
    let targetValue = event.target.value;
    setAddValues({ ...AddValues, [name]: targetValue });
    setAddErrors({ ...AddErrors, [name]: targetValue ? false : true })
  };
  const submitForm = () => {
    let srrorObj = {};
    let errorStatus = false;
    Object.keys(AddValues).map(function (k) {
      srrorObj[k] = AddValues[k] ? false : true;
      errorStatus = errorStatus ? true : !AddValues[k];
    })
    setAddErrors(srrorObj);
    if (!errorStatus) {
      /* if(AddValues.SlectedFormTypeName=='new form')
      for (let x = 0; x < parseInt(countsData); x++) {
        fieldsList.push({FieldType:FormFields[1],FieldTypeID:FormFields[1].id});
      } */
    }


  }
  const updateList=(countsData)=>{
    fieldsList = [];
    for (let x = 0; x < parseInt(countsData); x++) {
      fieldsList.push({FieldType:FormFields[1],FieldTypeID:FormFields[1].id});
    }
  }
  let LabelSelected = fieldsList.some((o) => o.FieldTypeID === 1);
  return (
    <Dialog
      open={props.Status}
      fullWidth
      maxWidth="sm"
      disableBackdropClick={false}
      disableEscapeKeyDown={false}
    >
      <DialogTitle className={classes.dialogTitle} id="dialog-title">{props.Title}</DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={4}>
          <Grid item md={12}>
            <Grid container spacing={4} direction="row">
              <Grid item md={12}>
                <Dropdown
                  label={"Form Type"}
                  data={AddValues.FormTypes}
                  getOptionLabel={option => (option.formtype ? capsFirstLetter(option.formtype) : "")}
                  getOptionSelected={(option, value) => option.formtype === value.formtype}
                  value={AddValues.SlectedFormType}
                  ItemChange={(event, newValue) => {
                    setAddValues({
                      ...AddValues,
                      SlectedFormTypeName: newValue ? newValue.formtype : null,
                      SlectedFormType: newValue ? newValue : null
                    });
                    setAddErrors({
                      ...AddErrors,
                      SlectedFormTypeName: newValue ? false : true,
                      SlectedFormType: newValue ? false : true
                    })
                  }}
                  error={AddErrors.SlectedFormTypeName}
                  initialName={"Form Type"}
                  errorMsg={"Please choose form type."}
                />
              </Grid>
              <Grid item md={12}>
                <RadioGroup row aria-label="FormTitle" name="FormTitle" defaultValue={AddValues.FormTitle}>
                  <FormControlLabel
                    value="head"
                    control={<Radio color="primary" />}
                    label="Header"
                    labelPlacement="Header"
                  />
                  <FormControlLabel
                    value="body"
                    control={<Radio color="primary" />}
                    label="Body"
                    labelPlacement="Body"
                  />
                </RadioGroup>
              </Grid>
              <Grid item md={12}>
                <Dropdown
                  label={"Column Counts"}
                  data={AddValues.ColumnCountList}
                  getOptionLabel={option => (option.name ? option.name : "")}
                  getOptionSelected={(option, value) => option.id === value.id}
                  value={AddValues.ColumnCount}
                  ItemChange={(event, newValue) => {
                    setAddValues({
                      ...AddValues,
                      ColumnCountID: newValue ? newValue.id : null,
                      ColumnCount: newValue ? newValue : null
                    });
                    setAddErrors({
                      ...AddErrors,
                      ColumnCountID: newValue ? false : true,
                      ColumnCount: newValue ? false : true
                    })
                    updateList(newValue ? newValue.id : 0);
                  }}
                  error={AddErrors.ColumnCountID}
                  initialName={"Column Counts"}
                  errorMsg={"Please choose column counts."}
                />
              </Grid>
              <Grid item md={12} container spacing={4} direction="row">
                {fieldsList.map((x, i) => {
                  let rowLeng = parseInt(12/parseInt(fieldsList.length));
                  let isObject = fieldsList.some((o) => o.FieldTypeID === 1);
                  return (
                    <Grid item md={rowLeng}>
                      <Dropdown
                        label={"Field Type"}
                        data={FormFields}
                        getOptionLabel={option => (option.type ? capsFirstLetter(option.type) : "")}
                        getOptionSelected={(option, value) => option.id === value.id}
                        getOptionDisabled={(option) => option.name ? (option.name=='label' && isObject) : false}
                        value={fieldsList[i].FieldType}
                        ItemChange={(event, newValue) => {
                          fieldsList[i].FieldType = newValue;
                          fieldsList[i].FieldTypeID = newValue ? newValue.id : null;
                          setAddValues({
                            ...AddValues,
                            LabelText:""
                          });
                         
                        }}
                        //error={AddErrors.FieldTypeID}
                        initialName={"Form Type"}
                        errorMsg={"Please choose form type."}
                      />
                    </Grid>
                  );
                })}

              </Grid>
              {LabelSelected ?
                <Grid item md={12}>
                  <InputField
                    label="Label Name"
                    type={"text"}
                    required={true}
                    value={AddValues.LabelText}
                    changeText={handleChange('LabelText')}
                    error={AddErrors.LabelText}
                    initialName={"Label Name"}
                    errorMsg={"Please enter label name."}
                  />
                </Grid>
                : <Grid />}

            </Grid>
          </Grid>

        </Grid>
      </DialogContent>
      <DialogActions>
        <Buttons
          close={() => props.ModelCancel()}
          closeText={"Cancel"}
          save={() => submitForm()}
          saveText={"Submit"}
        />
      </DialogActions>
    </Dialog>
  );
}

export default Create;