import React, { useState, createRef, Component } from "react";
import {
  Grid, TextField, Button, Switch,
  FormControlLabel, IconButton, DeleteIcon,
  EditIcon, MUIDataTable, moment, connect,
  _, swal, toast, Typography, FullCalendar, withStyles,
  dayGridPlugin, timeGridPlugin, interactionPlugin, ArrowBackIcon
} from "../../utilities/PackagesList";
import {
  PageTitle, CustomToolbar, AlertDialog,
  CustomModel, InputField, Dropdown,
  Buttons, LoadingOverlay, ButtonIcon
} from "../../utilities/ComponentsList";
import { MonthNumber, WeekStartDate, NextStartDate, NextEndDate, ChangeEndDate, ChangeStartDate,
        WeekEndDate, MonthStartDate, MonthEndDate, LastDayMonth , CDatePlusYMD, CDateMinusYMD
      } from "../../utilities/AppFunctions";
import { GetTeamLeaves , getTeams, DeleteTeamLeaves} from '../../api/Team';
import { GetJobTypes, UpdateJobTypes, DeleteJobTypes } from '../../api/Services';
import { refreshReduxStates } from '../../api/Refresh';
import styles from "../../utilities/StylesClass";
import AddLeaves from "./AddLeaves";
import EditLeaves from "./EditLeaves";
class Leaves extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      AddModel: false,
      AddingData: null,
      RemoveModel: false,
      RemoveData: null,
      EditState: false,
      EditData: null,
      StartDate: MonthStartDate(),
      EndDate: MonthEndDate(),
      TeamSelectedID:"",
      TeamSelected:"",
      PreviousMonth: MonthNumber(),
      DefaultView:"dayGridMonth",
      getCalls:[1, 1],
      postCalls:[0],
      TLeaveList:[],
      TeamsLists:[],
      initialEvents: [{id:1, teamId: 44, teamName: "MM Service Team", start: "2021-07-01", end: "2021-07-01"}]
    }
    this.calendarRef = React.createRef()
    props.getTeams(1);
    let filterObj = {
      StartDate: MonthStartDate(),
      EndDate: MonthEndDate(),
      TeamID : null
    }
    props.GetTeamLeaves(filterObj);
  }
  UNSAFE_componentWillReceiveProps = (props) => {
    if (this.state.getCalls[0]) {
      if (props.TeamLeaveSuccess) {
        let succed = this.state.getCalls;
        succed[0] = 0;
        let loaderStatus = succed.includes(1);
        let TeamLeaveData = props.TeamLeaveList;
        let newItems = [];
        let newList = TeamLeaveData.filter(function (item, index) {
          let subItems = item.leaves;
          subItems.forEach(function(value, index, array) {
            let newObject = {};
            newObject.id = value.id;
            newObject.start = value.date;
            newObject.end = value.date;
            newObject.teamName = item.teamName;
            newObject.teamId = item.teamId;
            newItems.push(newObject);
        }); 
        //newItems.push(newObject);
        })
        this.setState({
          TLeaveList: newItems,
          isLoading: loaderStatus,
          getCalls: succed
        })
      } else if (props.TeamLeaveFailure) {
        let succed = this.state.getCalls;
        succed[0] = 0;
        let loaderStatus = succed.includes(1);
        this.setState({
          TLeaveList: [],
          getCalls: succed,
          isLoading: loaderStatus
        })
      }
    }
    if (this.state.getCalls[1]) {
      if (props.TeamListSuccess) {
        let succed = this.state.getCalls;
        succed[1] = 0;
        let loaderStatus = succed.includes(1);
        let TeamListData = props.TeamLists;
        let newTeam = {
          "id": 0,
          "teamName": "All",
          "vehicleNumber": "",
          "vehicelId": "",
          "assignedStaff": [],
          "teamTypeId": 0,
          "teamTypeName": "all"
      };
      TeamListData.unshift(newTeam); 
        this.setState({
          TeamsLists: TeamListData,
          TeamSelectedID: TeamListData[0].id,
          TeamSelected: TeamListData[0],
          isLoading: loaderStatus,
          getCalls: succed
        })
      } else if (props.TeamListFailure) {
        let succed = this.state.getCalls;
        succed[1] = 0;
        let loaderStatus = succed.includes(1);
        this.setState({
          TeamsLists: [],
          getCalls: succed,
          isLoading: loaderStatus
        })
      }
    }
    if (this.state.postCalls[0]) {
      if (props.DeleteLeaveSuccess) {
        let removObj = this.state.RemoveData;
        this.setState({
          postCalls: [0],
          isLoading: false,
          RemoveModel: false,
          RemoveData: null
        }, () => {
          removObj.remove();
          toast.success('Deleted Successfully!', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: false,
          });
        })
      } else if (props.DeleteLeaveFailure) {
        this.setState({
          postCalls: [0],
          isLoading: false,
          RemoveModel: false,
          RemoveData: null
        }, () => {
          toast.error(props.DeleteLeaveResponse.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: false,
          });
        })
      }
    }
  }
  CloseAddLeave = () => {
    this.setState({
      AddModel: false,
      AddingData: null,
    })
  }
  AddedLeave = () => {
    let calendarApi = this.calendarRef.current.getApi();
    let presentMonth = (calendarApi.getDate()).getMonth();    
    let TeamID = this.state.TeamSelectedID ? this.state.TeamSelectedID : null;
    /* calendarApi.unselect()
    calendarApi.addEvent({
      teamId : teamObj.id,
      teamName: teamObj.teamName,
      start: selectedItem.startStr,
      end: selectedItem.endStr
    }) */
      let StartdatePicked = MonthStartDate(presentMonth);
      let EnddatePicked = MonthEndDate(presentMonth);
      let filterObj = {
        StartDate: StartdatePicked,
        EndDate: EnddatePicked,
        TeamID : TeamID
      }
      this.setState({
        isLoading:true,
        getCalls :[1,0],
        AddModel: false,
        AddingData: null,
      },()=>{
        this.props.GetTeamLeaves(filterObj);
        calendarApi.gotoDate(StartdatePicked);
      })
  }
  EditedLeave = (teamName) => {   
    this.setState({
      EditState: false,
      EditData: null,
    })
  }
  CloseEditLeave = () => {
    this.setState({
      EditState: false,
      EditData: null,
    })
  }
  RemoveClick = (RemoveObject) => {
    this.setState({
      RemoveModel: true,
      RemoveData: RemoveObject.event
    })
  }
  RemoveConfirm = () => {
    let removObj = this.state.RemoveData;
    this.setState({
      //RemoveModel: false,
      //EditState: false,
     // EditData: null,
     // RemoveData: null,
     postCalls: [1],
     isLoading: true,
    }, () => {
      //removObj.remove()
      this.props.DeleteTeamLeaves(removObj.id);
    })
  }
  RemoveClose = () => {
    this.setState({
      RemoveModel: false,
      RemoveData: null
    })
  }
  handleEventClick = (clickInfo) => {
    //alert(JSON.stringify(clickInfo.event))
    this.setState({
      EditState: true,
      EditData: clickInfo.event
    })
  }
  handleDateSelect = (selectInfo) => {
     this.setState({
      AddModel: true,
      AddingData: selectInfo
    }) 
   /* let calendarApi = selectInfo.view.calendar
    alert(selectInfo.startStr+'--'+selectInfo.endStr+'--'+selectInfo.allDay)
     calendarApi.unselect()

     calendarApi.addEvent({
       id: 1,
       title:"Team A",
       start: selectInfo.startStr,
       end: selectInfo.endStr,
       allDay: true
     }) */

  }
  searchClick = () => {
    if (!this.state.StartDate && !this.state.EndDate && !this.state.PostalCode && !this.state.TeamSelectedID) {
      toast.warning('Please choose any one filter option!', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: false,
      });
      return;
    }
    let StartdatePicked = this.state.StartDate ? this.state.StartDate : MonthStartDate();
    let EnddatePicked = this.state.EndDate ? this.state.EndDate : MonthEndDate();
    let TeamID = this.state.TeamSelectedID ? this.state.TeamSelectedID : null;
    let filterObj = {
      StartDate: StartdatePicked,
      EndDate: EnddatePicked,
      TeamID : TeamID
    }
    this.setState({
      isLoading:true,
      getCalls :[1,0]
    },()=>{
      this.props.GetTeamLeaves(filterObj);
      let calendarApi = this.calendarRef.current.getApi();
      calendarApi.gotoDate(StartdatePicked);
    })
  }
  clearSearchClick = () => {
    this.setState({
      StartDate: MonthStartDate(),
      EndDate: MonthEndDate(),
      TeamSelected: null,
      TeamSelectedID: null
    });

  }
  CalanderViewChange=(currcls, ViewType)=>{
    let TeamID = currcls.state.TeamSelectedID ? currcls.state.TeamSelectedID : null;
    let StartdatePicked = currcls.state.StartDate ? currcls.state.StartDate : MonthStartDate();
    let EnddatePicked = currcls.state.EndDate ? currcls.state.EndDate : MonthEndDate();
    let calendarApi = currcls.calendarRef.current.getApi();
    let ExistViewType = calendarApi.view.type;
     if(ViewType=='dayGridMonth'){
        StartdatePicked = MonthStartDate(moment(StartdatePicked).format("M"));
        EnddatePicked = MonthEndDate(moment(StartdatePicked).format("M"));
      }else if(ViewType=='timeGridWeek'){
        StartdatePicked = WeekStartDate(moment(StartdatePicked).format("YYYY-MM-DD"));
        EnddatePicked = WeekEndDate(moment(StartdatePicked).format("YYYY-MM-DD"));
      }else if(ViewType=='timeGridDay'){
        StartdatePicked = moment(StartdatePicked).format("YYYY-MM-DD");
        EnddatePicked = moment(StartdatePicked).format("YYYY-MM-DD");
      }
      let filterObj = {
        StartDate: StartdatePicked,
        EndDate: EnddatePicked,
        TeamID : TeamID
      }
      currcls.setState({
        isLoading:true,
        DefaultView : ViewType,
        getCalls :[1,0],
        StartDate: StartdatePicked,
        EndDate: EnddatePicked,
      },()=>{
        currcls.props.GetTeamLeaves(filterObj);
      })
    calendarApi.changeView(ViewType,{
      start: StartdatePicked,
      end: EnddatePicked
    });
    
  }
  CalanderNavigation=(currcls, direction)=>{
    let calendarApi = currcls.calendarRef.current.getApi();
    let ViewType = calendarApi.view.type;
    const presentMonth = (calendarApi.getDate()).getMonth();  
    let fulYear = calendarApi.getDate().getFullYear();
    let StartdatePicked = currcls.state.StartDate ? currcls.state.StartDate : NextStartDate(calendarApi.getDate());
    let EnddatePicked = currcls.state.EndDate ? currcls.state.EndDate : NextEndDate(calendarApi.getDate());
    let TeamID = currcls.state.TeamSelectedID ? currcls.state.TeamSelectedID : null;
   
    if(direction=='P'){
      let previousMonth = MonthNumber(calendarApi.getDate());
      if(ViewType=='timeGridDay'){
        StartdatePicked = CDateMinusYMD(StartdatePicked ,1);
        EnddatePicked = StartdatePicked;
      }else if(ViewType=='timeGridWeek'){
        StartdatePicked = WeekStartDate(CDateMinusYMD(StartdatePicked,7));
        EnddatePicked = WeekEndDate(StartdatePicked);
      }else if(ViewType=='dayGridMonth'){
        //alert(previousMonth+"==="+fulYear)
         if(parseInt(previousMonth)===0){
          previousMonth = 12;
          fulYear = fulYear - 1;
        } 
        StartdatePicked = ChangeStartDate(previousMonth, fulYear);
        EnddatePicked = ChangeEndDate(previousMonth, fulYear);
      }
      let filterObj = {
        StartDate: StartdatePicked,
        EndDate: EnddatePicked,
        TeamID : TeamID
      }
     
      currcls.setState({
        isLoading:true,
        DefaultView : ViewType,
        getCalls :[1,0],
        StartDate: StartdatePicked,
        EndDate: EnddatePicked,
      },()=>{
        currcls.props.GetTeamLeaves(filterObj);
        calendarApi.prev();
        
      })
    }else if(direction=='N'){
      let nextMonth = MonthNumber(calendarApi.getDate());
      if(ViewType=='timeGridDay'){
        StartdatePicked = CDatePlusYMD(StartdatePicked ,1);
        EnddatePicked = StartdatePicked;
      }else if(ViewType=='timeGridWeek'){
        StartdatePicked = WeekStartDate(CDatePlusYMD(StartdatePicked,7));
        EnddatePicked = WeekEndDate(StartdatePicked);
      }else if(ViewType=='dayGridMonth'){
        const newMonth = nextMonth + 2 > 12 ? (nextMonth + 2 - 12) : nextMonth + 2;
       // alert(newMonth+"=="+fulYear)
        if(parseInt(newMonth)===1){
          fulYear = fulYear + 1;
        }
        StartdatePicked = ChangeStartDate(newMonth, fulYear);
        EnddatePicked = ChangeEndDate(newMonth, fulYear);
      }
      let filterObj = {
        StartDate: StartdatePicked,
        EndDate: EnddatePicked,
        TeamID : TeamID
      }
      //alert(JSON.stringify(filterObj))
      currcls.setState({
        isLoading:true,
        getCalls :[1,0],
        DefaultView : ViewType,
        StartDate: StartdatePicked,
        EndDate: EnddatePicked,
      },()=>{
        currcls.props.GetTeamLeaves(filterObj);
        //calendarApi.gotoDate(StartdatePicked);
        calendarApi.next();
      })
    } 
  }
  renderEventContent = (eventInfo) => {
    
    return (
      <Grid item md={12}>
        <Typography>{eventInfo.event.extendedProps.teamName}</Typography>
      </Grid>
    )
  }
  render() {
    const { classes } = this.props;
    const thiscls = this;
    /* let columns = [];
    var obj = { id: 'someVal', name: 'otherVal' };
    columns.push({
        name: Object.keys(obj)[0],
        label: "Job Code"
    })
    columns.push({
      name: Object.keys(obj)[1],
      label: "Name"
  }) */
    return (
      <>
        <Grid container spacing={4}>
          <Grid item md={12}>
          <Grid container spacing={1} md={12} direction={"row"} style={{ marginTop: 30 }}>
                <Grid item md={2} >
                  <InputField
                    label={"Start Date"}
                    type={"date"}
                    required={false}
                    value={this.state.StartDate}
                    changeText={(data) => {
                      this.setState({
                        StartDate: data ? data.target.value : null,
                        EndDate: data ? LastDayMonth(data.target.value) : null
                      })
                    }}
                    // error={Createerrors.Date}
                    initialName={"Start Date"}
                    errorMsg={"Please enter start date."}
                  />
                </Grid>
                <Grid item md={2} >
                  <InputField
                    label={"End Date"}
                    type={"date"}
                    required={false}
                    value={this.state.EndDate}
                    changeText={(data) => { this.setState({ EndDate: data ? data.target.value : null }) }}
                    // error={Createerrors.Date}
                    initialName={"End Date"}
                    errorMsg={"Please enter end date."}
                  />
                </Grid>
                <Grid item md={2} >
                  <Dropdown
                    label={"Select Team"}
                    data={this.state.TeamsLists}
                    getOptionLabel={option => (option.teamName ? option.teamName : "")}
                    getOptionSelected={(option, value) => option.id === value.id}
                    value={this.state.TeamSelected}
                    ItemChange={(event, newValue) => {
                      this.setState({
                        TeamSelectedID: newValue ? newValue.id : null,
                        TeamSelected: newValue ? newValue : null
                      })
                    }}
                    //error={Createerrors.TeamSelected}
                    initialName={"Team"}
                    errorMsg={"Please choose team."}
                  />
                </Grid>
                <Grid item md={1} >
                  <Button
                    onClick={() => this.clearSearchClick()}
                    className={classes.buttonCancel}
                    variant="contained" color="primary">
                    Clear
                  </Button>
                </Grid>
                <Grid item md={1} >
                  <Button
                    onClick={() => this.searchClick()}
                    className={classes.buttonSuccess}
                    variant="contained" color="primary">
                    Search
                  </Button>
                </Grid>
              </Grid>
            <Grid item spacing={4} md={12} style={{ marginTop: 30 }}>
            <FullCalendar
              ref={this.calendarRef}
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              headerToolbar={{
                left: 'PreviousButton NextButton',
                center: 'title',
                right: 'MonthButton,WeekButton,DayButton'
              }}
              customButtons={{
                  NextButton: {
                      text: "next",
                      click: function() {
                        thiscls.CalanderNavigation(thiscls, "N")
                        /* let calendarApi = thiscls.calendarRef.current.getApi()
                        calendarApi.next(); */
                      },
                  },
                  PreviousButton: {
                    text: "prev",
                    click: function(e) {
                      thiscls.CalanderNavigation(thiscls, "P")
                      /* let calendarApi = thiscls.calendarRef.current.getApi()
                      calendarApi.prev(); */
                    },
                },
                MonthButton: {
                    text: "month",
                    click: function(e) {
                      thiscls.CalanderViewChange(thiscls, "dayGridMonth")
                      /* let calendarApi = thiscls.calendarRef.current.getApi()
                      calendarApi.prev(); */
                    },
                },
                WeekButton: {
                    text: "week",
                    click: function(e) {
                      thiscls.CalanderViewChange(thiscls, "timeGridWeek")
                      /* let calendarApi = thiscls.calendarRef.current.getApi()
                      calendarApi.prev(); */
                    },
                },
                DayButton: {
                    text: "day",
                    click: function(e) {
                      thiscls.CalanderViewChange(thiscls, "timeGridDay")
                      /* let calendarApi = thiscls.calendarRef.current.getApi()
                      calendarApi.prev(); */
                    },
                }
              }}
              initialView={this.state.DefaultView}
              editable={true}
              selectable={true}
              selectMirror={true}
              dayMaxEvents={true}
              weekends={true}
              events={this.state.TLeaveList} // alternatively, use the `events` setting to fetch from a feed
              select={(event) => this.handleDateSelect(event)}
              eventContent={this.renderEventContent} // custom render function
              eventClick={(event) => this.RemoveClick(event)}
              //eventClick={(event) => this.handleEventClick(event)}
            //eventsSet={this.handleEvents} // called after events are initialized/added/changed/removed
            /* you can update a remote database when these fire:
            eventAdd={function(){}}
            eventChange={function(){}}
            eventRemove={function(){}}
            */
            />
            </Grid>
          </Grid>
          <AlertDialog
            Status={this.state.RemoveModel}
            DialogConfirm={() => { this.RemoveConfirm() }}
            DialogCancel={() => { this.RemoveClose() }}
            Title={"Remove Leave"}
            Content={"Are you sure, you want to remove the leave ?"}
          />
          {this.state.AddModel ? <AddLeaves
            Title={"Add Leave"}
            parentClass={this}
            parentState={this.state}
            Status={this.state.AddModel}
            ModelConfirm={this.AddedLeave}
            ModelCancel={this.CloseAddLeave}
          /> : null}
          {this.state.EditState ? <EditLeaves
            Title={"Edit Leave"}
            parentClass={this}
            parentState={this.state}
            Status={this.state.EditState}
            ModelConfirm={this.EditedLeave}
            ModelCancel={this.CloseEditLeave}
          /> : null}
          <LoadingOverlay Status={this.state.isLoading} />
        </Grid>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    TeamLeaveSuccess: state.GetTeamLeavesReducer.Success,
    TeamLeaveFailure: state.GetTeamLeavesReducer.Failure,
    TeamLeaveList: state.GetTeamLeavesReducer.TeamLeaves,

    TeamListSuccess: state.getTeamsReducer.Success,
    TeamListFailure: state.getTeamsReducer.Failure,
    TeamLists: state.getTeamsReducer.TeamList,

    DeleteLeaveSuccess: state.DeleteTeamLeavesReducer.Success,
    DeleteLeaveFailure: state.DeleteTeamLeavesReducer.Failure,
    DeleteLeaveResponse: state.DeleteTeamLeavesReducer.DeleteResponse,

    UserDetails: state.LoginReducer.UserDetails,
    LoaderState: state.LoaderReducer.State
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetTeamLeaves: (fObj) => dispatch(GetTeamLeaves(fObj)),
    getTeams: (ID) => dispatch(getTeams(ID)),
    DeleteTeamLeaves: (LID) => dispatch(DeleteTeamLeaves(LID)),
    refreshReduxStates: () => dispatch(refreshReduxStates()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Leaves));
