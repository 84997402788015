import React from "react";
import {
  Route,
  Switch,
  Redirect,
  withRouter,
} from "react-router-dom";
import { classnames } from "./../../utilities/PackagesList";

// styles
import useStyles from "./styles";

// components
import Header from "./../Header";
import Sidebar from "./../Sidebar";

// Common
import Staffs from "./../../pages/staffs";
import AddStaff from "./../../pages/addstaff";
import Vehicles from "./../../pages/vehicles";
import Teams from "./../../pages/teams";
//Delivery
import Delivery from "./../../pages/delivery";
//import DeliverySchedule from "./../../pages/deliveryschedule";
import AdhocDelivery from "./../../pages/adhocdelivery";
import AdhocSchedule from "./../../pages/adhocschedule";
import DeliveryStatus from "./../../pages/deliverystatus";
import DeliveryReport from "./../../pages/deliveryreport";
import DeliveryPTrip from "./../../pages/deliveryptrip";
//Services
import CreateService from "./../../pages/createservice";
import Service from "./../../pages/service";
import ServiceStatus from "./../../pages/servicestatus";
import AdhocService from "./../../pages/adhocservice";
import TimeSlotReport from "./../../pages/timeslotreport";
import CollectionReport from "./../../pages/collectionreport";
import PaymentReport from "./../../pages/paymentreport";
import JobTypesReport from "./../../pages/jobtypereport";
import TechReport from "./../../pages/techreport";
import LastServiceReport from "./../../pages/lastservicereport";
//Masters
import JobType from "./../../pages/jobtypes";
import PaymentType from "./../../pages/paymenttypes";
import TimeSlots from "./../../pages/timeslots";
import Forms from "./../../pages/forms";
import Leaves from "./../../pages/leaves";
// context
import { useLayoutState } from "./../../context/LayoutContext";

function Layout(props) {
  var classes = useStyles();

  // global
  var layoutState = useLayoutState();

  return (
    <div className={classes.root}>
      <>
        <Header history={props.history} />
        <Sidebar />
        <div
          className={classnames(classes.content, {
            [classes.contentShift]: layoutState.isSidebarOpened,
          })}
        >
          <div className={classes.fakeToolbar} />
          <Switch>
            <Route exact path="/app/vehicles" component={Vehicles} />
            <Route exact path="/app/staffs" component={Staffs} />
            <Route exact path="/app/addstaff" component={AddStaff} />
            <Route exact path="/app/teams" component={Teams} />
            <Route exact path="/app/delivery" component={Delivery} />
            {/* <Route exact path="/app/deliveries" component={DeliverySchedule} /> */}
            <Route exact path="/app/adhocdelivery" component={AdhocDelivery} />
            <Route exact path="/app/adhocschedule" component={AdhocSchedule} />
            <Route exact path="/app/deliverystatus" component={DeliveryStatus} />
            <Route exact path="/app/deliveryreport" component={DeliveryReport} />
            <Route exact path="/app/deliveryptrip" component={DeliveryPTrip} />
            <Route exact path="/app/createservice" component={CreateService} />
            <Route exact path="/app/service" component={Service} />
            <Route exact path="/app/servicestatus" component={ServiceStatus} />
            <Route exact path="/app/adhocservice" component={AdhocService} />
            <Route exact path="/app/jobtypes" component={JobType} />
            <Route exact path="/app/paymenttypes" component={PaymentType} />
            <Route exact path="/app/timeslots" component={TimeSlots} />
            <Route exact path="/app/timeslotreport" component={TimeSlotReport} />
            <Route exact path="/app/collectionreport" component={CollectionReport} />
            <Route exact path="/app/paymentreport" component={PaymentReport} />
            <Route exact path="/app/jobtypereport" component={JobTypesReport} />
            <Route exact path="/app/techreport" component={TechReport} />
            <Route exact path="/app/lastservicereport" component={LastServiceReport} />
            <Route exact path="/app/leaves" component={Leaves} />
            <Route exact path="/app/forms" component={Forms} />
          </Switch>
        </div>
      </>
    </div>
  );
}

export default withRouter(Layout);
