import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, makeStyles} from './../../utilities/PackagesList';
import useStyles from "./../../utilities/Styles";

/* const useStyles = makeStyles(theme => ({
  dialogTitle: {
    
  }
})) */

export default function AlertDialog(props) {
  const classes = useStyles();
  const closeDialog = () => {
    props.DialogCancel();
  };
  const deeteStaff = () => {
    props.DialogConfirm();
  };

  return(
      <Dialog
        open={props.Status}
        onClose={closeDialog}
        fullWidth
        maxWidth="sm"
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
      >
        <DialogTitle className={classes.dialogTitle} id="dialog-title">{props.Title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="dialog-description">
            {props.Content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button size="small" variant="contained" color="primary" className={classes.buttonCancel}  onClick={closeDialog}>
            No
          </Button>
          <Button size="small" variant="contained" color="secondary" className={classes.buttonSuccess} onClick={deeteStaff}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
  );
}